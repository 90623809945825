import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {formatDate, joinArrayByComma, renderStringsFromArray} from "../../middlewares/utils";
import {BULK_EVENT_VALIDATION_ERRORS} from "../../constants";

function descendingComparator ( a, b, orderBy )
{
  if ( b[ orderBy ] < a[ orderBy ] )
  {
    return -1;
  }
  if ( b[ orderBy ] > a[ orderBy ] )
  {
    return 1;
  }
  return 0;
}

function getComparator ( order, orderBy )
{
  return order === 'desc'
    ? ( a, b ) => descendingComparator( a, b, orderBy )
    : ( a, b ) => -descendingComparator( a, b, orderBy );
}


function stableSort ( array, comparator )
{
  const stabilizedThis = array.map( ( el, index ) => [ el, index ] );
  stabilizedThis.sort( ( a, b ) =>
  {
    const order = comparator( a[ 0 ], b[ 0 ] );
    if ( order !== 0 )
    {
      return order;
    }
    return a[ 1 ] - b[ 1 ];
  } );
  return stabilizedThis.map( ( el ) => el[ 0 ] );
}

const headCells = [
  {
    id: 'title',
    label: 'Title',
  },
  {
    id: 'start',
    label: 'Start date and time',
  },
  {
    id: 'end',
    label: 'End date and time',
  },
  {
    id: 'location',
    label: 'Location',
  },
  {
    id: 'group',
    label: 'Group',
  },
  {
    id: 'users',
    label: 'Users',
  },
  {
    id: 'error',
    label: 'Errors found',
  },
];

function EnhancedTableHead ( props )
{
  return (
    <TableHead>
      <TableRow>
        { headCells.map( ( headCell ) => (
          <TableCell
            sx={ {
              color: '#626D8A',
              padding: '12px 11px !important',
              fontFamily: 'Manrope',
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: '133.333%',
              borderColor: '#EAEBEB',
            }}
            key={ headCell.id }
            align={ headCell.numeric ? 'right' : 'left' }
            padding={ headCell.disablePadding ? 'none' : 'normal' }
          >
              { headCell.label }
          </TableCell>
        ) ) }
      </TableRow>
    </TableHead>
  );
}

export default function UploadEventErrorTable (props)
{
  const [ order, setOrder ] = React.useState( 'asc' );
  const [ orderBy, setOrderBy ] = React.useState( 'email' );
  const [ page, setPage ] = React.useState( 0 );
  const [ rowsPerPage, setRowsPerPage ] = React.useState( 5 );

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max( 0, ( 1 + page ) * rowsPerPage - props.events.length ) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort( props.events, getComparator( order, orderBy ) ).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [ order, orderBy, page, rowsPerPage ],
  );

  return (
    <Table
      sx={{minWidth: 1}}
        aria-labelledby="tableTitle"
      >
        <EnhancedTableHead />
        <TableBody>
          { visibleRows.map( ( row, index ) =>
          {
            return row.validationErrors.length > 0 && (
              <TableRow
                hover
                key={ row.name }
                sx={{
                  '& .MuiTableCell-root': {
                    color: '#161616 !important',
                    padding: '12px 11px !important',
                    fontFamily: '"Manrope"',
                    fontSize: '13px',
                    fontWeight: 400,
                    lineHeight: '133.333%',
                    borderLeft: '1px solid',
                    borderColor: '#EAEBEB',

                    '&:last-of-type': {
                      borderRight: '1px solid',
                      borderColor: '#EAEBEB',
                      color: '#B42318 !important'
                    }
                  }
                }}
              >
                <TableCell>
                  { row.title }
                </TableCell>
                <TableCell>{ formatDate(row.eventStartDateTime) }</TableCell>
                <TableCell>{ formatDate(row.eventEndDateTime) }</TableCell>
                <TableCell>{ row.location }</TableCell>
                <TableCell>{ joinArrayByComma(row.groups) }</TableCell>
                <TableCell>{ joinArrayByComma(row.userEmails) }</TableCell>
                <TableCell>{ renderStringsFromArray(BULK_EVENT_VALIDATION_ERRORS, row.validationErrors) }</TableCell>
              </TableRow>
            );
          } ) }
          { emptyRows > 0 && (
            <TableRow
              style={ {
                height: 53 * emptyRows,
              } }
            >
              <TableCell colSpan={ 6 } />
            </TableRow>
          ) }
        </TableBody>
      </Table>
  );
}