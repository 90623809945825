import React, { useState, useEffect, useRef } from "react";

import { DashboardLayout } from "../components/dashboard-layout";
import { connect, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import brdCum from "../assets/images/new-images/brd-cum-icon.svg";
import SearchIcon from "../assets/images/search.svg";
import add from "../assets/images/new-images/add.png";
import EventWrapper from "../components/Calendar/EventWrapper";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "../../node_modules/react-big-calendar/lib/css/react-big-calendar.css";
import p2 from "../assets/images/new-images/evt2.svg";
import p3 from "../assets/images/new-images/evt3.svg";
import p4 from "../assets/images/new-images/evt4.svg";
import p5 from "../assets/images/new-images/evt5.svg";
import Modal from "../components/UI/Modal";
import calendar from "../assets/images/new-images/calender-ico.svg";
import mdelete from "../assets/images/new-images/m-delete.svg";
import edit from "../assets/images/new-images/m-edit.svg";
import crs from "../assets/images/new-images/m-crs.svg";
import deleteIcon from "../assets/images/new-images/delete.png";
import editIcon from "../assets/images/new-images/edit.png";
import listIcon from "../assets/images/new-images/listIcon.png";
import AddEvents from "../components/Calendar/AddEvents";
import DeleteEvents from "../components/Calendar/DeleteEvents";
import * as action from "../actions/action-event";
import * as masterAction from "../actions/action-master";
import CustomToolbar from "../components/Calendar/CustomToolbar";
import Pagination from "../components/UI/Pagination";
import path from "../assets/images/new-images/Path.png";
import Loader from "../components/UI/Loader";
import DateRangePicker from "rsuite/DateRangePicker";
import { dateEventFilter } from "../actions/action-event";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import axios from "axios";
import AddBulkEvents from "../components/Calendar/AddBulkEvents";
import { fetchAllUsers } from "../actions/actions-users";
import CalendarEventCategory from "../components/Calendar/CalendarEventCategory";
import arrow from "../assets/images/new-images/right-arrow.svg";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const { REACT_APP_BACKEND_API_APP } = process.env;

function Event({
  deleteModal,
  fetchEvent,
  fetchAllUserGroups,
  fetchCalendarEventCategories,
  allEvents = [],
  userGroups = [],
  calendarEventCategories,
  categories = [],
  totalRecords,
  getPaginationData,
  paginationOptions,
  loading,
  fetchSearch,
  ...props
}) {
  const dispatch = useDispatch();

  const init = () => {
    // fetchEvent();
    fetchSearch("");
    fetchAllUserGroups();
    dispatch(fetchAllUsers(true));
    fetchCalendarEventCategories();
  };

  const [eventData, setEventData] = useState([]);
  const [bulkAddSuccess, setBulkAddSuccess] = useState(false);
  let empId = sessionStorage.getItem("userEmpId");
  let pageNo = useRef();
  pageNo.current = paginationOptions.pageNo;

  const setPaginationOptions = (values) => {
    getPaginationData(values);
  };

  useEffect(() => {
    init();
  }, [bulkAddSuccess]);

  const [events, setEvents] = useState([]);
  const location = useLocation();

  const openEventHandler = (data) => {
    setEventData(data);
  };

  const localizer = momentLocalizer(moment);

  const [deleteId, setDeleteId] = useState(null);
  const delTest = (id) => {
    deleteModal(true);
    setDeleteId(id);
  };

  const [editmode, setEditMode] = useState(false);
  const [filterEventIndex, setfilterEventIndex] = useState("");
  const [filterEvent, setFilterEvent] = useState({});
  const [timer, setTimer] = useState(null);

  const [calendarView, setCalendarView] = useState(
    location?.state?.calendarView ? location?.state?.calendarView : false
  );
  const [listView, setListView] = useState(
    location?.state?.calendarView ? false : true
  );
  const [activeToggle, setActiveToggle] = useState(false);

  const calendarViewHandler = () => {
    setCalendarView(true);
    setListView(false);
    setActiveToggle(true);
  };
  const listViewHandler = () => {
    setListView(true);
    setCalendarView(false);
    setActiveToggle(false);
  };

  const [searchInput, setSearchInput] = useState("");

  const searchHandler = (e) => {
    setSearchInput(e.target.value);
    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      fetchSearch(e.target.value);
    }, 1000);

    setTimer(newTimer);
  };

  const [page, setPage] = useState(1);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [allEventsCalendar, setAllEventsCalendar] = useState([]);
  const [calendarDeleteSuccess, setCalendarDeleteId] = useState(false);
  const [editCalendar, setEditCalendar] = useState([]);
  const [editCalendarSuccess, setEditCalendarSuccess] = useState(false);

  const fetchRecords = () => {
    let token = sessionStorage.getItem("accessToken2");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const userId = sessionStorage.getItem("userId");

    axios
      .get(
        `${REACT_APP_BACKEND_API_APP}/api/GetEventsListByLoginUserId/?userId=${userId}&PageSize=${10000}`,
        config
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          let result = response.data.responseData.data.map((a) => {
            return {
              id: a.eventId,
              start: new Date(moment.utc(a.eventStartDateTime).local()),
              end: new Date(moment.utc(a.eventEndDateTime).local()),
              ...a,
            };
          });
          setAllEventsCalendar(result);
        }
      });
  };

  useEffect(() => {
    fetchRecords();
  }, [allEvents]);

  useEffect(() => {
    fetchEvent();
  }, [calendarEventCategories]);

  const editEvent = (id) => {
    setEditMode(true);
    calendarView
      ? setfilterEventIndex(
          allEventsCalendar.findIndex((event, index) => event.eventId === id)
        )
      : setfilterEventIndex(
          allEvents.findIndex((event, index) => event.eventId === id)
        );
    calendarView
      ? setFilterEvent(
          allEventsCalendar.find((event, index) => event.eventId === id)
        )
      : setFilterEvent(allEvents.find((event, index) => event.eventId === id));
  };

  useEffect(() => {
    setAllEventsCalendar(
      allEventsCalendar.filter((a) => a.eventId !== deleteId)
    );
    setCalendarDeleteId(false);
  }, [calendarDeleteSuccess]);

  useEffect(() => {
    setAllEventsCalendar(
      allEventsCalendar.map((event) => {
        if (event.eventId === editCalendar.eventId) {
          return {
            ...event,
            title: editCalendar.title,
            description: editCalendar.description,
            location: editCalendar.location,
            start: new Date(
              moment.utc(editCalendar.eventStartDateTime).local()
            ),
            end: new Date(moment.utc(editCalendar.eventEndDateTime).local()),
            eventStartDateTime: moment
              .utc(editCalendar.eventStartDateTime)
              .format(),
            eventEndDateTime: moment
              .utc(editCalendar.eventEndDateTime)
              .format(),
            groupIdList: editCalendar.groupIdList,
            userId: editCalendar.userId,
          };
        } else {
          return event;
        }
      })
    );
    setEditCalendar([]);
    setEditCalendarSuccess(false);
  }, [editCalendarSuccess]);

  const [value, setValue] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const dateFilterHandler = (val) => {
    setValue(val);

    let date = {};
    date.startDate = val && moment.utc(val[0]).local().format("YYYY-MM-DD");
    date.endDate = val && moment.utc(val[1]).local().format("YYYY-MM-DD");

    if (val) {
      setStartDate(date.startDate);
      setEndDate(date.endDate);
    } else {
      setStartDate("");
      setEndDate("");
    }

    props.dateEventFilter(date);
  };

  useEffect(() => {
    let date = {};
    date.startDate = "";
    date.endDate = "";
    props.dateEventFilter(date);
  }, []);

  return (
    <>
      <AuthenticatedTemplate>
        <AddEvents
          events={events}
          setEvents={setEvents}
          allEvents={allEvents}
          filterEvent={filterEvent}
          setFilterEvent={setFilterEvent}
          filterEventIndex={filterEventIndex}
          editmode={editmode}
          setEditMode={setEditMode}
          calendarEventCategories={calendarEventCategories}
          groupsList={userGroups}
          setEditCalendar={setEditCalendar}
          setEditCalendarSuccess={setEditCalendarSuccess}
        />
        <AddBulkEvents setBulkAddSuccess={setBulkAddSuccess} />
        <DeleteEvents
          deleteId={deleteId}
          events={events}
          setEvents={setEvents}
          fetchEvent={fetchEvent}
          setCalendarDeleteId={setCalendarDeleteId}
        />
        <DashboardLayout>
          <section>
            <div className="breadcrumb">
              <img src={brdCum} alt="logo" />
              <p> |&nbsp;&nbsp; Events </p>
            </div>
            <div className="app-wrapper">
            <>
                  <div className="setting-box notification-setting-box">
                    <div className="application-name">
                      <div className="nav-setting">
                        <div className="nav nav-tabs" id="myTab" role="tablist">
                          <button
                            className="nav-link active"
                            id="home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#home"
                            type="button"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                          >
                            <span>Events</span>
                            <img src={arrow} alt="logo" />
                          </button>

                          <div className="divider"></div>

                          <button
                            className="nav-link"
                            id="profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#profile"
                            type="button"
                            role="tab"
                            aria-controls="profile"
                            aria-selected="false"
                          >
                            <span> Calendar Event Categories</span>
                            <img src={arrow} alt="logo" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="application-detail">
                      <div className="tab-content">
                        <div
                          className="tab-pane fade show active"
                          id="home"
                          role="tabpanel"
                          aria-labelledby="home-tab"
                        >
                          <div className="app-header event-head-ipad">
                            <p className="app-heding">Events</p>
                            <div className="app-searching">
                              {listView && (
                                <div className="relative w-100">
                                  <img src={SearchIcon} alt="icon" />
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search"
                                    value={searchInput}
                                    onChange={searchHandler}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="wrp-notify-rightHead">
                              <div className="manageUI"></div>
                              {listView && (
                                <div className="setDateRang notifyDateRange">
                                  <DateRangePicker
                                    placeholder="Filter by Date"
                                    format="MM-dd-yyy"
                                    onChange={(ValueType) => {
                                      dateFilterHandler(ValueType);
                                    }}
                                    value={value}
                                    placement="autoVerticalStart"
                                    editable={false}
                                    size="md"
                                  />
                                </div>
                              )}
                              <div className="applist-right-headbar min-w-111">
                                <div className="cal-views">
                                  <img
                                    className={`list icon-set me-3 ${
                                      !activeToggle ? "icon-active" : ""
                                    }`}
                                    src={listIcon}
                                    alt="calendar"
                                    onClick={listViewHandler}
                                  />
                                  <img
                                    className={`calendarIon icon-set me-3 ${
                                      activeToggle ? "icon-active" : ""
                                    }`}
                                    src={calendar}
                                    alt="calendar"
                                    onClick={calendarViewHandler}
                                  />
                                </div>
                                {empId && empId !== "" && empId !== undefined ? (
                                  <></>
                                ) : (
                                    <>
                                      <button
                                          data-bs-toggle="modal"
                                          data-bs-target="#createEvent"
                                          className="btn btn-genmeb min-w72"
                                          onClick={() => setFilterEvent({})}
                                      >
                                        <img src={add} alt="icon" className="me-2"/> Add event
                                      </button>
                                      <button
                                          data-bs-toggle="modal"
                                          data-bs-target="#bulkEventsUpload"
                                          className="btn btn-genmeb min-w72"
                                      >
                                        <img src={add} alt="icon" className="me-2"/> Add Bulk Events
                                      </button>
                                    </>
                                )}
                              </div>
                            </div>
                          </div>
                          {loading ? (
                              <Box
                                  sx={{
                                    display: "flex",
                                    position: "absolute",
                                    top: "50%",
                                    width: "100%",
                                    height: "100%",
                                justifyContent: "center",
                                alignItems: "start",
                                left: "10%",
                                // background: "#000",
                                zIndex: 99,
                              }}
                            >
                              <CircularProgress />
                            </Box>
                          ) : (
                            <>
                              {allEvents && allEvents?.length === 0 ? (
                                <div className="alert-bar-boxInner">
                                  No events available
                                </div>
                              ) : allEvents.length === 0 ? (
                                <div className="alert-bar-boxInner">
                                  {" "}
                                  No Match Found
                                </div>
                              ) : (
                                <div className="events-box">
                                  {!calendarView ? (
                                    listView && loading ? (
                                      <Loader />
                                    ) : (
                                      <div className="eventList-wrapper">
                                        <div className="event-scroll">
                                          {allEvents && allEvents?.length === 0 ? (
                                            <p className="events-error">No Events Available </p>
                                          ) : allEvents.length === 0 ? (
                                            <p className="events-error">No Match Found </p>
                                          ) : (
                                            allEvents.map((event, index) => (
                                              <div className="evnt-list" style={{
                                                borderLeft : ".375rem solid " + calendarEventCategories?.find( cat => event?.calendarEventCategoryID == cat.id)?.colour
                                              }} key={index}>
                                                <div>
                                                  <p className="evtList-head">{event.title}</p>

                                                  <p className="evtList-footer">
                                                    {moment
                                                      .utc(event.eventStartDateTime)
                                                      .local()
                                                      .format("MMMM Do YYYY, h:mm A") +
                                                      " - "}{" "}
                                                    {moment
                                                      .utc(event.eventEndDateTime)
                                                      .local()
                                                      .format("YYYY-MM-DD") >
                                                    moment
                                                      .utc(event.eventStartDateTime)
                                                      .local()
                                                      .format("YYYY-MM-DD")
                                                      ? moment
                                                          .utc(event.eventEndDateTime)
                                                          .local()
                                                          .format("MMMM Do YYYY, h:mm A")
                                                      : moment
                                                          .utc(event.eventEndDateTime)
                                                          .local()
                                                          .format("h:mm A")}
                                                  </p>
                                                  <div className="dropdown event-read">
                                                    <button
                                                      type="button"
                                                      className="dropdown-toggle read-text"
                                                      // data-bs-toggle="dropdown"
                                                    >
                                                      Read More
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                      <li>
                                                        <div className="event-popup">
                                                          <div className="p-top">
                                                            <img src={path} alt="icon" />
                                                          </div>
                                                          <span className="popup-head">
                                                            <span className="read-more"
                                                              style={{
                                                                background : calendarEventCategories?.find( cat => event?.calendarEventCategoryID == cat.id)?.colour
                                                              }}
                                                            ></span>

                                                            <p>{event.title}</p>
                                                          </span>

                                                          {event.description !== "" && (
                                                            <span className="popup-body">
                                                              <img src={p5} alt="icon" />
                                                              <span>
                                                                <p className="p-text">
                                                                  Description
                                                                </p>
                                                                <p className="p-sub-text">
                                                                  {event.description}
                                                                </p>
                                                              </span>
                                                            </span>
                                                          )}

                                                          <span className="popup-body">
                                                            <img src={p2} alt="icon" />
                                                            <span>
                                                              <p className="p-text">Scheduled</p>
                                                              <p className="p-sub-text">
                                                                {moment
                                                                  .utc(event.eventStartDateTime)
                                                                  .local()
                                                                  .format(
                                                                    "MMMM Do YYYY, h:mm A"
                                                                  ) + " - "}{" "}
                                                                {moment
                                                                  .utc(event.eventEndDateTime)
                                                                  .local()
                                                                  .format("YYYY-MM-DD") >
                                                                moment
                                                                  .utc(event.eventStartDateTime)
                                                                  .local()
                                                                  .format("YYYY-MM-DD")
                                                                  ? moment
                                                                      .utc(event.eventEndDateTime)
                                                                      .local()
                                                                      .format(
                                                                        "MMMM Do YYYY, h:mm A"
                                                                      )
                                                                  : moment
                                                                      .utc(event.eventEndDateTime)
                                                                      .local()
                                                                      .format("h:mm A")}
                                                              </p>
                                                            </span>
                                                          </span>
                                                          {event.location !== "" && (
                                                            <span className="popup-body">
                                                              <img src={p4} alt="icon" />
                                                              <span>
                                                                <p className="p-text">
                                                                  {event.location}
                                                                </p>
                                                              </span>
                                                            </span>
                                                          )}
                                                        </div>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                                {empId && empId !== "" && empId !== undefined ? (
                                                  <></>
                                                ) : (
                                                  <div className="icon-size">
                                                    <img
                                                      src={editIcon}
                                                      alt="icon"
                                                      className="me-3"
                                                      data-bs-toggle="modal"
                                                      data-bs-target="#createEvent"
                                                      onClick={() => {
                                                        editEvent(event.eventId);
                                                      }}
                                                    />
                                                    <img
                                                      src={deleteIcon}
                                                      alt="icon"
                                                      data-bs-target="#deleteEvent"
                                                      data-bs-toggle="modal"
                                                      onClick={() => {
                                                        delTest(event.eventId);
                                                      }}
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                            ))
                                          )}
                                        </div>
                                      </div>
                                    )
                                  ) : (
                                    <div className="event-scroll">
                                      <div className="myCustomHeight">
                                        <Calendar
                                          localizer={localizer}
                                          defaultView={Views.DAY}
                                          startAccessor="start"
                                          endAccessor="end"
                                          events={allEventsCalendar}
                                          views={["day", "week", "month"]}
                                          resizable
                                          timeslots={1}
                                          step={60}
                                          components={{
                                            eventWrapper: (props) => (
                                              <EventWrapper color={calendarEventCategories?.find( cat => props?.event?.calendarEventCategoryID == cat.id)?.colour} onAdd={openEventHandler} {...props} />
                                            ),
                                            toolbar: CustomToolbar,
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                              <Pagination
                                totalRecords={totalRecords}
                                options={paginationOptions}
                                pageNo={pageNo}
                                onChange={(e) => setPaginationOptions(e)}
                              />
                            </>
                          )}
                        </div>

                        <div
                          className="tab-pane fade"
                          id="profile"
                          role="tabpanel"
                          aria-labelledby="profile-tab"
                        >
                          <CalendarEventCategory />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
            </div>
          </section>
        </DashboardLayout>
        <Modal
          modalId="showEvent"
          modalLabel="exampleModalLabel"
          className="edit-event"
        >
          <div className="modal-dialog modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-header evet-set">
                {empId && empId !== "" && empId !== undefined ? (
                  <></>
                ) : (
                  <>
                    <img
                      src={edit}
                      alt="icon"
                      data-bs-toggle="modal"
                      data-bs-target="#createEvent"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        editEvent(eventData.eventId);
                      }}
                    />
                    <img
                      src={mdelete}
                      alt="icon"
                      data-bs-target="#deleteEvent"
                      data-bs-toggle="modal"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        delTest(eventData.eventId);
                      }}
                    />
                  </>
                )}
                <img
                  src={crs}
                  alt="icon"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body scroll-body">
                <span className="popup-head">
                  <span className="read-more"
                    style={{
                      background : calendarEventCategories?.find( cat => eventData.calendarEventCategoryID == cat.id)?.colour
                    }}
                  ></span>
                  <p>{eventData.title}</p>
                </span>
                {eventData.description !== "" && (
                  <span className="popup-body">
                    <img src={p5} alt="icon" />
                    <span>
                      <p className="p-text">Description</p>
                      <p className="p-sub-text">{eventData.description}</p>
                    </span>
                  </span>
                )}
                <span className="popup-body">
                  <img src={p2} alt="icon" />
                  <span>
                    <p className="p-text">Scheduled</p>
                    <p className="p-sub-text">
                      {moment
                        .utc(eventData.eventStartDateTime)
                        .local()
                        .format("MMMM Do YYYY, h:mm A") + " - "}{" "}
                      {moment
                        .utc(eventData.eventEndDateTime)
                        .local()
                        .format("YYYY-MM-DD") >
                      moment
                        .utc(eventData.eventStartDateTime)
                        .local()
                        .format("YYYY-MM-DD")
                        ? moment
                            .utc(eventData.eventEndDateTime)
                            .local()
                            .format("MMMM Do YYYY, h:mm A")
                        : moment
                            .utc(eventData.eventEndDateTime)
                            .local()
                            .format("h:mm A")}
                    </p>
                  </span>
                </span>

                {eventData.location !== "" && (
                  <span className="popup-body">
                    <img src={p4} alt="icon" />
                    <span>
                      <p className="p-text">{eventData.location}</p>
                    </span>
                  </span>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <center className="center-content">
          You do not have access to the Launchpad application. To request access
          to Launchpad, or believe you should already have access to Launchpad,
          please reach out to the Launchpad support team through the following
          email: BIS@genmab.com.
        </center>
      </UnauthenticatedTemplate>
    </>
  );
}

const mapStateToProps = ({ event, master }) => ({
  loading: event.loading,
  userGroups: master.userGroups,
  totalRecords: event.totalRecords,
  recordsPerPage: event.recordsPerPage,
  allEvents: event.event,
  calendarEventCategories : master.calendarEventCategories,
  paginationOptions: event.paginationOptions,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllUserGroups: () => dispatch(masterAction.fetchAllUserGroups()),
  fetchCalendarEventCategories: () => dispatch(masterAction.fetchCalendarEventCategories()),
  fetchEvent: () => dispatch(action.fetchEvent()),
  deleteModal: (val) => dispatch(action.deleteModal(val)),
  getPaginationData: (values) => dispatch(action.getPaginationData(values)),
  dateEventFilter: (val) => dispatch(dateEventFilter(val)),
  fetchSearch: (values) => dispatch(action.fetchSearch(values.toLowerCase())),
});

export default connect(mapStateToProps, mapDispatchToProps)(Event);
