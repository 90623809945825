import {eventActions, usersActions} from "./constants";
import Service from "../lib/Service";
import moment from "moment";
import queryString from "query-string";
import { useSelector } from "react-redux";
import {INVALID_BULK_UPLOAD_FILE_FORMAT} from "../constants";

export const setRecordLength =
  (data, records) => async (dispatch, getState) => {
    dispatch({
      type: eventActions.SET_EVENT_RECORDS_LENGTH,
      payload: {
        records: data.totalRecordCount ? data.totalRecordCount : 0,
      },
    });
  };

export const fetchEvent = () => async (dispatch, getState) => {
  const offsetTime = encodeURIComponent(moment().local().format());
  const { paginationOptions, search, eventStart, eventEnd } = getState().event;
  const { pageNo, displayLimit } = paginationOptions;
  const userId = sessionStorage.getItem("userId");

  const searchKeyword = search ? encodeURIComponent(search) : "";

  try {
    dispatch({
      type: eventActions.FETCH_ALL_EVENT_START,
      loading: true,
    });

    const data = await Service.GET({
      name: "GetEventsListByLoginUserId",
      queryString: `userId=${userId}&dateTimeOffset=${offsetTime}&PageNumber=${pageNo}&PageSize=${displayLimit}&fromDate=${
        eventStart || ""
      }&toDate=${eventEnd || ""}&keyword=${searchKeyword || ""}`,
    }).then((res) => {
      if (res.isSuccess) return res;
    });
    dispatch({
      type: eventActions.FETCH_ALL_EVENT_SUCCESS,
      payload: {
        payload:
          data.responseData !== null
            ? data?.responseData?.data?.map((item) => {
                return {
                  start: new Date(moment.utc(item.eventStartDateTime).local()),
                  end: new Date(moment.utc(item.eventEndDateTime).local()),
                  ...item,
                };
              })
            : [],
        loading: false,
      },
    });
    dispatch(
      setRecordLength(
        data.responseData !== null ? data.responseData : [],
        data.totalRecordCount
      )
    );
  } catch (error) {
    dispatch({
      type: eventActions.FETCH_ALL_EVENT_FAILED,
      payload: error.message,
    });
  }
};

export const createNewEvent = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: eventActions.CREATE_NEW_EVENT_START,
    });

    const data = await Service.POST({
      name: "CreateNewEvent",
      payload: payload,
    }).then((res) => {
      if (res.isSuccess) return res.data;
    });
    dispatch({
      type: eventActions.CREATE_NEW_EVENT_SUCCESS,
    });
    dispatch(fetchEvent());
  } catch (error) {
    dispatch({
      type: eventActions.CREATE_NEW_EVENT_FAILED,
      payload: error.message,
    });
  }
};

export const deleteModal = (openModal) => async (dispatch) => {
  dispatch({
    type: eventActions.OPEN_DELETE_MODAL,
    payload: {
      openModal,
    },
  });
};

export const deleteEvent = (id) => async (dispatch) => {
  try {
    dispatch({
      type: eventActions.DELETE_EVENT_START,
    });

    const data = await Service.POST({
      name: "DeleteEventByEventId",
      queryString: `eventId=${id}`,
    }).then((res) => {
      if (res.isSuccess) return res;
    });
    dispatch({
      type: eventActions.DELETE_EVENT_SUCCESS,
      payload: data.responseData,
    });
    dispatch(deleteModal(false));
    dispatch(fetchEvent());
  } catch (error) {
    dispatch({
      type: eventActions.DELETE_EVENT_FAILED,
      payload: error.message,
    });
  }
};

export const updateEventDetails = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: eventActions.UPDATE_EVENT_START,
    });

    const data = await Service.POST({
      name: "UpdateEventDetails",
      payload: payload,
    }).then((res) => {
      if (res.isSuccess) return res.data;
    });
    dispatch({
      type: eventActions.UPDATE_EVENT_SUCCESS,
    });
    dispatch(fetchEvent());
  } catch (error) {
    dispatch({
      type: eventActions.UPDATE_EVENT_FAILED,
      payload: error.message,
    });
  }
};

export const getPaginationData = (data) => async (dispatch, getState) => {
  dispatch({
    type: eventActions.FETCH_PAGINATION_DATA_SUCCESS,
    payload: {
      pageNo: data.pageNo,
      displayLimit: data.displayLimit,
    },
  });

  dispatch(fetchEvent());
};

export const fetchSearch = (values) => async (dispatch) => {
  dispatch({
    type: eventActions.FETCH_SEARCH_INPUT,
    payload: {
      search: values,
      paginationOptions: {
        pageNo: 1,
        displayLimit: 10,
      },
    },
  });
  dispatch(fetchEvent());
};

export const dateEventFilter = (date) => async (dispatch) => {
  dispatch({
    type: eventActions.FETCH_EVENT_FITLER_DATES,
    payload: {
      dates: date,
      paginationOptions: {
        pageNo: 1,
        displayLimit: 10,
      },
    },
  });
  dispatch(fetchEvent());
};
export const fetchDashboardEvents = () => async (dispatch, getState) => {
  const offsetTime = encodeURIComponent(moment().local().format());
  const { fromDate, toDate } = getState().event;
  const userEmpId = sessionStorage.getItem("userEmpId")
    ? sessionStorage.getItem("userEmpId")
    : "";
  try {
    dispatch({
      type: eventActions.FETCH_DASH_EVENTS_BY_LOGIN_ID_START,
      loading: true,
    });

    const data = await Service.GET({
      name: "GetEventsByEmail",
      queryString: `dateTimeOffset=${offsetTime}&PageNumber=${1}&PageSize=${10}&fromDate=${
        fromDate || ""
      }&toDate=${toDate || ""}&empId=${userEmpId}`,
    }).then((res) => {
      if (res.isSuccess) return res;
    });
    dispatch({
      type: eventActions.FETCH_DASH_EVENTS_BY_LOGIN_ID_SUCCESS,
      payload: {
        payload: data.responseData !== null ? data.responseData.data : [],
        loading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: eventActions.FETCH_DASH_EVENTS_BY_LOGIN_ID_FAILED,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  }
};

export const fetchDashboardPopUpEvents = () => async (dispatch, getState) => {
  const offsetTime = encodeURIComponent(moment().local().format());
  const { fromPopUpDate, toPopUpDate } = getState().event;
  const userEmpId = sessionStorage.getItem("userEmpId")
    ? sessionStorage.getItem("userEmpId")
    : "";
  try {
    dispatch({
      type: eventActions.FETCH_DASH_POPUP_EVENTS_BY_LOGIN_ID_START,
      loading: true,
    });

    const data = await Service.GET({
      name: "GetEventsByEmail",
      queryString: `dateTimeOffset=${offsetTime}&PageNumber=${1}&PageSize=${10000}&fromDate=${
        fromPopUpDate || ""
      }&toDate=${toPopUpDate || ""}&empId=${userEmpId}`,
    }).then((res) => {
      if (res.isSuccess) return res;
    });
    dispatch({
      type: eventActions.FETCH_DASH_POPUP_EVENTS_BY_LOGIN_ID_SUCCESS,
      payload: {
        payload: data.responseData !== null ? data.responseData.data : [],
        loading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: eventActions.FETCH_DASH_POPUP_EVENTS_BY_LOGIN_ID_FAILED,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  }
};

export const dashDateEventsFilter = (date) => async (dispatch) => {
  dispatch({
    type: eventActions.FETCH_DASH_EVENTS_FITLER_DATES,
    payload: {
      dates: date,
    },
  });
  dispatch(fetchDashboardEvents());
};

export const dashDatePopUpEventsFilter = (date) => async (dispatch) => {
  dispatch({
    type: eventActions.FETCH_DASH_POPUP_EVENTS_FITLER_DATES,
    payload: {
      dates: date,
    },
  });
  dispatch(fetchDashboardPopUpEvents());
};

export const fetchDashboardDates = () => async (dispatch, getState) => {
  const userEmpId = sessionStorage.getItem("userEmpId")
    ? sessionStorage.getItem("userEmpId")
    : "";
  try {
    dispatch({
      type: eventActions.FETCH_DATES_EVENTS_BY_LOGIN_ID_START,
      loading: true,
    });

    const data = await Service.GET({
      name: "GetEventsDateList",
      queryString: `empId=${userEmpId}`,
    }).then((res) => {
      if (res.isSuccess) return res;
    });
    dispatch({
      type: eventActions.FETCH_DATES_EVENTS_BY_LOGIN_ID_SUCCESS,
      payload: {
        payload: data.responseData !== null ? data.responseData.dates : [],
        loading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: eventActions.FETCH_DATES_EVENTS_BY_LOGIN_ID_FAILED,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  }
};
export const fetchDashboardPopupDates = () => async (dispatch, getState) => {
  const userEmpId = sessionStorage.getItem("userEmpId")
    ? sessionStorage.getItem("userEmpId")
    : "";
  try {
    dispatch({
      type: eventActions.FETCH_DATES_POPUP_EVENTS_BY_LOGIN_ID_START,
      loading: true,
    });

    const data = await Service.GET({
      name: "GetEventsDateList",
      queryString: `empId=${userEmpId}`,
    }).then((res) => {
      if (res.isSuccess) return res;
    });
    dispatch({
      type: eventActions.FETCH_DATES_POPUP_EVENTS_BY_LOGIN_ID_SUCCESS,
      payload: {
        payload: data.responseData !== null ? data.responseData.dates : [],
        loading: false,
      },
    });
  } catch (error) {
    dispatch({
      type: eventActions.FETCH_DATES_POPUP_EVENTS_BY_LOGIN_ID_FAILED,
      payload: {
        loading: false,
        error: error.message,
      },
    });
  }
};

export const uploadBulkEvents = (value) => async (dispatch) => {
  try {
    dispatch({
      type: eventActions.ADD_BULK_EVENTS_START,
    });

    const data = await Service.POST({
      name: "BulkEventCreate",
      payload: value,
    }).then((res) => {
      if (res.isSuccess && res.statusCode === 200) {
        return res;
      } else {
        return {
          displayMessage: res.displayMessage,
          statusCode: res.statusCode,
        };
      }
    });
    dispatch({
      type: eventActions.ADD_BULK_EVENTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: eventActions.ADD_BULK_EVENTS_FAILED,
      payload: error.message,
    });
  }
};

export const uploadBulkEventsValidate = (value) => async (dispatch) => {
  try {
    dispatch({
      type: eventActions.VALIDATE_BULK_EVENTS_START,
    });

    const data = await Service.POST({
      name: "BulkEventValidate",
      payload: value,
    }).then((res) => {
      if (res.isSuccess && res.statusCode === 200) {
        return res;
      } else {
        return {
          displayMessage: INVALID_BULK_UPLOAD_FILE_FORMAT,
          statusCode: res.statusCode,
        };
      }
    });
    dispatch({
      type: eventActions.VALIDATE_BULK_EVENTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: eventActions.VALIDATE_BULK_EVENTS_FAILED,
      payload: error.message,
    });
  }
};

export const resetBulkEventsState = () => async (dispatch) => {
  dispatch({
    type: eventActions.BULK_EVENT_UPLOAD_RESET
  });
};