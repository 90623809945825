import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import Item from "./Item";
import { restrictToVerticalAxis  } from '@dnd-kit/modifiers';

const SortableItem = (props) => {
  const sortable = useSortable({ id: props.id,modifiers: [restrictToVerticalAxis]});
  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
  } = sortable;

    const style = {
      cursor: "pointer",
      zIndex: isDragging ? "100" : "auto",
      opacity: isDragging ? 0.3 : 1,
    
    };

  return (
    <Item
      ref={setNodeRef}
      style={style}
      id={props.id}
      {...props}
      searchInput = {props.searchInput}
    />
  );
};

export default SortableItem;
