import { Autocomplete, Avatar, Box, InputAdornment, TextField, Typography, Chip, IconButton } from "@mui/material";
import { AppsIcon, UserIcon, DeleteIcon, GroupIcon, CheckIcon } from "../icons";
import {useEffect, useState} from "react";

const AssignAppAutocomplete = ({ config, options, assignedValue, placeholder, updateSelectedValue}) => {

    const [value, setValue] = useState(null);
    const type = config.type;
    const getName = (type, option) => {
        switch (type) {
            case 'user':
            case 'user-select':
                return option?.userName;
            case 'app-select':
            case 'app':
                return option?.appName;
            case 'value':
            case 'value-select':
                return option?.value;
        }

    }

    useEffect(() => {
        if(assignedValue === undefined || assignedValue === null) {
            setValue(null);
        }
    }, [assignedValue]);

    const handleSelectedValue = (event, value, type) => {
        updateSelectedValue(event, value)
        if(type === 'app-select' || type === 'user-select' || type === 'value-select') {
            autocompleteProps.value = null;
            setValue(null);
        } else {
            setValue(value);
        }
    }

  const defaultRenderTags = (value, getTagProps) => {
    return (value.map((option, index) => {
      const { key, onDelete, ...tagProps } = getTagProps({ index });
      return (
        <Chip variant="outlined"
        deleteIcon={<IconButton sx={{p: 0, '&:hover': {background: 'none'}}}><DeleteIcon /></IconButton>}
        label={
          <Box display='flex' gap="0.3125rem" alignItems='center'>
            <Typography sx={{ fontSize: '0.875rem', fontFamily: "Manrope Medium", lineHeight: '142.857%', color: '#344054' }}>{getName(type, option)}</Typography>
          </Box>
        } 
        key={key} 
        {...tagProps}
        onDelete={onDelete}
        />
      );
    }))}

    const autocompleteProps = {
      value: value,
      fullWidth: true,
      multiple: config.multiple,
      disablePortal: true,
      popupIcon: false,
      clearIcon: null,
      onChange: (event, value) => handleSelectedValue(event, value, config.type),
      options: options,
      getOptionLabel: (option) => getName(type, option),
      renderOption: (props, option) => {
        const { "aria-selected": ariaSelected } = props;
        return (
          <Box component="li" sx={{ gap: '0.5rem', '&:hover p': { color: '#101828',}}} {...props}>
            <Typography sx={{ fontSize: '1rem', lineHeight: '150%', color: ariaSelected ? '#101828' : '#344054', flex: type !== 'group' ? 'none' : 1 }}>{getName(config.type, option)}</Typography>
            {ariaSelected && <CheckIcon />}
          </Box>
        )
      },
      renderInput: (params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">
                  {type === 'user' ? <UserIcon /> : type === 'app' ? <AppsIcon /> : <GroupIcon />}
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            ),
          }}
        />
      ),
      renderTags: defaultRenderTags
    };
    return <Autocomplete {...autocompleteProps} />;
}
export default AssignAppAutocomplete;