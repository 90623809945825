import { Avatar, Box, Chip, Divider, IconButton, Typography } from "@mui/material";
import { AppsIcon, GroupIcon, MinusIcon, PlusIcon, UserIcon } from "./icons";
import { useState } from "react";

const initialState = {
    background: '#fff',
    opacity: '100%',
    transition: 'all ease-in-out .3s',
    // borderTop: '0.0625rem solid #D0D5DD',
    '&:hover': {
        background: '#F9FAFB',
    }
}

const SelectedViewItem = ({index, type, item, removeSelectedApp}) => {
    const [showAddIcon, setShowAddIcon] = useState(false);
    const [strikeText, setStrikeText] = useState(false);
    const [additionalStyles, setAdditionalStyles] = useState(initialState);

    const getName = (type, item) => {
        switch (type){
            case 'value':
                return item.value;
            case 'user':
                return item.userName;
            case 'group':
                return item.groupName;
        }
    }

    const handleRemove = () => {
        removeSelectedApp('remove', item)
    }

    const handleAdd = () => {
        setShowAddIcon(false)
        setStrikeText(false);
        
        setAdditionalStyles({
            background: '#F5FFFA',
            '&:hover': {
                background: '#F5FFFA',
            }
        });
        setShowNewChip(true);
        setTimeout(() => {
            setAdditionalStyles({
                ...initialState
            })
        }, 2000);
    }

    return (
        <>
            {index !== 0 && <Divider sx={{ width: '100%', height: '0.0625rem', opacity: 1, border:'none', background: '#D0D5DD' }} />}
            <Box 
                sx={{
                    mixBlendMode: 'multiply',
                    transition: 'all ease-in-out .3s',
                    ...additionalStyles,
                }} 
                display='flex' 
                gap={1} 
                alignItems='center' 
                p="0.5rem 0.75rem" 
            >
                <Avatar sx={{width: '2.5rem', height: '2.5rem', background: '#F2F4F7'}}>{ type === 'apps' ? <AppsIcon /> : type === 'user' ? <UserIcon /> : <GroupIcon /> }</Avatar>
                <Box flex={1} display='flex' alignItems='center' gap={1}>
                    <Typography sx={{ textDecoration: strikeText ? "line-through": "none" }}>{getName(type, item)}</Typography>
                    {item.status === 'add' && <Chip label="New" variant="contained" color="success" />}
                </Box>

                {showAddIcon ? (
                    <IconButton sx={{borderRadius: '0.5rem', p: '0.625rem'}} onClick={handleAdd}><PlusIcon /></IconButton>
                ) : (
                    <IconButton sx={{borderRadius: '0.5rem', p: '0.625rem'}} onClick={handleRemove}><MinusIcon /></IconButton>
                )}
                
            </Box>
        </>
    )   
}

export default SelectedViewItem;