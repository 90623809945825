import React, { useState, useEffect } from "react";
import Modal from "../UI/Modal";
import sucess from "../../assets/images/new-images/sucess.png";
import SearchIcon from "../../assets/images/search.svg";
import up from "../../assets/images/new-images/up.png";
import * as actions from "../../actions/actions-users";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { createNewEvent, updateEventDetails } from "../../actions/action-event";
import { useSelector } from "react-redux";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Alert, AlertTitle } from "@mui/material";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

const AddEvents = ({
  onAddEvent,
  allEvents,
  setAllEvents,
  filterEvent,
  setFilterEvent,
  calendarEventCategories,
  filterEventIndex,
  editmode,
  setEditMode,
  groupsList = [],
  users,
  message,
  updateEvent,
  events,
  setEditCalendar,
  setEditCalendarSuccess,
  fetchUsersListByRolesID,
  ...props
}) => {
  const [userGroups, setUserGroups] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const loginData = useSelector((state) => state.loginReducer.loginData);

  const initialState = {
    title: "",
    description: "",
    location: "",
    start: moment(),
    end: moment(),
    groupIdList: [],
    userId: [],
  };

  const [event, setEvent] = useState(initialState);

  const { start, end, groupIdList, userId } = event;
  const [selectChecked, setSelectChecked] = useState(false);

  useEffect(() => {
    {
      editmode ? setEvent(filterEvent) : setEvent(initialState);
    }
  }, [editmode, filterEvent]);

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (!editmode) {
      setEvent({ ...event, [name]: value });
    } else {
      setFilterEvent({
        ...filterEvent,
        [name]: value,
      });
    }
  };

  const successHandler = () => {
    setSubmitted(!submitted);
    setEditMode(false);
  };

  const onSelect = (value) => {
    setEvent({ ...event, start: value[0]._d, end: value[1]._d });
  };

  const [userSearchInput, setUserSearchInput] = useState("");

  const userSearchHandler = (e) => {
    setUserSearchInput(e.target.value);
  };

  let userSearchFilter =
    users?.length > 0 &&
    usersList &&
    usersList?.length &&
    usersList.filter((item) => {
      if (userSearchInput === "") {
        return item;
      } else if (
        item.userName?.toLowerCase().includes(userSearchInput.toLowerCase()) ||
        item.email?.toLowerCase().includes(userSearchInput.toLowerCase())
      ) {
        return item;
      }
      return null;
    });

  const [groupsSearchInput, setGroupsSearchInput] = useState("");

  const groupsSearchHandler = (e) => {
    setGroupsSearchInput(e.target.value);
  };

  let groupsSearchFilter =
    userGroups?.length > 0 &&
    userGroups
      ?.filter((i) => i.status === true)
      ?.filter((search) => {
        if (groupsSearchInput === "") {
          return search;
        }
        if (
          search.value.toLowerCase().includes(groupsSearchInput?.toLowerCase())
        ) {
          return search;
        }
        return null;
      });

  const usersSelect = (e) => {
    setSelectChecked(e.target.checked);

    if (e.target.checked) {
      setSelectedUsers(
        usersList.map((i) => {
          return {
            ...i,
            checked: e.target.checked,
          };
        })
      );
    } else if (e.target.checked === false) {
      setSelectedUsers([]);
    }
  };

  const [selectGroupChecked, setSelectGroupChecked] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectCategory, setSelectCategory] = useState({});

  useEffect(() => {
    fetchUsersListByRolesID(selectedGroups.map((i) => i.id));
  }, [selectedGroups]);
  
  const usersHandler = (e, val) => {
    if (e.target.checked) {
      setSelectedUsers([...selectedUsers, val]);
    } else if (e.target.checked === false) {
      setSelectChecked(false);
      setSelectedUsers(selectedUsers.filter((i) => i.userId !== val.userId));
    }
  };

  const categoriesHandler = (e, category) => {
    if (e.currentTarget.checked) {
      setSelectCategory(category);
    } else if (e.currentTarget.checked === false) {
      setSelectCategory({})
    }
  };

  const groupSelect = (e) => {
    setSelectGroupChecked(e.target.checked);
    if (e.target.checked) {
      setSelectedGroups(
        userGroups.map((i) => {
          return {
            ...i,
            ischecked: e.target.checked,
          };
        })
      );
    } else if (e.target.checked === false) {
      setSelectedGroups([]);
    }
  };

  const groupHandler = (e, val) => {
    if (e.target.checked) {
      setSelectedGroups([...selectedGroups, val]);
    } else if (e.target.checked === false) {
      setSelectGroupChecked(false);
      setSelectedGroups(selectedGroups.filter((i) => i.id !== val.id));
    }
  };

  const defaultState = {
    title: false,
    types : [],
    startDate: false,
    endDate: false,
    groups: false,
    users: false,
  };
  const [touched, setTouched] = useState(defaultState);
  const titlehandleFocus = (e) => {
    setTouched({ ...touched, title: true });
  };
  const typeshandleFocus = (e) => {
    setTouched({ ...touched, types: true });
  };
  const datehandleFocus = (e) => {
    setTouched({ ...touched, startDate: true });
  };
  const datehandleFocus2 = (e) => {
    setTouched({ ...touched, endDate: true });
  };
  const handleFocus2 = (e) => {
    setTouched({ ...touched, flage: true });
  };
  const handleFocus3 = (e) => {
    setTouched({ ...touched, groups: true });
  };
  const handleFocus4 = (e) => {
    setTouched({ ...touched, users: true });
  };
  const currentDate = new Date();
  const todayDate = new Date();
  todayDate.setHours(8, 0, 0, 0);
  const endDate = new Date();
  endDate.setHours(9, 0, 0, 0);
  const [startValue, setStartValue] = React.useState(todayDate);
  const [endValue, setEndValue] = React.useState(endDate);
  const [errorStartDate, setErrorStartDate] = useState(false);
  const [errorEndDate, setErrorEndDate] = useState(false);

  const enteredTitleIsValid = event.title.trim() !== "";
  const enteredTypeIsValid = editmode
    ? filterEvent?.calendarEventCategoryID !== undefined &&
    calendarEventCategories?.find(
        (item) => filterEvent?.calendarEventCategoryID === item.id
      )?.status === true
    : alert.flage !== null;
  const enteredStartDateIsValid =
    (!editmode ? startValue !== null : filterEvent.start !== null) &&
    errorStartDate === false;
  let userValidFilter =
    usersList && usersList.filter((user) => user.checked === true);
  const enteredEndDateIsValid = endValue !== null && errorEndDate === false;
  const enteredUserIsValid = userValidFilter.length > 0;
  let groupValidFilter =
    userGroups && userGroups.filter((group) => group.ischecked === true);
  const enteredGroupIsValid = groupValidFilter.length > 0;

  const titleInputIsInvalid =
    (!enteredTitleIsValid && touched.title) ||
    (touched.startDate && !enteredTitleIsValid) ||
    (touched.endDate && !enteredTitleIsValid) ||
    (touched.groups && !enteredTitleIsValid) ||
    (touched.users && !enteredTitleIsValid);

  const startdateInputIsInvalid =
    (!enteredStartDateIsValid && touched.startDate) ||
    (touched.endDate && !enteredStartDateIsValid);

  const enddateInputIsInvalid = !enteredEndDateIsValid && touched.endDate;

  const typeInputIsInvalid =
    (!enteredTypeIsValid && touched.flage) ||
    (touched.description && !enteredTypeIsValid) ||
    (touched.groups && !enteredTypeIsValid) ||
    (touched.users && !enteredTypeIsValid) ||
    (touched.startDate && !enteredTypeIsValid) ||
    (touched.endDate && !enteredTypeIsValid);

  const groupsInputIsInvalid = !enteredGroupIsValid && touched.groups;

  const usersInputIsInvalid = !enteredUserIsValid && touched.users;

  const submitHandler = (e) => {
    e.preventDefault();
    setTouched({
      title: true,
      startDate: true,
      endDate: true,
      groups: true,
      users: true,
    });
    if (
      !enteredTitleIsValid ||
      !enteredStartDateIsValid ||
      !enteredEndDateIsValid ||
      (!enteredUserIsValid && !enteredGroupIsValid)
    ) {
      return;
    }

    if (editmode) {
      let start = new Date(filterEvent.eventStartDateTime);
      let end = new Date(filterEvent.eventEndDateTime);

      let payload = {
        eventId: event.eventId,
        calendarEventCategoryID : selectCategory?.id,
        title: event.title,
        description: event.description,
        location: event.location,
        eventStartDateTime: moment.utc(filterEvent.eventStartDateTime).format(),
        eventEndDateTime: moment.utc(filterEvent.eventEndDateTime).format(),
        createdBy: filterEvent.createdBy,
        groupIdList: userGroups
          .filter((i) => i.ischecked === true)
          .map((item) => item.id),
        userId: usersList
          .filter((i) => i.checked === true)
          .map((item) => item.userId),
        tagList: filterEvent.tagList,
      };
      updateEvent(payload);
      setEditCalendar(payload);
      setEditCalendarSuccess(true);
    } else {
      // onAddEvent(payload);
      setEvent([...events, event]);
      let date = new Date();
      let payload = {
        title: event.title,
        description: event.description,
        calendarEventCategoryID : selectCategory?.id ? selectCategory?.id : filterEvent.calendarEventCategoryID,
        location: event.location,
        eventStartDateTime: startValue?.$d
          ? moment.utc(startValue?.$d).format()
          : moment.utc().format(),
        eventEndDateTime: endValue?.$d
          ? moment.utc(endValue?.$d).format()
          : moment.utc().format(),
        id: Math.floor(Math.random() * 1000),
        createdBy: 0,
        groupIdList: userGroups
          .filter((i) => i.ischecked === true)
          .map((item) => item.id),
        userId: usersList
          .filter((i) => i.checked === true)
          .map((item) => item.userId),
        tagList: [],
      };
      props.createNewEvent(payload);
      setUserGroups(
        userGroups.map((item) => {
          return {
            ...item,
            ischecked: false,
          };
        })
      );
      setUsersList(
        usersList.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
    }
    setEvent(initialState);
    setTouched(defaultState);
    setSubmitted(!submitted);
    setEndValue(endDate);
    setStartValue(todayDate);
    setSelectChecked(false);
    setSelectGroupChecked(false);
    setSelectedGroups([]);
    setSelectedUsers([]);
    setUsersList([]);
    setSelectCategory({})
  };

  const submitGroupForUsers = (values) => {
    setUserGroups(
      userGroups.map((obj) => {
        if (values.find((ol) => ol.id === obj.id)) {
          return {
            ...obj,
            ischecked: true,
          };
        } else {
          return { ...obj, ischecked: false };
        }
      })
    );
    setGroupsSearchInput("");
  };

  const submitUsers = (values) => {
    setUsersList(
      usersList.map((obj) => {
        if (values.find((ol) => ol.userId === obj.userId)) {
          return {
            ...obj,
            checked: true,
          };
        } else {
          return { ...obj, checked: false };
        }
      })
    );

    setUserSearchInput("");
  };

  const submitCalendarEventCategory = (category) => {
    if (editmode) {
      setFilterEvent({ ...filterEvent, calendarEventCategory: category.id });
    } else {
      setEvent({ ...event, flage: category.id, calendarEventCategory: category.id  });
    }
  };

  useEffect(() => {
    if (editmode) {
      let newVal = {};
      newVal = groupsList.map((item) => {
        if ([...new Set(filterEvent.groupIdList)].includes(item.id)) {
          return { ...item, ischecked: true };
        }
        return item;
      });
      setUserGroups(newVal);
      setSelectedGroups(
        groupsList.filter((i) => filterEvent.groupIdList.includes(i.id))
      );
      if (
        newVal.filter((i) => i.ischecked === true).length === groupsList.length
      ) {
        setSelectGroupChecked(true);
      }
    } else {
      groupsList.length > 0 &&
        setUserGroups(
          groupsList.map((item) => {
            return {
              ...item,
              ischecked: false,
            };
          })
        );
    }
  }, [groupsList, editmode]);

  useEffect(() => {
    if (editmode) {
      let newVal = {};
      newVal =
        users?.length > 0 &&
        users.map((item) => {
          if (filterEvent.userId.some((i) => i === parseInt(item.userId))) {
            return { ...item, checked: true };
          }
          return item;
        });
      setUsersList(newVal ? newVal : []);
      let newArr = [];
      let test = users.filter((item) =>
        filterEvent.userId.includes(item.userId)
      );
      for (let i = 0; i < test.length; i++) {
        if (selectedUsers.indexOf(test[i]) === -1) {
          newArr.push(test[i]);
        }
      }
      setSelectedUsers(newArr);
    } else {
      if ( users.length > 0 ) {
        setUsersList(
          users?.map((item) => {
              return { ...item, checked: false };
            })
        );
      } else {
        setUsersList([]);
      }
    }
  }, [users, editmode, selectedGroups]);

  useEffect(() => {
    if (!editmode || !filterEvent) {
      setUsersList(users);
      setUserGroups(groupsList);
      setSelectedGroups([]);
      setSelectGroupChecked(false);
      setSelectChecked(false);
      setSelectCategory({})
    }
    if ( editmode ) {
      const matchCategory = calendarEventCategories?.find((e) => filterEvent?.calendarEventCategoryID === e.id);
      setSelectCategory(matchCategory ? matchCategory : {} );
    }
  }, [editmode]);

  const resetAllValues = () => {
    setSelectGroupChecked(false);
    setSelectChecked(false);
    setUserGroups(
      userGroups.map((item) => {
        return {
          ...item,
          ischecked: false,
        };
      })
    );
    setUsersList([]);
    setSelectedUsers([]);
    setSelectCategory({});
    setSelectedGroups([]);
    setSelectGroupChecked(false);
    setGroupsSearchInput("");
    setUserSearchInput("");
  };

  const dummy = () => {
    if (userGroups.filter((i) => i.ischecked === true)?.length > 0) {
      setSelectedGroups(userGroups.filter((i) => i.ischecked === true));
    } else {
      setSelectedGroups([]);
    }
  };

  const dummy2 = () => {
    if (
      usersList.length > 0 &&
      usersList.filter((i) => i.checked === true)?.length > 0
    ) {
      setSelectedUsers(usersList.filter((i) => i.checked === true));
    } else {
      setSelectedUsers([]);
    }
  };

  const cancelSubmission = (mode) => {
    if (mode) {
      if (filterEvent.eventId) {
        setSelectCategory(
          calendarEventCategories?.find(
            (i) => filterEvent?.calendarEventCategoryID === i.id
          )
        );
      }
    }
  };

  const todayDate2 = moment();
  let newStart = moment.utc(filterEvent.eventStartDateTime).local().format();

  let newEnd = moment.utc(filterEvent.eventEndDateTime).local().format();

  const [shouldShowAlert, setShouldShowAlert] = useState(false);

  useEffect(() => {
    if (editmode) {
      setShouldShowAlert(
        new Date(filterEvent.eventStartDateTime).setHours(0, 0, 0, 0) < currentDate ||
          new Date(filterEvent.eventEndDateTime).setHours(0, 0, 0, 0) < currentDate
      );
    } else {
      setShouldShowAlert(startValue < currentDate || endValue < currentDate);
    }
  }, [editmode, filterEvent, startValue, endValue, currentDate]);
  return (
    <>
      <Modal
        modalId="createEvent"
        modalLabel="exampleModalLabel"
        className="addUserModal"
      >
        {!submitted ? (
          <div className="modal-dialog modal-dialog-centered m-widht">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {editmode ? "Update Event" : "Add Event"}
                </h5>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setTouched(defaultState);
                    if (!editmode) {
                      setEvent(initialState);
                      setEndValue(endDate);
                      setStartValue(todayDate);
                      resetAllValues();
                    } else {
                      setEditMode(false);
                      setEndValue(endDate);
                      setStartValue(todayDate);
                      setSelectedUsers([]);
                      setSelectCategory({});
                      setUsersList([]);
                    }
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <form className="user-lable">
                  <div className="form-group">
                    <label>
                      Title<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter a Title"
                      name="title"
                      value={!editmode ? event.title : filterEvent.title || ""}
                      onChange={handleChange}
                      onBlur={titlehandleFocus}
                    />
                    {titleInputIsInvalid && (
                      <p className="e-message">Please enter a Event Title</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label>
                      Event Category
                    </label>
                    {!editmode ? (
                      <input
                        style={{ caretColor: "transparent" }}
                        type="text"
                        className="form-control arow"
                        placeholder="Select type"
                        data-bs-target="#eventCategoryModalWindow"
                        data-bs-toggle="modal"
                        name="flage"
                        value={selectCategory?.id ? selectCategory.categoryName : ""}
                        onChange={() => {
                          console.log();
                        }}
                      />
                    ) : (
                      <input
                        style={{
                          caretColor: "transparent",
                          color:
                          calendarEventCategories?.find(
                              (ii) =>
                                filterEvent?.calendarEventCategoryID === ii.id
                            )?.status === false
                              ? "#ccc"
                              : "",
                        }}
                        type="text"
                        className="form-control arow"
                        placeholder="Select type"
                        data-bs-target="#eventCategoryModalWindow"
                        data-bs-toggle="modal"
                        name="calendarEventCategoryId"
                        value={
                          selectCategory?.id ? selectCategory.categoryName : ""
                        }
                        onChange={() => {
                          console.log();
                        }}
                      />
                    )}
                  </div>
                  <div className="form-group">
                    <label>Description</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Add Description"
                      name="description"
                      value={
                        !editmode
                          ? event.description
                          : filterEvent.description || ""
                      }
                      onChange={handleChange}
                      maxLength={250}
                    />
                    <p className="e-black">Maximum 250 characters</p>
                  </div>
                  <div className="form-group">
                    <label>
                      Start Date & Time<sup>*</sup>
                    </label>

                    <div
                      className="relative-box input-setting"
                      onBlur={datehandleFocus}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={5}>
                          <DateTimePicker
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                error={errorStartDate}
                              />
                            )}
                            value={
                              !editmode
                                ? startValue
                                : moment
                                    .utc(filterEvent.eventStartDateTime)
                                    .format() || newStart
                            }
                            onChange={(newValue) => {
                              !editmode
                                ? setStartValue(newValue)
                                : setFilterEvent({
                                    ...filterEvent,
                                    eventStartDateTime:
                                      newValue?.$d || new Date(),
                                  });
                            }}
                            onError={(reason, value) => {
                              if (reason) {
                                setErrorStartDate(true);
                              } else {
                                setErrorStartDate(false);
                              }
                            }}
                            minDate={todayDate}
                          />
                        </Stack>
                      </LocalizationProvider>
                      {startdateInputIsInvalid && (
                        <p className="e-message">
                          Please enter a valid Start Date
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>
                      End Date & Time<sup>*</sup>
                    </label>

                    <div
                      className="relative-box input-setting"
                      onBlur={datehandleFocus2}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={5}>
                          <DateTimePicker
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                error={errorEndDate}
                              />
                            )}
                            minDate={
                              !editmode
                                ? startValue?.$d || todayDate
                                : filterEvent.eventStartDateTime
                            }
                            minTime={
                              !editmode
                                ? startValue &&
                                  new Date(endValue?.$d || endValue).getDate() +
                                    new Date(
                                      endValue?.$d || endValue
                                    ).getMonth() +
                                    1 ===
                                    new Date(startValue).getDate() +
                                      new Date(startValue).getMonth() +
                                      1 &&
                                  new Date(
                                    endValue?.$d || endValue
                                  ).getFullYear() <=
                                    new Date(startValue).getFullYear()
                                  ? moment(startValue?.$d)
                                      .hours(moment(startValue?.$d).hour())
                                      .minutes(moment(startValue?.$d).minutes())
                                  : null
                                : newStart &&
                                  new Date(newStart).getDate() +
                                    new Date(newStart).getMonth() +
                                    1 ===
                                    new Date(newEnd).getDate() +
                                      new Date(newEnd).getMonth() +
                                      1 &&
                                  new Date(newStart).getFullYear() <=
                                    new Date(newEnd).getFullYear()
                                ? moment(newStart)
                                    .hours(moment(newStart).hour())
                                    .minutes(moment(newStart).minutes())
                                : null
                            }
                            value={
                              !editmode
                                ? endValue
                                : moment
                                    .utc(filterEvent.eventEndDateTime)
                                    .format()
                            }
                            onChange={(newValue) => {
                              !editmode
                                ? setEndValue(newValue)
                                : setFilterEvent({
                                    ...filterEvent,
                                    eventEndDateTime:
                                      newValue?.$d || new Date(),
                                  });
                            }}
                            onError={(reason, value) => {
                              if (reason) {
                                setErrorEndDate(true);
                              } else {
                                setErrorEndDate(false);
                              }
                            }}
                          />
                        </Stack>
                      </LocalizationProvider>

                      {enddateInputIsInvalid && (
                        <p className="e-message">
                          Please enter a valid End Date
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form-group ful-w">
                    <label>Location</label>
                    <div className="relative-box">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Add Location"
                        name="location"
                        value={
                          !editmode
                            ? event.location
                            : filterEvent.location || ""
                        }
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label>
                      Group
                    </label>
                    <input
                      style={{ caretColor: "transparent" }}
                      type="text"
                      className="form-control arow"
                      data-bs-target="#ModalToggle01"
                      data-bs-toggle="modal"
                      name="groups"
                      placeholder="Select Group"
                      value={
                        userGroups &&
                        userGroups
                          ?.filter((a) => a.status === true)
                          .filter((a) => a.ischecked === true)
                          .map((item) => item.value)
                          .join(", ")
                      }
                      onChange={() => {
                        console.log();
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      User
                    </label>
                    <input
                      style={{ caretColor: "transparent" }}
                      type="text"
                      className="form-control arow"
                      placeholder="Select User"
                      data-bs-target="#ModalToggle02"
                      data-bs-toggle="modal"
                      name="user"
                      value={
                        usersList.length > 0
                          ? usersList
                              .filter((a) => a.checked === true)
                              .map((item) => item.userName)
                              .join(", ")
                          : ""
                      }
                      onChange={() => {
                        console.log();
                      }}
                    />
                  </div>
                </form>
                {shouldShowAlert && (
                  <Alert
                    sx={{
                      borderRadius: '8px',
                      mb: 2,
                      mt: 3,
                      padding: '1rem',
                      gap: '12px',
                      border: '1px solid #FDA29B',
                      background: '#FFFBFA',

                      '& .MuiAlert-icon': {
                        padding: 0,
                        margin: 0,
                        color: '#ff5c1c',

                        '& svg': {
                          fontSize: '20px'
                        }
                      },

                      '& .MuiAlert-message': {
                        padding: 0,

                        '& .MuiTypography-root': {
                          marginTop: '0.25rem',
                          fontFamily: 'Manrope',
                          fontSize: '14px',
                          fontWeight: 400,
                          color: '#ff5c1c',
                          lineHeight: '142.857%',
                        },

                        '& .MuiAlertTitle-root': {
                          margin: 0,
                          fontFamily: "Manrope SemiBold",
                          color: '#ff5c1c',
                          fontWeight: 600,
                        }
                      }
                    }} variant="outlined" severity="warning">
                    <AlertTitle>The selected dates for this event are in the past.</AlertTitle>
                  </Alert> )
                }
                {groupsInputIsInvalid && usersInputIsInvalid && (
                  <Alert
                  sx={{
                    borderRadius: '8px',
                    mb: 2,
                    mt: 3,
                    padding: '1rem',
                    gap: '12px',
                    border: '1px solid #FDA29B',
                    background: '#FFFBFA',
  
                    '& .MuiAlert-icon': {
                      padding: 0,
                      margin: 0,
                      color: '#B42318',
  
                      '& svg': {
                        fontSize: '20px'
                      }
                    },
  
                    '& .MuiAlert-message': {
                      padding: 0,
  
                      '& .MuiTypography-root': {
                        marginTop: '0.25rem',
                        fontFamily: 'Manrope',
                        fontSize: '14px',
                        fontWeight: 400,
                        color: '#B42318',
                        lineHeight: '142.857%',
                      },
  
                      '& .MuiAlertTitle-root': {
                        margin: 0,
                        fontFamily: "Manrope SemiBold",
                        color: '#B42318',
                        fontWeight: 600,
                      }
                    }
                  }} icon={<ErrorOutlineOutlinedIcon />} variant="outlined" severity="error">
                    <AlertTitle> Please select either a group or a user to assign this event to.</AlertTitle>
                  </Alert> )
                }
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setTouched(defaultState);
                    if (!editmode) {
                      setEvent(initialState);
                      resetAllValues();
                      setEndValue(endDate);
                      setStartValue(todayDate);
                    } else {
                      setEditMode(false);
                      setEndValue(endDate);
                      setStartValue(todayDate);
                      setSelectedUsers([]);
                      setSelectCategory({});
                      setUsersList([]);
                    }
                  }}
                >
                  Cancel
                </button>
                <button onClick={submitHandler} className="btn btn-genmeb ms-3">
                  {editmode ? "Update" : "Add"}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="modal-dialog modal-dialog-centered ">
            <div className="modal-content edit-success">
              <div className="modal-body text-center pt-0">
                {<img src={sucess} alt="icon" className="m-3" />}
                <p className="green-text">Success</p>
                <p>
                  {editmode
                    ? "Event Updated Successfully"
                    : "New Event Created Successfully"}
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-genmeb ms-3"
                  data-bs-dismiss="modal"
                  onClick={successHandler}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        )}
      </Modal>
      {/* User Start */}
      <Modal modalId={"ModalToggle02"} modalLabel={"eventModalLabel7"}>
        <div
          className="modal-dialog modal-dialog-centered me-widht"
          name="users"
          onBlur={handleFocus4}
        >
          <div className="modal-content alt3">
            <div className="modal-body p-0">
              <div className="relative w-100">
                <img src={SearchIcon} alt="icon" className="pop-search" />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={userSearchHandler}
                  value={userSearchInput}
                />
                <img
                  src={up}
                  alt="icon"
                  className="up-icon"
                  data-bs-target="#eventModal"
                  data-bs-dismiss="modal"
                />
              </div>

              {users.length > 0 &&
                usersList?.length > 0 &&
                !userSearchInput && (
                  <div className="s-all">
                    <p>Select All</p>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={selectedUsers.length === usersList.length}
                      onChange={(e) => usersSelect(e)}
                    />
                  </div>
                )}
              {message && <div className="all-check">{message}</div>}
              {users?.length === 0 && (
                <div className="all-check">No User(s) Found</div>
              )}
              {users?.length > 0 &&
              usersList.length > 0 &&
              userSearchFilter.length === 0 ? (
                <div className="all-check">No Match Found</div>
              ) : (
                userSearchFilter.length > 0 &&
                userSearchFilter.map((user, index) => (
                  <div key={index} className="all-check">
                    <p>
                      {user.userName}
                      <span>{user.email}</span>
                    </p>
                    <input
                      type="checkbox"
                      name="users"
                      className="form-check-input"
                      value={user.userId}
                      checked={selectedUsers
                        .map((ir) => ir.userId)
                        .includes(user.userId)}
                      onChange={(e) => usersHandler(e, user)}
                    />
                  </div>
                ))
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  dummy2();
                  setUserSearchInput("");
                  setSelectChecked(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                data-bs-target="#eventModal"
                data-bs-dismiss="modal"
                onClick={() => submitUsers(selectedUsers)}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* Group Start */}
      <Modal modalId={"ModalToggle01"} modalLabel={"eventModalLabel2"}>
        <div
          className="modal-dialog modal-dialog-centered me-widht"
          name="groups"
          onBlur={handleFocus3}
        >
          <div className="modal-content alt1">
            <div className="modal-body p-0">
              <div className="relative w-100">
                <img src={SearchIcon} alt="icon" className="pop-search" />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={groupsSearchHandler}
                  value={groupsSearchInput}
                />
                <img
                  src={up}
                  alt="icon"
                  className="up-icon"
                  data-bs-target="#eventModal"
                  data-bs-dismiss="modal"
                />
              </div>
              {!groupsSearchInput && (
                <div className="s-all">
                  <p>Select All</p>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={
                      selectGroupChecked ||
                      selectedGroups.length === userGroups.length
                    }
                    onChange={(e) => groupSelect(e)}
                  />
                </div>
              )}

              {userGroups && userGroups.length === 0 && (
                <div className="all-check">No Group Found</div>
              )}

              {groupsSearchFilter.length === 0 ? (
                <div className="all-check">No Match Found</div>
              ) : (
                groupsSearchFilter.length > 0 &&
                groupsSearchFilter.map((r) => (
                  <div className="all-check" key={r.id}>
                    <p>{r.value}</p>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="group"
                      value={r.id}
                      checked={selectedGroups.map((ir) => ir.id).includes(r.id)}
                      onChange={(e) => groupHandler(e, r)}
                    />
                  </div>
                ))
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  dummy();
                  setGroupsSearchInput("");
                  setSelectGroupChecked(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={() => submitGroupForUsers(selectedGroups)}
                className="btn btn-genmeb ms-3"
                data-bs-target="#eventModal"
                data-bs-dismiss="modal"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* // Start Calendar Event Category */}
      <Modal
        modalId={"eventCategoryModalWindow"}
        modalLabel={"eventCategoryLabel"}
      >
        <div
          className="modal-dialog modal-dialog-centered me-widht"
          name="flage"
          onBlur={handleFocus2}
        >
          <div className="modal-content alt2">
            <div className="modal-body p-0">
              <div className="s-all">
                <p>Select Calendar Event Category</p>
                <img
                  src={up}
                  alt="icon"
                  className="up-icon"
                  data-bs-dismiss="modal"
                />
              </div>
              {!editmode
                ? calendarEventCategories
                    ?.filter((i) => i.status === true)
                    ?.map((category, index) => (
                      <div className="all-check" key={category.id}>
                        <p
                          style={{
                            textDecoration:
                            category.status === false ? "line-through" : "",
                          }}
                        >
                          {category.categoryName}
                        </p>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="flage"
                            value={category.categoryName}
                            key={alert.flage}
                            onChange={(e) => categoriesHandler(e, category)}
                            checked={selectCategory?.id === category.id}
                            id={`flexRadioDefault${category.id}`}
                          />
                      </div>
                    ))
                : calendarEventCategories.map((category, index) => (
                    <div className="all-check" key={category.id}>
                      <p
                        style={{
                          color: category.status === false ? "#cccccc" : "",
                        }}
                      >
                        {category.categoryName}
                      </p>

                      {category.status === true && (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="calendarEventCategory"
                          value={category.categoryName}
                          key={filterEvent.eventId}
                          onChange={(e) => categoriesHandler(e, category)}
                          checked={selectCategory?.id === category.id}
                          id={`flexRadioDefault${category.id}`}
                        />
                      )}
                    </div>
                  ))}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => cancelSubmission(editmode)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                data-bs-target="#eventModal"
                data-bs-dismiss="modal"
                onClick={() => submitCalendarEventCategory(selectCategory)}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* // end Calendar Event Category */}

      {/* User Start */}
      <Modal
        modalId={"eventModal7"}
        modalLabel={"eventModalLabel7"}
      >
        <div
          className="modal-dialog modal-dialog-centered me-widht"
          name="users"
          onBlur={handleFocus4}
        >
          <div className="modal-content alt3">
            <div className="modal-body p-0">
              <div className="relative w-100">
                <img src={SearchIcon} alt="icon" className="pop-search" />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={userSearchHandler}
                  value={userSearchInput}
                />
                <img
                  src={up}
                  alt="icon"
                  className="up-icon"
                  data-bs-target="#eventModal"
                  data-bs-dismiss="modal"
                />
              </div>

              {users?.length > 0 &&
                usersList.length > 0 &&
                !userSearchInput && (
                  <div className="s-all">
                    <p>Select All</p>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={selectedUsers.length === usersList.length}
                      onChange={(e) => usersSelect(e)}
                    />
                  </div>
                )}
              {message && <div className="all-check">message</div>}
              {users?.length === 0 && (
                <div className="all-check">No User(s) Found</div>
              )}
              {users?.length > 0 &&
              usersList.length > 0 &&
              userSearchFilter.length === 0 ? (
                <div className="all-check">No Match Found</div>
              ) : (
                userSearchFilter.length > 0 &&
                userSearchFilter.map((user, index) => (
                  <div key={index} className="all-check">
                    <p>
                      {user.userName}
                      <span>{user.email}</span>
                    </p>
                    <input
                      type="checkbox"
                      name="users"
                      className="form-check-input"
                      value={user.userId}
                      checked={selectedUsers
                        .map((ir) => ir.userId)
                        .includes(user.userId)}
                      onChange={(e) => usersHandler(e, user)}
                    />
                  </div>
                ))
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  dummy2();
                  setUserSearchInput("");
                  setSelectChecked(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                data-bs-target="#eventModal"
                data-bs-dismiss="modal"
                onClick={() => submitUsers(selectedUsers)}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* Group Start */}
      <Modal
        modalId={"eventModal2"}
        modalLabel={"eventModalLabel2"}
      >
        <div
          className="modal-dialog modal-dialog-centered me-widht"
          name="groups"
          onBlur={handleFocus3}
        >
          <div className="modal-content alt1">
            <div className="modal-body p-0">
              <div className="relative w-100">
                <img src={SearchIcon} alt="icon" className="pop-search" />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={groupsSearchHandler}
                  value={groupsSearchInput}
                />
                <img
                  src={up}
                  alt="icon"
                  className="up-icon"
                  data-bs-target="#eventModal"
                  data-bs-dismiss="modal"
                />
              </div>
              {!groupsSearchInput && (
                <div className="s-all">
                  <p>Select All</p>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={
                      selectGroupChecked ||
                      selectedGroups?.length === userGroups?.length
                    }
                    onChange={(e) => groupSelect(e)}
                  />
                </div>
              )}

              {userGroups && userGroups.length === 0 && (
                <div className="all-check">No Group Found</div>
              )}

              {groupsSearchFilter.length === 0 ? (
                <div className="all-check">No Match Found</div>
              ) : (
                groupsSearchFilter.length > 0 &&
                groupsSearchFilter.map((r) => (
                  <div className="all-check" key={r.id}>
                    <p>{r.value}</p>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="group"
                      value={r.id}
                      checked={selectedGroups.map((ir) => ir.id).includes(r.id)}
                      onChange={(e) => groupHandler(e, r)}
                    />
                  </div>
                ))
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  dummy();
                  setGroupsSearchInput("");
                  setSelectGroupChecked(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={() => submitGroupForUsers(selectedGroups)}
                className="btn btn-genmeb ms-3"
                data-bs-target="#eventModal"
                data-bs-dismiss="modal"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ usersList }) => ({
  users: usersList.usersList,
  message: usersList.message,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsersListByRolesID: (payload) =>
    dispatch(actions.fetchUsersListByRolesID(payload)),
  createNewEvent: (payload) => dispatch(createNewEvent(payload)),
  updateEvent: (payload) => dispatch(updateEventDetails(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEvents);

// export default AddEvents;
