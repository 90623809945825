import React, { useState, useRef } from "react";
import moment from "moment";

const EventWrapper = ({ event, children, onAdd, calendarEventCategories, color }) => {
  const { title, className } = children.props;

  const hourStart =
    event.eventStartDateTime &&
    moment.utc(event.eventStartDateTime).local().hour();
  const hourStop =
    event.eventEndDateTime && moment.utc(event.eventEndDateTime).local().hour();
  const gridRowStart = hourStart + 1;
  const customClass = `${className} rbc-event`;
  const defaultColor = "white";

  let bottomSpan = hourStop - hourStart;

  const openEvent = (data) => {
    onAdd(data);
  };
  return (
    <>
      <div
        title={title}
        className={customClass}
        style={{
          gridRow: `${gridRowStart} / span ${
            bottomSpan === 0 ? 1 : bottomSpan
          }`,
          borderLeft : color ?
            color : defaultColor,
          backgroundColor : color ? color : defaultColor
        }}
      >
        {children.props.children}
        <p className="fade-text">{event?.description}</p>
        <span
          className="show-event-pop"
          data-bs-toggle="modal"
          data-bs-target="#showEvent"
          onClick={() => openEvent(event)}
        >
          Read More
        </span>
      </div>
    </>
  );
};

export default EventWrapper;
