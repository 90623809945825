import React, { useState, useEffect } from "react";
import picker from "../../assets/images/new-images/picker.png";
import Modal from "../UI/Modal";
import sucess from "../../assets/images/new-images/sucess.png";
import failure from "../../assets/images/new-images/error.png";
import * as actions from "../../actions/action-master";
import { validateHTMLColorHex } from "validate-color";
import { connect } from "react-redux";
import {
  createNotificationType,
  updateNotificationType,
} from "../../actions/action-master";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";

const AddNotificationType = ({
  createType,
  notificationTypes,
  setNotificationTypes,
  editmode,
  setEditMode,
  filterTypeIndex,
  filterType,
  setFilterType,
  message,
  loading,
  activeStatus,
  ...props
}) => {
  const initialState = {
    colour: null || "",
    value: "",
  };

  const [userInput, setUserInput] = useState(initialState);

  useEffect(() => {
    {
      editmode ? setUserInput(filterType) : setUserInput(initialState);
    }
  }, [editmode, filterType]);

  const handleChange = (evt) => {
    if (!editmode) {
      setUserInput({ ...userInput, [evt.target.name]: evt.target.value });
    } else {
      setFilterType({
        ...filterType,
        [evt.target.name]: evt.target.value,
      });
    }
  };

  const [submitted, setSubmitted] = useState(false);

  const successHandler = () => {
    setSubmitted(!submitted);
    setEditMode(false);
  };

  const defaultState = {
    value: false,
    colour: false,
  };
  const [touched, setTouched] = useState(defaultState);

  const handleFocus = (e) => {
    let { name } = e.target;
    setTouched({ ...touched, [name]: true });
  };
  const enteredTypeIsValid = userInput.value.trim() !== "";
  const typeInputIsInvalid =
    (!enteredTypeIsValid && touched.value) ||
    (touched.colour && !enteredTypeIsValid);

  const enteredColorIsValid = validateHTMLColorHex(userInput.colour);

  const colorInputIsInvalid = !enteredColorIsValid && touched.colour;

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setTouched({
      value: true,
      colour: true,
    });

    if (!enteredTypeIsValid || !enteredColorIsValid) {
      return;
    }

    if (editmode) {
      let updatedType = userInput;
      let updatedTypes = [...notificationTypes];
      updatedTypes[filterTypeIndex] = updatedType;
      setNotificationTypes(updatedTypes);
      let payload = {
        notificationId: filterType.id,
        typeName: filterType.value.trim(),
        colour: filterType.colour,
        imagebase64String: "",
        imageName: "",
        infoText: "",
        createdBy: 0,
      };
      props.updateNotificationType(payload);
    } else {
      let payload = {
        notificationId: 0,
        imagebase64String: "",
        imageName: "",
        infoText: "",
        createdBy: 0,
        typeName: userInput.value.trim(),
        colour: userInput.colour,
      };

      props.createNotificationType(payload);
    }

    setUserInput(initialState);
    setSubmitted(!submitted);
    setTouched(defaultState);
  };

  

  return (
    <Modal
      modalId={"notificationType"}
      modalLabel={"exampleModalLabel"}
      className={"modal fade"}
    >
      {!submitted ? (
        <div className="modal-dialog modal-dialog-centered modal-400">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {editmode
                  ? "Update Notification Type"
                  : "Add Notification Type"}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
               
                onClick={() => {
                  setTouched(defaultState);
                  if (!editmode) {
                    setUserInput(initialState);
                  } else {
                    setEditMode(false);
                    setFilterType({
                      filterType,
                    });
                  }
                }}
              ></button>
            </div>
            <form className="notifType-box" onSubmit={handleSubmit} >
            <div className="modal-body">
              
                <div className="form-group">
                  <label>Notification Type <sup>*</sup></label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Notification Type(Max Characters  Length - 30)"
                    onChange={handleChange}
                    name="value"
                    value={
                      !editmode ? userInput.value || "" : filterType.value || ""
                    }
                    onBlur={handleFocus}
                    maxLength="30"
                  />
                  {typeInputIsInvalid && (
                    <p className="e-message">
                      Please enter a Notification Type
                    </p>
                  )}
                </div>
                {/* <div className="form-group">
                <label>Dispaly Notification Type Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Dispaly Notification Type Name"
                  onChange={handleChange}
                />
              </div> */}
                <div className="form-group">
                  <label>Background Color <sup>*</sup></label>
                  <div className="form-control color-box">
                    <input
                      type="text"
                      className="color-text-field"
                      placeholder="Add color code "
                      name="colour"
                      value={
                        !editmode ? userInput.colour : filterType.colour || ""
                      }
                      
                      onChange={handleChange}
                    />
                    <input
                      type="color"
                      className="color-lode form-control-color"
                      name="colour"
                      onChange={handleChange}
                      onBlur={handleFocus}
                      
                    />
                    <span className="picker">
                      <img src={picker} alt="icon" />
                    </span>
                  </div>
                  {colorInputIsInvalid && (
                    <p className="e-message">Please select a valid Color</p>
                  )}
                </div>
              
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  setTouched(defaultState);
                  if (!editmode) {
                    setUserInput(initialState);
                  } else {
                    setEditMode(false);
                    setFilterType({
                      filterType,
                    });
                  }
                }}
              >
                Cancel
              </button>
              <button className="btn btn-genmeb ms-3">
                {editmode ? "Update" : "Add"}
              </button>
            </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content edit-success">
            <div className="modal-body text-center pt-0 type-loader">
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    position: "absolute",
                    top: "30%",
                    width: "100%",
                    height: "300PX",
                    justifyContent: "center",
                    alignItems: "start",
                    // background: "#000",
                    zIndex: 99,
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <img
                    src={message && (message === 404 ? failure : sucess)}
                    alt="icon"
                    className="m-3"
                  />
                  <p className={`${message && (message === 404 ? "red-text" : "green-text")}`}>
                    {message && (message === 404 ? "Failure" : "Success")}
                  </p>
                  
                  <p>
                    {message &&
                      (message === 404
                        ?  "Notification Type Already Exists" 
                        : editmode
                        ? "Notification Type Updated Successfully"
                        : "New Notification Type Created Successfully")}
                  </p>
                </>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                data-bs-dismiss="modal"
                onClick={successHandler}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

const mapStateToProps = ({ master }) => ({
  notificationTypes: master.notificationTypes,
  message: master.response,
  loading: master.responseLoading,
});

const mapDispatchToProps = (dispatch) => ({
  createNotificationType: (payload) =>
    dispatch(createNotificationType(payload)),
  updateNotificationType: (payload) =>
    dispatch(updateNotificationType(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNotificationType);
