import React, { useState, useEffect } from "react";
import "../assets/css/myApp.css";
import "../assets/css/new-dashboard.css";
import SearchIcon from "../assets/images/search.svg";
import add from "../assets/images/new-images/add.png";
import deleteIcon from "../assets/images/new-images/delete.png";
import edit from "../assets/images/new-images/edit.png";
import assign from "../assets/images/new-images/assign.png";
import Onboard from "../components/App/Onboard";
import Assign from "../components/App/Assign/Assign";
import ActivateApps from "../components/App/ActivateApps";
import Pagination from "../components/Pagination";
import DeleteApps from "../components/App/DeleteApps";
import sortIcon from "../assets/images/new-images/sortIcon.svg";
import axios from "axios";
import UpdateApp from "../components/App/UpdateApp";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import { setAppsData, setAppsExist } from "../actions/action-apps";
import sortIcon2 from "../assets/images/new-images/sortIcon3.png";
import { MultiSelect } from "react-multi-select-component";
import { useDebouncedCallback } from "use-debounce";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import moment from "moment";
import Service from "../lib/Service"
const { REACT_APP_BACKEND_API_APP } = process.env;

const MyApp = () => {
  const dispatch = useDispatch();
  const appsData = useSelector((state) => state.appsData.appsData);
  const userGroups = useSelector((state) => state.master.userGroups);
  const [appsArr, setAppsArr] = useState(
    appsData.appsList ? appsData.appsList : []
  );
  const [statusAppId, setStatusAppId] = useState(0);

  const [includeInactive, setIncludeInactive] = useState(true);
  const [inactiveItem, setInactiveItem] = useState(null);
  const [activeStatus, setActiveStatus] = useState("Active");

  const resultsPerPage = 10
  const searchDebounceMilisec = 1000
  const [totalResults, setTotalResults] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [pageNum, setPageNum] = useState(1)
  const userGroupList = useSelector((state) =>
    state.master.userGroups?.filter((a) => a.id !== 0)
  );
  const [searchValue, setSearchValue] = useState("");
  const [groupSelection, setGroupSelection] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false)

  const options = userGroupList
    ?.filter((i) => i.status === true)
    ?.map((a) => {
      return {
        value: a.id,
        label: a.value,
        ...a,
      };
    });

  const inActiveTest = (item, status, id) => {
    setInactiveItem(item);
    setActiveStatus(status);
    setStatusAppId(id);
  };

  const [deleteId, setDeleteId] = useState(null);

  const delTest = (id) => {
    setDeleteId(id);
  };

  const fetchApps = async (
    keyword=searchValue,
    groups=groupSelection,
    includeInActive=includeInactive,
    pNum=pageNum
    ) => {
    let token = sessionStorage.getItem("accessToken2");
    const postData = {
      keyword,
      groupIds: groups.length > 0 ? groups.map((a) => a.id) : [],
      includeInActive,
    };
    setLoading(true)

    await Service.POST({
      name: "GetApplicationsListByLoginUserId",
      payload: postData,
      queryString : `PageNumber=${pNum}&PageSize=${resultsPerPage}`,
      headers : { Authorization: `Bearer ${token}` }
    }).then((response) => {
      if (response.statusCode === 200) {
        setTotalResults(response.responseData.totalRecordCount);
        setPageCount(
          Math.ceil(response.responseData.totalRecordCount / resultsPerPage)
        );
        let appsList = response.responseData.data;
        setLoading(false);
        setRefetch(false)

        appsList = appsList.map((item) => {
          return {
            date: new Date(item.createdAt),
            group: userGroups
              ?.filter((a) => item.groupIdList.some((o2) => a.id === o2))
              .map((a) => a.value)
              .join(", "),
            ...item,
          };
        });
        dispatch(setAppsData({ appsList: appsList, pageCount: pageCount }));
        setAppsArr(appsList ? appsList : []);
      } else if (response.statusCode === 404) {
        setTotalResults(0);
        setPageCount(Math.ceil(0));
        setRefetch(false)
        dispatch(setAppsData({ appsList: [], pageCount: 0 }));
        setAppsArr([]);
      }
      setLoading(false);
    });
  }

  const fetchAppsExist = async (
    keyword=searchValue,
    groups=groupSelection,
    includeInActive=includeInactive,
    pNum=pageNum
    ) => {
    let token = sessionStorage.getItem("accessToken2");
    const postData = {
      keyword,
      groupIds: groups.length > 0 ? groups.map((a) => a.id) : [],
      includeInActive,
    };

    await Service.POST({
      name: "GetApplicationsListByLoginUserId",
      payload: postData,
      queryString : `?PageNumber=${pNum}&PageSize=${resultsPerPage}`,
      headers : { Authorization: `Bearer ${token}` }
    }).then((response) => {
      if (response.statusCode === 200) {
        let appsList = response.responseData.data
        dispatch(setAppsExist({ appsList: appsList, pageCount: pageCount }));
      } else if (response.statusCode === 404) {
        dispatch(setAppsExist({ appsList: [], pageCount: 0 }));
      }
    });
  }

  // fetch apps on firstrender and reset page number on componentDidUnmount
  useEffect(()=>{
    fetchApps()
    return ()=>{
      setPageNum(1)
    }
  },[])

  // Refetch apps after app changes (edit, assigment, add, delete, active status)
  useEffect(() => {
    if(refetch){
      fetchApps()
    }
  }, [refetch])

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    setPageNum(selectedPage)
    fetchApps(undefined, undefined, undefined, selectedPage)
  };

  const handleHideInactiveToggle = () =>{
    fetchApps(undefined, undefined, !includeInactive, undefined)
    setIncludeInactive(!includeInactive)
  }

  const handleGroupSelectionChange = (e) => {
    const groups = e
    setGroupSelection(groups)
    setPageNum(1)
    fetchApps(undefined, groups, undefined, 1)
  }

  const handleSearchChange = (value)=>{
    setSearchValue(value)
    setPageNum(1)
    fetchApps(value, undefined, undefined, 1)
  }

  const debouncedChangeHandler = useDebouncedCallback(
    (value) => handleSearchChange(value),
    searchDebounceMilisec
  );

  const debouncedAppSearchHandler = useDebouncedCallback(
    (value) => fetchAppsExist(value, undefined, undefined, 1),
    searchDebounceMilisec
  );


  const [editAppData, setEditAppData] = useState("");
  const [showEditModal, setShowEditModal] = useState(false)
  const [showAssignModal, setShowAssignModal] = useState(false)
  const editClick = (item) => {
    setEditAppData(item);
    setShowEditModal(true)
  };

  const [order, setOrder] = useState("ASC");
  const [ColName, setColName] = useState("");
  const sorting = (col) => {
    setColName(col);
    if (order === "ASC") {
      const sorted = [...appsArr].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setAppsArr(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...appsArr].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setAppsArr(sorted);
      setOrder("ASC");
    }
  };
  const [openAppModal, setOpenAppModal] = useState(false)

  const showAddAppModal = () => {
    setOpenAppModal(true)
  };

  const [selectedApps, setSelectedApps] = useState([]);

  const openAssignAppModal = () => {
    setSelectedApps([]);
    setShowAssignModal(true)
  }

  return (
    <>
      <AuthenticatedTemplate>
        <div>
          <ActivateApps
            modalId={"hideInactiveModal"}
            modalLabel={"hideInactiveModalLabel"}
            inactiveItem={inactiveItem}
            activeStatus={activeStatus}
            setStatusSuccess={setRefetch}
            appId={statusAppId}
          />
          <DeleteApps
            appsArr={appsArr}
            setAppsArr={setAppsArr}
            deleteId={deleteId}
            setDeleteSuccess={setRefetch}
          />
          <UpdateApp
            modalId={"EditAppModal"}
            modalLabel={"exampleModalLabel"}
            editAppData={editAppData}
            setUpdateSuccess={setRefetch}
            searchApp={debouncedAppSearchHandler}
            show={showEditModal}
            setShow={setShowEditModal}
            editClick={editClick}
            setShowAssign={setShowAssignModal}
            setSelectedApps={setSelectedApps}
          />
          <Onboard
            setOnboardSuccess={setRefetch}
            searchApp={debouncedAppSearchHandler}
            editClick={editClick}
            setShowAssign={setShowAssignModal}
            setOpenAppModal={setOpenAppModal}
            openAppModal={openAppModal}
            setSelectedApps={setSelectedApps}
          />
          <Assign
            setAssignSuccess={setRefetch}
            statusSuccess={setRefetch}
            appsArr={appsArr}
            show={showAssignModal}
            setShowAssign={setShowAssignModal}
            selectedApps={selectedApps}
            setSelectedAppsProps={setSelectedApps}
          />

          <section>
            <div>
              <div className="app-header ipad-view setGrid">
                <div className="setTextinbox">
                  <p className="app-heding">App List</p>
                  <div className="app-searching">
                    <div className="relative w-100">
                      <img src={SearchIcon} alt="icon" />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        onKeyUp={(evnt) =>
                          debouncedChangeHandler(evnt.target.value.toLowerCase().trim())
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="applist-right-headbar appbox-right setipadDesign">
                  <div className="appSetRight">
                    <div className="appManag-toggle">
                      <p className="text-w">Hide Inactive</p>
                      <div className="togl-btn">
                        <label className="switch me-3 ms-3">
                          <input
                            type="checkbox"
                            checked={!includeInactive}
                            onChange={handleHideInactiveToggle}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <div className="multiSelect-box">
                      <label>View By Group</label>
                      <MultiSelect
                        options={options}
                        value={groupSelection}
                        onChange={handleGroupSelectionChange}
                        labelledBy="Select"
                        overrideStrings={{
                          allItemsAreSelected: "All Groups are selected.",
                        }}
                      />
                    </div>
                  </div>

                  <div className="btn-appset">
                    <button
                      className="btn btn-genmeb ms-3 me-3 spcl-width r-0ipad"
                      data-bs-target="#assign"
                      onClick={openAssignAppModal}
                    >
                      <img src={assign} alt="icon" className="me-2" />
                      Assign/Unassign Users
                    </button>
                    <button
                      className="btn btn-genmeb w-set"
                      onClick={() => setOpenAppModal(true) }
                      data-bs-target="#Onboard"
                    >
                      <img src={add} alt="icon" className="me-2" /> Add App
                    </button>
                  </div>
                </div>
              </div>
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    position: "absolute",
                    top: "50%",
                    left: "0%",
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "start",
                    // background: "#000",
                    zIndex: 99,
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table-hover app-list table">
                      <thead>
                        <tr>
                          {/* <th /> */}
                          <th>&nbsp;</th>
                          <th>Action</th>
                          <th>#</th>
                          <th
                            className={`${
                              ColName === "appName" ? "sort-highlight" : ""
                            }`}
                          >
                            App Name
                            <span className="ms-2">
                              <img
                                src={
                                  ColName === "appName" && order === "ASC"
                                    ? sortIcon2
                                    : sortIcon
                                }
                                alt="icon"
                                onClick={() => {
                                  sorting("appName");
                                }}
                              />
                            </span>
                          </th>
                          <th
                            className={`${
                              ColName === "displayName" ? "sort-highlight" : ""
                            }`}
                          >
                            Display Name
                            <span className="ms-2">
                              <img
                                src={
                                  ColName === "displayName" && order === "ASC"
                                    ? sortIcon2
                                    : sortIcon
                                }
                                alt="icon"
                                onClick={() => {
                                  sorting("displayName");
                                }}
                              />
                            </span>
                          </th>
                          <th>Information Text</th>
                          <th>Application URL</th>
                          <th>Mobile App URL</th>
                          <th>Groups</th>
                          <th>
                            Status
                            <span className="ms-2"></span>
                          </th>
                          <th
                            className={`${
                              ColName === "createdAt" ? "sort-highlight" : ""
                            }`}
                          >
                            Date Added
                            <span className="ms-2">
                              <img
                                src={
                                  ColName === "createdAt" && order === "ASC"
                                    ? sortIcon2
                                    : sortIcon
                                }
                                alt="icon"
                                onClick={() => {
                                  sorting("createdAt");
                                }}
                              />
                            </span>
                          </th>
                          <th
                              className={`${
                                  ColName === "updatedAt" ? "sort-highlight" : ""
                              }`}
                          >
                            Last Modified Date
                            <span className="ms-2">
                          <img
                              src={
                                ColName === "updatedAt" && order === "ASC"
                                    ? sortIcon2
                                    : sortIcon
                              }
                              alt="icon"
                              onClick={() => {
                                sorting("updatedAt");
                              }}
                          />
                        </span>
                          </th>
                          <th
                              className={`${
                                  ColName === "updatedBy" ? "sort-highlight" : ""
                              }`}
                          >
                            Last Modified By
                            <span className="ms-2">
                          <img
                              src={
                                ColName === "updatedBy" && order === "ASC"
                                    ? sortIcon2
                                    : sortIcon
                              }
                              alt="icon"
                              onClick={() => {
                                sorting("updatedBy");
                              }}
                          />
                        </span>
                          </th>
                        </tr>
                      </thead>

                      <tbody className="text-capitalize">
                        {appsArr?.length === 0 && !searchValue ? (
                          <tr>
                            <td>
                              <p className="events-error">No apps available </p>
                            </td>
                          </tr>
                        ) : searchValue && appsArr.length === 0 ? (
                          <tr>
                            <td>
                              <p className="events-error">No Match Found </p>
                            </td>
                          </tr>
                        ) : (
                          appsArr.map((item, index) => {
                            return (
                              <React.Fragment key={item.appId}>
                                <tr id="accordion1" className="acc-height">
                                  <td>
                                    <button
                                      className="accordion-button collapsed"
                                      data-bs-toggle="collapse"
                                      data-bs-target={`#collapse${item.appId}`}
                                      aria-expanded="false"
                                      aria-controls={`#collapse${item.appId}`}
                                    ></button>
                                  </td>
                                  <td>
                                    <span className="d-flex">
                                      <img
                                        src={edit}
                                        alt="icon"
                                        className="me-3"
                                        data-bs-toggle="modal"
                                        onClick={() => editClick(item) }
                                        data-bs-target="#EditAppModal"
                                      />
                                      <img
                                        src={deleteIcon}
                                        alt="icon"
                                        data-bs-toggle="modal"
                                        data-bs-target="#deleteAppsModal"
                                        onClick={() => {
                                          delTest(item.appId);
                                        }}
                                      />
                                    </span>
                                  </td>
                                  <td>
                                    <span>{index + ( resultsPerPage * (pageNum - 1)) + 1 }</span>
                                  </td>
                                  <td>{item.appName}</td>
                                  <td>{item.displayName}</td>
                                  <td>
                                    {item.description !== "" && item.description !== null
                                      ? item.description
                                      : "N/A"}
                                  </td>
                                  <td>{decodeURIComponent(item.appUrl)}</td>
                                  <td>
                                    {item.mobileAppUrl !== null
                                      ? decodeURIComponent(item.mobileAppUrl)
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {item?.groupIdList?.length > 0
                                      ? item.group
                                      : "N/A"}
                                  </td>
                                  <td className="togl-btn">
                                    <span
                                      className={`w33 ${
                                        item.isActive === true ? "green" : "red"
                                      }`}
                                    >
                                      {item.isActive ? "Active" : "Inactive"}
                                    </span>
                                    <label
                                      className="switch me-3 ms-3"
                                      data-bs-toggle="modal"
                                      data-bs-target="#hideInactiveModal"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={
                                          item.isActive === true ? true : false
                                        }
                                        onChange={() => {
                                          console.log("");
                                        }}
                                        onClick={() => {
                                          inActiveTest(
                                            item,
                                            item.isActive,
                                            item.appId
                                          );
                                        }}
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                  </td>
                                  <td>
                                    {item.date
                                      ? item.date.getMonth() +
                                        1 +
                                        "/" +
                                        item.date.getDate() +
                                        "/" +
                                        item.date.getFullYear()
                                      : ""}
                                  </td>
                                  <td>
                                    {item.updatedAt ? moment(item.updatedAt).local().format("MM/DD/YYYY") : "N/A"}
                                  </td>
                                  <td>
                                    {item.updatedBy ? item.updatedBy : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="11" className="p-0 accordion-td">
                                    <div
                                      id={`collapse${item.appId}`}
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingOne"
                                      data-bs-parent="#accordion1"
                                    >
                                      <div className="accordion-data">
                                        <div className="acc-head">
                                          <p>Information Text</p>
                                          <span>
                                            {item.description !== ""
                                              ? item.description
                                              : "N/A"}
                                          </span>
                                        </div>
                                        <div className="acc-head">
                                          <p>Application URL</p>
                                          <span>
                                            {decodeURIComponent(item.appUrl)}
                                          </span>
                                        </div>
                                        <div className="acc-head">
                                          <p>Mobile App URL</p>
                                          <span>
                                            {item.mobileAppUrl !== null
                                              ? decodeURIComponent(
                                                  item.mobileAppUrl
                                                )
                                              : "N/A"}
                                          </span>
                                        </div>
                                        <div className="acc-head">
                                          <p>Groups</p>
                                          <span>
                                            {item?.groupIdList?.length > 0
                                              ? item.group
                                              : "N/A"}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    setOffset={setPageNum}
                    pageNum={pageNum}
                    count={totalResults}
                    perPage={resultsPerPage}
                  />
                </>
              )}
            </div>
          </section>
        </div>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <center className="center-content">
          You do not have access to the Launchpad application. To request access
          to Launchpad, or believe you should already have access to Launchpad,
          please reach out to the Launchpad support team through the following
          email: BIS@genmab.com.
        </center>
      </UnauthenticatedTemplate>
    </>
  );
};

export default MyApp;
