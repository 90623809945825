import React, { useEffect, useState, useRef } from "react";
import {connect, useDispatch} from "react-redux";
import moment from "moment/moment";
import * as actions from "../../../actions/action-notifications";
import {
  fetchNotificationTypes,
  updateAlertReadStatus,
  persistSharedNotificationReadStatus,
  getSharepointNotificationsReadStatus
} from "../../../actions/action-master";
import {
  dashDateNotificatiionFilter,
  fetchDashboardAlerts,
} from "../../../actions/action-notifications";
import {fetchSharepointData, resetSharepointState} from "../../../actions/actions-sharepoint";
import DateRangePicker from "rsuite/DateRangePicker";
import { useSelector } from "react-redux";
import { Box, Checkbox, Drawer, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, Button as MaterialButton, Typography } from "@mui/material";
import { fetchAllUserRoles } from "../../../actions/action-master";
import { Filter, Search, More, Visit, Down, Up, Close, CheckboxDefault, CheckboxSelected, CalendarIcon } from '../../Icons'
import {renderImageDataUrl} from "../../../middlewares/utils";
import {formatDate, sortByDateKey} from "../utils";
import cloneDeep from 'lodash/cloneDeep';
import { FILTER_BY_DATE } from "../../../actions/constants";

const AlertsDashboard = ({
  fetchNotificationTypes,
  fetchDashboardAlerts,
  fetchAllUserGroups,
  fetchSharepointData, resetSharepointState,
  notificationTypes,
  updateAlertReadStatus,
  persistSharedNotificationReadStatus,
  getSharepointNotificationsReadStatus,
  dismissAlert,
  onAdd,
  dashDateNotificatiionFilter,
  dashAlerts = [],
  sharepointNotificationsReadStatus,
  dashLoading,
  ...props
}) => {

  const loginData = useSelector((state) => state.usersList.dashData);
  const appsData = useSelector((state) => state.usersList.dashApps);
  const [notificationsData, setNotificationsData] = useState([])
  const [ showMoreDetails, setShowMoreDetails ] = useState( null );
  const [ showMenu, setShowMenu ] = useState( null );
  const [ openFilter, setOpenFilter ] = useState( false );
  const dispatch = useDispatch();
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [unfilteredNotifications, setUnfilteredNotifications] = useState([])
  const [notificationFilters, setNotificationFilters] = useState({
    read: false,
    unread: false,
    types: new Map(),
    dateRange: [],
  })
  const [previousNotificationFilters, setPreviousNotificationFilters] = useState(null);

  const sharepointNotifications = useSelector(state =>  state.sharepointReducer.notifications)
  const filterByDate = new Date(new Date().setDate(new Date().getDate() - FILTER_BY_DATE)).toISOString();

  const loadNotifications = (dashApps, sharepointNotifications) => {

    const applications = dashApps?.map(app => ({'appId': app.appId, 'createdAt': app.createdAt }));

    const folders = sharepointNotifications?.filter(notification => notification.folder && notification.children.length > 0) || [];

    let notifications = [];

    if(folders.length === 0) return notifications;

    const expirationDateLimit = new Date();
    expirationDateLimit.setDate(expirationDateLimit.getDate() - 90);

    folders.forEach(f => {

      f.children.forEach(c => {
        const app = applications.find(a => a.appId === f.appId);
        if(!app) return;

        const folder = {...c, ...app}

        const appDate = new Date(app.createdAt);
        const childDate = new Date(c.createdDateTime);

        if(appDate.getTime() < childDate.getTime() && childDate.getTime() > expirationDateLimit) {
          notifications.push(folder)
        }
      });
    });

    return notifications;
  };

  const runSearchQuery = (query) => {
    if(query.length > 2) {
      setSearchQuery(query);
    } else if (query.length === 0) {
      setSearchQuery('');
    }
  };

  useEffect(() => {
    loginData && Object.keys(loginData).length > 0 && fetchNotificationTypes();
    loginData &&
      Object.keys(loginData).length > 0 &&
      dispatch(fetchAllUserRoles());
    loginData && Object.keys(loginData).length > 0 && fetchDashboardAlerts();
  }, [loginData]);


  const filterHandler = () => {

    let filteredNotifications = notificationsData;

    if(notificationFilters.read && !notificationFilters.unread) {
      filteredNotifications = filteredNotifications.filter((alert) => alert.isRead === true);
    }

    if(notificationFilters.unread && !notificationFilters.read){
        filteredNotifications = filteredNotifications.filter((alert) => alert.isRead === false);
    }

    if(notificationFilters.types.size > 0){
      const activeFilters = Array.from(notificationFilters.types)
          .filter(([key, value]) => value === true).map(([key]) => key);
      if(activeFilters.length > 0) filteredNotifications = filteredNotifications.filter((alert) => activeFilters.includes(alert.type));
    }

    if(notificationFilters.dateRange?.length > 0){

      let filterStartDate = notificationFilters.dateRange[0];
      let filterEndDate = notificationFilters.dateRange[1];

      filteredNotifications = filteredNotifications.filter((alert) => {
        let alertDate = new Date(alert.date);
        return alertDate >= filterStartDate && alertDate <= filterEndDate;
      })
    }

    if(searchQuery.length > 2) {
      filteredNotifications = filteredNotifications.filter(notification => notification.title.toLowerCase().includes(searchQuery.toLowerCase()));
    }

    setFilteredNotifications(filteredNotifications ?? []);
  };

  useEffect(() => {
    filterHandler();
  }, [notificationsData, searchQuery]);

  const showFiltersDrawer = () => {
    setOpenFilter(true);
    setPreviousNotificationFilters(cloneDeep(notificationFilters));
  };

  const applyNotificationFilters = () => {
    if(notificationFilters.dateRange?.length > 0) dateFilterHandler(notificationFilters.dateRange);
    filterHandler();
    setOpenFilter(false);
  }

  const dismissNotificationFilters = () => {
    setNotificationFilters(previousNotificationFilters);
    setOpenFilter(false);
  }

  const readFilterHandler = (id, value) => {
    updateAlertReadStatus(id, value);
    setShowMenu(null);
  };

  const updateSharedPointNotificationStatus = (id, read) => {
    let payload = {
      id : id,
      isRead : read,
      filterByDate : filterByDate
    }
    persistSharedNotificationReadStatus(payload);
    setShowMenu(null);
  };

  const setFilterValue = (key, val) => {
    if(key === 'types') {
      let typesMap = new Map(notificationFilters[key].set(val[0], val[1]));
      setNotificationFilters({...notificationFilters, [key]: typesMap})
    } else {
      setNotificationFilters({...notificationFilters, [key]: val})
    }
  }

  const dateFilterHandler = (val) => {

    let date = {};
    date.startDate = val && moment.utc(val[0]).local().format("YYYY-MM-DD");
    date.endDate = val && moment.utc(val[1]).local().format("YYYY-MM-DD");

    dashDateNotificatiionFilter(date);
  };

  const getBorderColor = (notificationTypes, notification) => {
    switch (notification.type) {
      case 'Sharepoint':
        return '#03817A'
      default:
        return notificationTypes?.find(
            (item) => item.id === notification.type
        )?.colour !== null
            ? notificationTypes?.find(
                (item) => item.id === notification.type
            )?.colour
            : "#06a894"
    }
  }

  const getNotificationType = (notificationTypes, notification) => {

    if(notification.type === "sharepoint") return 'Sharepoint';

    //Fall back to categorised notifications.
    return notificationTypes?.find(
      (item) => item.id === notification.type
    )?.status === false
      ? "INACT"
      : notification.type === 0
      ? "DELE"
      : notificationTypes?.find(
          (item) => item.id === notification.type
        )?.value
  }

  useEffect( () => {
    getSharepointNotificationsReadStatus(filterByDate);
  }, [])

  useEffect(() => {
    if(appsData.length > 0) {
      appsData.filter((app) => app.appUrl.includes("sharepoint.com")).forEach(function(app){
        fetchSharepointData(app.appId, app.appUrl)
      });
    } else {
      dispatch(resetSharepointState())
    }
  }, [appsData]);

  const loadSharepointNotifications = (sharepointNotifications, sharepointNotificationsReadStatus) => {

    let notifications = [];

    sharepointNotifications.forEach((sharepointNotification) => {

      notifications.push({
        id: sharepointNotification.id,
        type: 'sharepoint',
        title: sharepointNotification.name,
        date: sharepointNotification.lastModifiedDateTime,
        formattedDate: formatDate(sharepointNotification.lastModifiedDateTime),
        visit: true,
        isRead: sharepointNotificationsReadStatus !== null ? sharepointNotificationsReadStatus?.find(notification => notification.id === sharepointNotification.id)?.isRead : false,
        url: sharepointNotification.webUrl
      });
    });

    return notifications;
  }

  const loadNativeNotifications = (nativeNotifications) => {

    let notifications = [];

    nativeNotifications.forEach((alert) => {
      notifications.push({
        id: alert.id,
        type: alert.notificationTypeID,
        title: alert.title,
        imageBase64String: alert.imageBase64String,
        date: alert.publishOn,
        formattedDate: formatDate(alert.publishOn),
        description: alert.description,
        isRead: alert.isRead,
      });
    });

    return notifications;
  }

  useEffect(() => {
    let notifications = [];
    
    let spNotifications = loadNotifications(appsData,  sharepointNotifications, sharepointNotificationsReadStatus);

    notifications.push(...loadSharepointNotifications(spNotifications, sharepointNotificationsReadStatus));
    notifications.push(...loadNativeNotifications(dashAlerts));

    sortByDateKey(notifications, 'date');
    setNotificationsData(notifications);
    setUnfilteredNotifications(notifications);

  }, [sharepointNotifications, dashAlerts, sharepointNotificationsReadStatus]);


  const notificationReadHandler = (notification) => {
    updateSharedPointNotificationStatus(notification.id, !notification.isRead)
    window.open(notification.url, "_blank")
  }

  return (
    <>
      <div className="alert-bar-box notifications">
        <div className="notifications-header">
          <h3>My Notifications</h3>

          <div className="wrap">
            <div className="input-with-icon">
              <Search />
              <input type="text" placeholder="Search" onChange={(e) => runSearchQuery(e.target.value)} />
            </div>

            <button onClick={() => showFiltersDrawer()}>
              <Filter />
              Filters
            </button>
          </div>
        </div>

        <div className="notifications-body">
          <div className="notifications-wrap">
            {dashLoading && (<p>Loading notifications...</p>) }
            {!dashLoading && filteredNotifications.length === 0 && (<p>No notifications available.</p>) }

            {filteredNotifications?.map((el, index) => (
              <div key={index} className={`notification-row ${!el.isRead ? 'unread' : ''}`} style={{borderColor: getBorderColor(notificationTypes, el)}}>
                <div className="notification-row--header">
                  {!el.isRead && <div className="dot"/>}

                  {el.type === 'sharepoint' ? (
                          <div
                              className="n-img"
                              style={{ border:  "2px solid #06a894",
                              }}
                          >
                          <div
                              className="user-created-box"
                              style={{color: getBorderColor(notificationTypes, el)}}
                          >
                            <span>
                              Alert
                            </span>
                          </div>
                          </div>
                      )
                      :
                      (
                          <div
                              className="n-img"
                              style={{
                                border: el.imageBase64String
                                    ? "none"
                                    : notificationTypes?.find(
                                        (item) => item.id === el.notificationTypeID
                                    )?.status === false || el.notificationTypeID === 0
                                        ? "2px solid #06a894"
                                        : `2px solid ${getBorderColor(notificationTypes, el)}`,
                              }}
                          >
                            {el.imageBase64String ? (
                                <img
                                    src={renderImageDataUrl(el.imageBase64String)}
                                    alt="icon"
                                />
                            ) : (
                                <div
                                    className="user-created-box"
                                    style={{color: getBorderColor(notificationTypes, el)}}
                                >
                            <span>
                              {getNotificationType(notificationTypes, el)}
                            </span>
                                </div>
                            )}
                          </div>
                      )}

                  <div className="wrap">
                    <p>{el.title}</p>
                    <span>{getNotificationType(notificationTypes, el)}</span>
                  </div>
                  <span>{el.formattedDate}</span>
                  {el.description && (
                      <button onClick={() => setShowMoreDetails(showMoreDetails === index ? null : index)} className="view-more">
                        {showMoreDetails === index ? 'Less' : 'More' } details
                        {showMoreDetails === index ? <Up /> : <Down /> }
                      </button>
                  ) }
                  {el.url && (
                        <button className="filled-btn" onClick={() => notificationReadHandler(el)}>
                          Visit
                          <Visit />
                        </button>
                  )}
                  {el.type !== 'sharepoint' && (
                  <div className="more-options">
                    <More onClick={() => setShowMenu(showMenu === index ? null : index)}/>
                    {showMenu === index && (
                        <div className="more-options--wrap">
                          <ul>
                            <li onClick={() => readFilterHandler(el.id, !el.isRead)}>{el.isRead ? `Mark Unread` : `Mark Read`}</li>
                          </ul>
                        </div>
                    )}
                  </div>)}
                </div>

                {showMoreDetails === index && (
                    <div className="notification-row--body">
                      <p>{el.description}</p>
                    </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <Drawer
          anchor='right'
          open={openFilter}
          onClose={() => setOpenFilter(false)}
      >
        <Box display='flex' flexDirection='column' flex={1}>
          <Box p={ 3 } display='flex' alignItems='flex-start'>
            <Box flex={1}>
              <Typography variant="h3">Filters</Typography>
              <Typography variant="body2">Apply filters to the notification list.</Typography>
            </Box>
            <IconButton onClick={() => setOpenFilter(false)} disableRipple>
              <Close />
            </IconButton>
          </Box>
          <Box height='calc(100vh - (102px + 73px))' overflow='auto' px={ 3 } gap={3} pb={3} display='flex' flexDirection='column'>
            <FormControl fullWidth component="fieldset" variant="standard">
              <FormLabel component="legend">
                Read/Unread
              </FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox disableRipple icon={<CheckboxDefault />}
                              checked={notificationFilters.unread}
                              onChange={(e) => setFilterValue('unread', e.target.checked)}
                              checkedIcon={<CheckboxSelected />} />
                  }
                  label="Unread"
                />
                <FormControlLabel
                  control={
                    <Checkbox disableRipple icon={<CheckboxDefault />}
                              checked={notificationFilters.read}
                              onChange={(e) => setFilterValue('read', e.target.checked)}
                              checkedIcon={<CheckboxSelected />} />
                  }
                  label="Read"
                />
              </FormGroup>
            </FormControl>
            <FormControl fullWidth component="fieldset" variant="standard">
              <FormLabel component="legend">
                Type
              </FormLabel>
              <FormGroup>
                {notificationTypes.map((notificationType) => (
                    <FormControlLabel
                        key={notificationType.id} // Assuming labels are unique
                        control={
                          <Checkbox disableRipple icon={<CheckboxDefault />} checkedIcon={<CheckboxSelected />}
                                    checked={notificationFilters['types'].has(notificationType.id)
                                        ? notificationFilters['types'].get(notificationType.id) : false}
                                    onChange={(e) => setFilterValue('types', [notificationType.id, e.target.checked])}
                          />
                        }
                        label={
                          <Typography sx={{color: `${notificationType.colour ? notificationType.colour : '#06a894'} !important`}}>
                            {notificationType.value}
                          </Typography>
                        }
                    />
                ))}
                <FormControlLabel
                    key={'sharepoint'}
                    control={
                      <Checkbox disableRipple icon={<CheckboxDefault />} checkedIcon={<CheckboxSelected />}
                                checked={notificationFilters['types'].has('sharepoint') ? notificationFilters['types'].get('sharepoint') : false}
                                onChange={(e) => setFilterValue('types', ['sharepoint', e.target.checked])}
                      />
                    }
                    label={
                      <Typography sx={{color: '#06a894 !important'}}>
                        Sharepoint
                      </Typography>
                    }
                />
              </FormGroup>
            </FormControl>


            <FormControl fullWidth>
              <FormLabel>Creation date</FormLabel>
              <DateRangePicker
                placeholder="mm/dd/yyyy hh:mm"
                format="MM-dd-yyy"
                onChange={(ValueType) => {
                  setFilterValue('dateRange', ValueType);
                } }
                caretAs={CalendarIcon}
                value={ notificationFilters.dateRange }
                placement={onAdd ? "autoVerticalStart" : "auto"}
                editable={false}
              />
            </FormControl>
          </Box>
          <Box px={3} justifyContent='flex-end' gap={1.5} display='flex' borderTop='1px solid #EAECF0' py={2}>
            <MaterialButton onClick={() => dismissNotificationFilters()} variant="outlined">
              Dismiss
            </MaterialButton>
            <MaterialButton variant="contained" onClick={() => applyNotificationFilters()}>
              Apply
            </MaterialButton>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

const mapStateToProps = ({ master, usersList, alerts }) => ({
  notificationTypes: master.notificationTypes,
  notificationStartDate: usersList.dashNotificationStart,
  notificationEndDate: usersList.dashNotificationEnd,
  dismissResponse: alerts.dismissResponse,
  dashAlerts: alerts.dashAlerts,
  dashLoading: alerts.dashLoading,
  sharepointNotificationsReadStatus: master.sharepointNotificationsReadStatus
});

const mapDispatchToProps = (dispatch) => ({
  fetchDashboardAlerts: () => dispatch(fetchDashboardAlerts()),
  fetchNotificationTypes: () => dispatch(fetchNotificationTypes()),
  updateAlertReadStatus: (id, status) =>
    dispatch(updateAlertReadStatus(id, status)),
  persistSharedNotificationReadStatus : (payload) => 
    dispatch(persistSharedNotificationReadStatus(payload)),
  getSharepointNotificationsReadStatus : (filterByDate) => 
    dispatch(getSharepointNotificationsReadStatus(filterByDate)),
  dashDateNotificatiionFilter: (val) =>
    dispatch(dashDateNotificatiionFilter(val)),
  dismissAlert: (val) => dispatch(actions.dismissAlert(val)),
  fetchSharepointData: (appId, shareUrl) => dispatch(fetchSharepointData(appId, shareUrl)),
  resetSharepointState: () => dispatch(resetSharepointState())
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertsDashboard);
