import React, { useEffect, useState, useCallback, useMemo } from "react";
import { DashboardLayout } from "../components/dashboard-layout";
import brdCum from "../assets/images/new-images/brd-cum-icon.svg";
import explor from "../assets/images/new-images/collap-icon.svg";
import EventWrapper from "../components/Calendar/EventWrapper";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import p2 from "../assets/images/new-images/evt2.svg";
import p4 from "../assets/images/new-images/evt4.svg";
import p5 from "../assets/images/new-images/evt5.svg";
import Modal from "../components/UI/Modal";
import crs from "../assets/images/new-images/m-crs.svg";
import CustomToolbar from "../components/Calendar/CustomToolbar";
import { useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { connect, useDispatch } from "react-redux";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import axios from "axios";
import Loader from "../components/UI/Loader";
import { useNavigate } from "react-router-dom";
import * as masterAction from "../actions/action-master";
import {
  fetchEvent
} from "../actions/action-event";

const { REACT_APP_BACKEND_API_APP } = process.env;

function CalendarView({ fetchEvent, events, calendarEventCategories, fetchCalendarEventCategories }) {
  const userEmpId = sessionStorage.getItem("userEmpId")
    ? sessionStorage.getItem("userEmpId")
    : "";
  const loginData = useSelector((state) => state.loginReducer.dashData);
  const navigate = useNavigate();

  const [allEvents, setAllEvents] = useState([]);

  const [readStatus, setReadStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  let newStartDate = moment("2020-01-01").format("YYYY-MM-DD");
  let newEndDate = moment("2040-01-01").format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(newStartDate);
  const [endDate, setEndDate] = useState(newEndDate);
  const offsetTime = encodeURIComponent(moment().local().format());
  const { instance } = useMsal();
  const activeAccount = instance?.getActiveAccount();
  const fetchRecords = () => {
    let token = sessionStorage.getItem("accessToken2");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    setLoading(true);
    axios
      .get(
        `${REACT_APP_BACKEND_API_APP}/api/GetEventsByEmail?emailAddress=${
          activeAccount?.username
        }&PageSize=${10000}&dateTimeOffset=${offsetTime}&fromDate=${
          startDate || ""
        }&toDate=${endDate || ""}&empId=${userEmpId}`,
        config
      )
      .then((response) => {
        if (response.data.responseData !== null) {
          let result = response.data.responseData.data.map((a) => {
            return {
              id: a.eventId,
              start: new Date(moment.utc(a.eventStartDateTime).local()),
              end: new Date(moment.utc(a.eventEndDateTime).local()),
              ...a,
            };
          });
          setAllEvents(result);
          setReadStatus(false);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    fetchRecords();
    fetchCalendarEventCategories();
    fetchEvent();
  }, []);

  const [eventData, setEventData] = useState([]);
  const openEventHandler = (data) => {
    setEventData(data);
  };

  const localizer = momentLocalizer(moment);

  return (
    <>
      <AuthenticatedTemplate>
        <DashboardLayout>
          <section>
            <div className="breadcrumb">
              <img src={brdCum} alt="logo" />
              <p> |&nbsp;&nbsp; Events </p>
            </div>

            <div className="app-wrapper">
              <div className="app-header dash-calender-mobile">
                <p className="app-heding">Events</p>
                <div>&nbsp;</div>
                <div className="text-right">
                  <img
                    onClick={() => navigate(-1)}
                    className="explor"
                    src={explor}
                    alt="explor"
                  />
                </div>
              </div>
              <div className="event-scroll-box">
                <div className="events-box">
                  <div className="myCustomHeight">
                    {loading ? (
                      <Loader />
                    ) : (
                      <Calendar
                        localizer={localizer}
                        defaultView={Views.DAY}
                        startAccessor="start"
                        endAccessor="end"
                        events={allEvents}
                        views={["day", "week", "month"]}
                        resizable
                        timeslots={1}
                        step={60}
                        components={{
                          eventWrapper: (props) => (
                            <EventWrapper color={calendarEventCategories?.find( cat => events.find( e => e.eventId === props.event.id)?.calendarEventCategoryID == cat.id)?.colour} onAdd={openEventHandler} {...props} />
                          ),
                          toolbar: CustomToolbar,
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </DashboardLayout>
        <Modal
          modalId="showEvent"
          modalLabel="exampleModalLabel"
          className="edit-event"
        >
          <div className="modal-dialog modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-header evet-set">
                <img
                  src={crs}
                  alt="icon"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body scroll-body">
                <span className="popup-head">
                  <span className="read-more"
                    style={{
                      background : 
                      calendarEventCategories?.find( cat => events.find( e => e.eventId === eventData.id)?.calendarEventCategoryID == cat.id)?.colour
                    }}></span>
                  <p>{eventData.title}</p>
                </span>

                {eventData.description !== "" && (
                  <span className="popup-body">
                    <img src={p5} alt="icon" />
                    <span>
                      <p className="p-text">Description</p>
                      <p className="p-sub-text">{eventData.description}</p>
                    </span>
                  </span>
                )}

                <span className="popup-body">
                  <img src={p2} alt="icon" />
                  <span>
                    <p className="p-text">Scheduled</p>
                    <p className="p-sub-text">
                      {moment
                        .utc(eventData.eventStartDateTime)
                        .local()
                        .format("MMMM Do YYYY, h:mm A") + " - "}{" "}
                      {moment
                        .utc(eventData.eventEndDateTime)
                        .local()
                        .format("YYYY-MM-DD") >
                      moment
                        .utc(eventData.eventStartDateTime)
                        .local()
                        .format("YYYY-MM-DD")
                        ? moment
                            .utc(eventData.eventEndDateTime)
                            .local()
                            .format("MMMM Do YYYY, h:mm A")
                        : moment
                            .utc(eventData.eventEndDateTime)
                            .local()
                            .format("h:mm A")}
                    </p>
                  </span>
                </span>

                {eventData.location !== "" && (
                  <span className="popup-body">
                    <img src={p4} alt="icon" />
                    <span>
                      <p className="p-text">{eventData.location}</p>
                    </span>
                  </span>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <center className="center-content">
          You do not have access to the Launchpad application. To request access
          to Launchpad, or believe you should already have access to Launchpad,
          please reach out to the Launchpad support team through the following
          email: BIS@genmab.com.
        </center>
      </UnauthenticatedTemplate>
    </>
  );
}

const mapStateToProps = ({ master, event }) => ({
  calendarEventCategories : master.calendarEventCategories,
  events : event?.event
});

const mapDispatchToProps = (dispatch) => ({
  fetchCalendarEventCategories: () => dispatch(masterAction.fetchCalendarEventCategories()),
  fetchEvent: () => dispatch(fetchEvent())
});


export default  connect(mapStateToProps, mapDispatchToProps)(CalendarView);;
