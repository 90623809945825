import moment from "moment";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions/action-master";
import Circle from "../../assets/images/new-images/add.png";
import deleteIcon from "../../assets/images/new-images/delete.png";
import edit from "../../assets/images/new-images/edit.png";
import AddCalendarEventCategory from "./AddCalendarEventCategory";
import DeleteCalendarEventCategory from "./DeleteCalendarEventCategory";
import { createCalendarEventCategory } from "../../actions/action-master";
import ActivateCalendarEventCategory from "./ActivateCalendarEventCategory";

const CalendarEventCategory = ({
  fetchCalendarEventCategories,
  calendarEventCategories,
  ...props
}) => {
  const init = () => {
    fetchCalendarEventCategories();
  };
  let empId = sessionStorage.getItem("userEmpId");
  useEffect(() => {
    init();
  }, []);
  const data = [];

  const [categories, setCategories] = useState(data);

  const createCategory = (newItem) => {
    setCategories([...categories, newItem]);
  };

  const [deleteId, setDeleteId] = useState(null);

  const removeCategory = (id) => {
    setDeleteId(id);
  };

  const [editmode, setEditMode] = useState(false);
  const [calendarEventCategoryIndex, setCalendarEventCategoryIndex] = useState("");
  const [calendarEventCategory, setCalendarEventCategory] = useState({});
  const editType = (id) => {
    setEditMode(true);
    setCalendarEventCategoryIndex(
      calendarEventCategories?.findIndex((type, index) => type.id === id)
    );
    setCalendarEventCategory(calendarEventCategories?.find((type, index) => type.id === id));
  };
  const [showAll, setShowAll] = useState(false);
  const [inactiveItem, setInactiveItem] = useState(null);
  const [activeStatus, setActiveStatus] = useState("Active");
  const [notifyId, setNotifyId] = useState(false);
  const [statusSuccess, setStatusSuccess] = useState(false);
  const inActiveTest = (item, status, id) => {
    setInactiveItem(item);
    setActiveStatus(status);
    setNotifyId(id);
  };

  return (
    <>
      <AddCalendarEventCategory
        createCategory={createCategory}
        calendarEventCategories={calendarEventCategories}
        setCalendarEventCategories={setCategories}
        editmode={editmode}
        setEditMode={setEditMode}
        calendarEventCategoryIndex={calendarEventCategoryIndex}
        calendarEvent={calendarEventCategory}
        setcalendarEvent={setCalendarEventCategory}
        activeStatus={activeStatus}
      />
      <DeleteCalendarEventCategory
        deleteId={deleteId}
        setCalendarEventCategories={setCategories}
        calendarEventCategories={calendarEventCategories}
      />
      <ActivateCalendarEventCategory
        modalId={"activateModalToggle12"}
        modalLabel={"activateToggleLabel12"}
        inactiveItem={inactiveItem}
        activeStatus={activeStatus}
        setStatusSuccess={setStatusSuccess}
        notifyId={notifyId}
      />
      <div
        className="tab-pane fade show active"
        id="nav-home"
        role="tabpanel"
        aria-labelledby="nav-home-tab"
      >
        <div>
          <div className="setting-header">
            <p className="app-heding">Calendar Event</p>
            <div className="applist-right-headbar notifyMobileBtn">
              <div className="d-flex">
                <p className="text-w">Hide Inactive</p>
                <div className="togl-btn">
                  <label className="switch me-3 ms-3">
                    <input
                      type="checkbox"
                      checked={showAll}
                      onChange={() => setShowAll(!showAll)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              {empId && empId !== "" && empId !== undefined ? (
                <></>
              ) : (
                <button
                  className="btn btn-genmeb  min-w72"
                  data-bs-toggle="modal"
                  data-bs-target="#CalendarEventCategory"
                >
                  <img src={Circle} alt="icon" className="me-2" /> Add
                  Calendar Event
                </button>
              )}
            </div>
          </div>

          <div className="table-responsive notificationTable ">
            <table className="table table-hover">
              <thead className="table-head">
                <tr>
                  <th>
                    #
                  </th>
                  <th>
                    Calendar Event
                  </th>
                  {/* <th>Display Name</th> */}
                  <th>Background Color</th>
                  <th>Status</th>
                  <th>
                    Date Added
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {calendarEventCategories && calendarEventCategories?.length === 0 ? (
                  <tr>
                    <td colSpan="6">No Calendar Event Categories Available</td>
                  </tr>
                ) : (
                  calendarEventCategories
                    ?.filter((i) => (!showAll ? i : i.status === true))
                    ?.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>{item.categoryName}</td>
                        {/* <td>{item.value}</td> */}
                        <td className="center-align">
                          <span
                            className="dot-show"
                            style={{
                              backgroundColor:
                                item.colour !== null && item.colour !== ""
                                  ? item.colour
                                  : "#06a894",
                            }}
                          ></span>
                          <span className="spacer-l">
                            {item.colour !== null && item.colour !== ""
                              ? item.colour
                              : "#06a894"}
                          </span>
                        </td>
                        <td>
                          <div className="togl-btn">
                            <span
                              className={`w33 ${
                                item.status === true ? "green" : "red"
                              }`}
                            >
                              {item.status ? "Active" : "Inactive"}
                            </span>
                            <label
                              className="switch me-3 ms-3"
                              data-bs-toggle="modal"
                              data-bs-target="#activateModalToggle12"
                            >
                              {empId && empId !== "" && empId !== undefined ? (
                                <></>
                              ) : (
                                <>
                                  <input
                                    type="checkbox"
                                    checked={
                                      item.status === true ? true : false
                                    }
                                    onChange={() => {
                                      console.log("");
                                    }}
                                    onClick={() => {
                                      inActiveTest(item, item.status, item.id);
                                    }}
                                  />

                                  <span className="slider round"></span>
                                </>
                              )}
                            </label>
                          </div>
                        </td>
                        <td>
                          {moment(item.createdAt).local().format("MM/DD/YYYY")}
                        </td>

                        <td>
                          {empId && empId !== "" && empId !== undefined ? (
                            <></>
                          ) : (
                            <span className="d-flex">
                              {item.status === true && (
                                <img
                                  src={edit}
                                  alt="icon"
                                  className="me-3"
                                  data-bs-toggle="modal"
                                  data-bs-target="#CalendarEventCategory"
                                  onClick={() => {
                                    editType(item.id);
                                  }}
                                />
                              )}
                              <img
                                src={deleteIcon}
                                alt="icon"
                                onClick={() => removeCategory(item.id)}
                                data-bs-target="#deleteTypesModal"
                                data-bs-toggle="modal"
                              />
                            </span>
                          )}
                        </td>
                      </tr>
                    ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ master }) => ({
  calendarEventCategories: master.calendarEventCategories,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCalendarEventCategories: () => dispatch(actions.fetchCalendarEventCategories()),
  createCalendarEventCategory: (payload) =>
    dispatch(createCalendarEventCategory(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarEventCategory);
