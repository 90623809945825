//export const APIURL = "https://gmb-lpd-api-0001-d.azurewebsites.net";
// export const APIURL = "https://genmab-webapi.azurewebsites.net";
export const MAX_LOGO_SIZE_BYTES = 100000
export const IMAGE_FILE_TYPES = {
    JPG: "image/jpg",
    JPEG: "image/jpeg",
    PNG: "image/png",
    SVG: "image/svg+xml"
}
export const BULK_USER_VALIDATION_ERRORS = [
    '',
    'User has an invalid email',
    'User has an invalid role',
    'User not found in active directory',
    'User already exists'
]
export const USER_ROLES = [
    'Admin',
    'Team Lead',
    'User',
]

export const INVALID_BULK_UPLOAD_FILE_FORMAT = "The uploaded file does not have the expected format. Please try again"
export const EMPTY_BULK_UPLOAD_FILE_FORMAT = "The uploaded file is empty. Please try again"
export const BULK_USER_CREATE_ERROR = "A problem occured while creating the users. Please try again."
export const BULK_EVENT_CREATE_ERROR = "A problem occured while creating the events. Please try again."

export const BULK_EVENT_VALIDATION_ERRORS = [
    '',
    'Event is assigned to invalid users',
    'Event is assigned to invalid groups',
    'Event title already exists',
    'Event date is in the past',
    'Event date range is invalid',
    'Missing users have been assigned',
    'Missing user groups have been assigned',
    'Event with same title added more than once'
]