import Modal from "../UI/Modal";
import React, { useState } from "react";
import sucess from "../../assets/images/new-images/sucess.png";
import * as actions from "../../actions/action-master";
import axios from "axios";
import active from "../../assets/images/new-images/active.png";
import inactive from "../../assets/images/new-images/inactive.png";
import { connect } from "react-redux";
const { REACT_APP_BACKEND_API_APP } = process.env;
import Service from "./../../lib/Service";

const ActivateCalendarEventCategory = ({
  modalId,
  modalLabel,
  inactiveItem,
  activeStatus,
  notifyId,
  setStatusSuccess,
  fetchCalendarEventCategories,
  ...props
}) => {
  const [userInactive, setUserInactive] = useState(false);

  const inactiveHandler = (inactiveItem, status) => {
    let isActive = activeStatus === true ? false : true;
    let token = sessionStorage.getItem("accessToken2");

    const data = Service.POST({
      name: "UpdateCalendarEventCategoryStatus",
      queryString : `CalendarEventCategoryId=${notifyId}&status=${isActive}`, 
      headers : { Authorization: `Bearer ${token}` }
    }).then( async (res) => {
      if (res.isSuccess === true) {
        setStatusSuccess(true);
        setUserInactive(!userInactive);
      }
      fetchCalendarEventCategories()
    });
  };

  const userInactiveHandler = () => {
    setUserInactive(!userInactive);
  };

  return (
    <Modal modalId={modalId} modalLabel={modalLabel} className={"addUserModal"}>
      <div className="modal-dialog modal-dialog-centered modal-sm ">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              
              {activeStatus === true
                ? !userInactive
                  ? "Deactivate Calendar Event Category"
                  : "Success"
                : !userInactive
                ? "Activate Calendar Event Category"
                : "Success"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() =>setUserInactive(false)}
            ></button>
          </div>

          <div className="modal-body text-center">
            {activeStatus === true ? (
              <img
                src={!userInactive ? inactive : sucess}
                alt="icon"
                className="m-3"
              />
            ) : (
              <img
                src={!userInactive ? active : sucess}
                alt="icon"
                className="m-3"
              />
            )}
            <p className="d-text">
              {activeStatus === true
                ? !userInactive
                  ? "Are you sure you want to deactivate the Calendar Event Category?"
                  : "Calendar Event Category deactivated Successfully"
                : !userInactive
                ? "Are you sure you want to Activate the Calendar Event Category?"
                : "Calendar Event Category Activated Successfully"}
            </p>
          </div>
          <div className="modal-footer">
            {!userInactive && (
              <button
                type="button"
                className="btn btn-secondary ms-3"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            )}

            {activeStatus === true ? (
              !userInactive ? (
                <button
                  type="button"
                  className="btn btn-genmeb ms-3"
                  onClick={() => inactiveHandler(inactiveItem, activeStatus)}
                >
                  Deactivate
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-genmeb ms-3"
                  data-bs-dismiss="modal"
                  onClick={userInactiveHandler}
                >
                  Ok
                </button>
              )
            ) : !userInactive ? (
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                onClick={() => inactiveHandler(inactiveItem, activeStatus)}
              >
                Activate
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-genmeb ms-3"
                data-bs-dismiss="modal"
                onClick={userInactiveHandler}
              >
                Ok
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ master }) => ({
  calendarEventCategories: master.calendarEventCategories,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCalendarEventCategories: () => dispatch(actions.fetchCalendarEventCategories()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivateCalendarEventCategory);
