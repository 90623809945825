import {InteractionRequiredAuthError, PublicClientApplication} from "@azure/msal-browser";
import axios from "axios";
import {sharepointApiScopes, msalConfig} from "./authConfig";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig, apiScopes } from "./authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize()
    .then(() => msalInstance.handleRedirectPromise())
    .then((tokenResponse) => {
        sessionStorage.setItem("tokenResponse", tokenResponse);
        if (!tokenResponse) {
            const accounts = msalInstance.getAllAccounts();
            if (accounts.length === 0 && sessionStorage.getItem("tokenResponse")) {
                msalInstance.loginRedirect(apiScopes);
            }
        } else {
        }
    })
    .catch((err) => {
        console.error(err);
    });

if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.enableAccountStorageEvents();
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});

export const fetchToken = (instance) => {
    return new Promise(async (resolve, reject) => {
        await msalInstance.handleRedirectPromise().then(authResult=>{
            // Check if user signed in 
            const account = msalInstance.getActiveAccount() || msalInstance.getAllAccounts()[0];
            if(!account){
                // redirect anonymous user to login page 
                msalInstance.loginRedirect(apiScopes);
            } else {
                try {
                    const response = instance.acquireTokenSilent({
                        ...apiScopes,
                        account: account,
                    });
                    resolve(response);
                } catch (error) {
                    reject(error);
                }
            }
        }).catch(err=>{
            console.log("Error during fetchToken : ", err);
        });
    });
};
export const fetchGraphToken = async () => {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        return Promise.reject("No active accounts found");
    }

    try {
        const response = await msalInstance.acquireTokenSilent({
            scopes: sharepointApiScopes.scopes,
            account: activeAccount || accounts[0]
        });

        if (response) {
            sessionStorage.setItem("graphAccessToken", response.accessToken);
            return response.accessToken;
        }
    } catch (error) {
        console.error("Error during acquireTokenSilent:", error);
    }

    return Promise.reject("Unable to refresh access token.");
};