import React, { useEffect, useState } from "react";
import Modal from "../../UI/Modal";
import SearchIcon from "../../../assets/images/search.svg";
import up from "../../../assets/images/new-images/up.png";
import axios from "axios";
import sucess from "../../../assets/images/new-images/sucess.png";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllUsers } from "../../../actions/actions-users";
import {
  fetchAllUserGroups,
  fetchApplicationAssignedUsersAndGroups,
  fetchAppsByGroupId
} from "../../../actions/action-master";
import {
  fetchAppsByUserId,
  fetchAllUserApps,
} from "../../../actions/actions-users";
import { Loader } from "rsuite";
import {Alert, AlertTitle, Avatar, Box, Button, LinearProgress, Typography} from "@mui/material";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { appsData } from "../../../reducers/reducer-apps";
import { UserIcon, CloseIcon, ContentIcon, GroupIcon, ArrowRight, CheckIcon, AppsIcon, ContentPastIcon, CopyAllIcon, DeleteIcon, GroupIcon, MinusIcon, UserIcon, PlusIcon } from '../icons'
import { Autocomplete, Avatar, Box, Chip, FormLabel, Grid, IconButton, InputAdornment, Tab, Tabs, TextField, Typography } from "@mui/material";
import { CustomAlert } from "../../UI/Alert";
import { CustomAvatar } from "../../UI/Avatar";
import PropTypes from 'prop-types';
import PLACEHOLDER from "../../../assets/images/new-images/placeholder.png";
import AssignAppAutocomplete from "./AssignAppAutocomplete";
import SelectedViewItem from "../SelectedViewItem";
import CircularProgress from "@mui/material/CircularProgress";
const { REACT_APP_BACKEND_API_APP } = process.env;

const STATIC_USERS = [
  { fullName: 'Phoenix Baker', userName: '@phoenix', image: "" },
  { fullName: 'Olivia Rhye', userName: '@olivia', image: "" },
  { fullName: 'Orlando Diggs', userName: '@orlando', image: "" },
  { fullName: 'Andi Lanoe', userName: '@andi', image: "" },
  { fullName: 'Kate Morrison', userName: '@kate', image: "" },
  { fullName: 'Koray Okumus', userName: '@koray', image: "" },
];

const DUMMY_DATA = [
  { fullName: 'Concur', image: '', isNew: true },
  { fullName: 'Beacon', image: '', isNew: false },
  { fullName: 'MMIT Pulse', image: '', isNew: true },
  { fullName: 'Eversana', image: '', isNew: false },
  { fullName: 'Navlin', image: '', isNew: false },
]

const STATIC_APPS = [
  { fullName: 'Beacon', image: PLACEHOLDER },
  { fullName: 'Breakaway Partners', image: PLACEHOLDER },
  { fullName: 'Concur', image: PLACEHOLDER },
];

function a11yProps(index) {
  return {
    id: `selection-tab-${index}`,
    'aria-controls': `selection-tabpanel-${index}`,
  };
}

function SelectionTabs(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`selection-tabpanel-${index}`}
      aria-labelledby={`selection-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ mt: '1.25rem' }}>{children}</Box>}
    </div>
  );
}

SelectionTabs.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Assign = (props) => {
  const initialState = {
    assignApp: [],
    group: [],
    userArr: [],
  };

  const allUsers = useSelector((state) => state?.usersList?.allUsers);
  const allApps = useSelector((state) => state?.usersList?.allApps);
  const allGroups = useSelector((state) => state?.master?.userGroups);

  const [appData, setAppData] = useState(initialState);

  const [submitted, setSubmitted] = useState(false);
  const [selectedApps, setSelectedApps] = useState([...props?.selectedApps]);
  const [selectedTab, setSelectedTab] = React.useState(0);
  // Assign by application
  const [assignSelectedApplication, setAssignSelectedApplication] = useState();
  const allApplicationAssignments = useSelector((state) => state?.master?.applicationAssignments);
  const applicationAssignmentsLoading = useSelector((state) => state?.master?.applicationAssignmentsLoading);
  const [applicationAssignments, setApplicationAssignments] = useState({users: [], groups: []})
  const [availableApplicationUsers, setavailableApplicationUsers] = useState([]);
  const [availableApplicationGroups, setavailableApplicationGroups] = useState([]);
  const updateSelectedApplication = (event, value) => {
    setAssignSelectedApplication(value);
    resetDropdownSelectionState();
  }

  useEffect(() => {
    if(assignSelectedApplication !== null && assignSelectedApplication !== undefined && 'appId' in assignSelectedApplication) {
      dispatch(fetchApplicationAssignedUsersAndGroups(assignSelectedApplication.appId))
    }

  }, [assignSelectedApplication]);

  useEffect(() => {
    setInitialApplicationAssignments(allApplicationAssignments)
  }, [allApplicationAssignments])

  const setInitialApplicationAssignments = (value) => {
    setApplicationAssignments(value);
  }

  const getAvailableApplicationGroups = (allGroups, applicationAssignments) => {
    const referenceIds = new Set(applicationAssignments?.groups?.map(item => item.id));
    // Filter elements in arrayToFilter by checking if their id exists in the referenceIds set
    return allGroups.filter(item => !referenceIds.has(item.id));
  }

  const getAvailableApplicationUsers = (allUsers, applicationAssignments) => {
    const referenceIds = new Set(applicationAssignments?.users?.map(item => item.userId));
    // Filter elements in arrayToFilter by checking if their id exists in the referenceIds set
    return allUsers.filter(item => !referenceIds.has(item.userId) && item.userName !== 'Select All');
  }

  const addSelectedApplicationUser = (event, value) => {
    updateApplicationAssignments('user', 'add', value);
  }

  const addSelectedApplicationGroup = (event, value) => {
    updateApplicationAssignments('group', 'add', value);
  }

  const updateApplicationAssignments = (type, operation, value) => {
    const isUserType = type === 'user';
    const assignmentsKey = isUserType ? 'users' : 'groups';
    const idKey = isUserType ? 'userId' : 'id';

    if (operation === 'add') {
      const updatedStatus = allApplicationAssignments[assignmentsKey].some(item => item[idKey] === value[idKey]) ? 'initial' : 'add';
      const newItem = { ...value, status: updatedStatus };
      const updatedAssignments = [...applicationAssignments[assignmentsKey], newItem];
      setApplicationAssignments((prev) => ({ ...prev, [assignmentsKey]: updatedAssignments }));
    }

    if (operation === 'remove') {
      const filteredAssignments = applicationAssignments[assignmentsKey].filter(item => item[idKey] !== value[idKey]);
      setApplicationAssignments((prev) => ({ ...prev, [assignmentsKey]: filteredAssignments }));
    }
  };

  const updateAssignedApplicationUsers = (type, value) => {
    updateApplicationAssignments('user', type, value);
    resetDropdownSelectionState();
  };

  const updateAssignedApplicationGroups = (type, value) => {
    updateApplicationAssignments('group', type, value);
    resetDropdownSelectionState();
  };

  useEffect(()=>{
    const availableApplicationGroups = getAvailableApplicationGroups(allGroups, applicationAssignments);
    const availableApplicationUsers = getAvailableApplicationUsers(allUsers, applicationAssignments);
    setavailableApplicationGroups(apps=> availableApplicationGroups);
    setavailableApplicationUsers(apps=> availableApplicationUsers);
  }, [allUsers, allGroups, applicationAssignments])

  // Assign by group
  const [assignSelectedGroup, setAssignSelectedGroup] = useState();
  const allGroupApps = useSelector((state) => state?.master?.allGroupApps);
  const allGroupAppsLoading = useSelector((state) => state?.master?.allGroupAppsLoading);
  const [assignedGroupApps, setAssignedGroupApps] = useState([]);
  const [availableGroupApps, setAvailableGroupApps] = useState([]);

  const updateSelectedGroup = (event, value) => {
    setAssignSelectedGroup(value);
    resetDropdownSelectionState();
  }

  useEffect(() => {
    if(assignSelectedGroup !== null && assignSelectedGroup !== undefined && 'id' in assignSelectedGroup) {
      dispatch(fetchAppsByGroupId([assignSelectedGroup.id]))
    }

  }, [assignSelectedGroup]);

  const getAvailableGroupApps = (allApps, assignedGroupApps) => {
    const referenceIds = new Set(assignedGroupApps?.map(item => item.id));
    // Filter elements in arrayToFilter by checking if their id exists in the referenceIds set
    return allApps.filter(item => !referenceIds.has(item.appId) && item.appName !== 'Select All');
  }

  const addSelectedGroupApp = (event, value) => {
    updateAssignedGroupApps('add', value);
  }

  const setInitialAssignedGroupApps = (value) => {
    const initialApps = value?.map(app =>  ({...app, status: 'initial'}))
    setAssignedGroupApps(initialApps);
  }

  const updateAssignedGroupApps = (type, value) => {

    if(type === 'add') {
      const appStatus = allGroupApps.filter(app => app.id === value.appId).length > 0 ? 'initial' : 'add';

      setAssignedGroupApps((assignedGroupApps) =>[...assignedGroupApps, {...value, value: value.appName, id: value.appId, status: appStatus}])
    }

    if(type === 'remove') {
      const filteredApps = assignedGroupApps.filter(app => app.id !== value.id);
      setAssignedGroupApps((assignedGroupApps) => filteredApps)
    }

  };

  useEffect(()=>{
    const availableApps = getAvailableGroupApps(allApps, assignedGroupApps);
    setAvailableGroupApps(apps=> availableApps);
  }, [allApps, allGroupApps, assignedGroupApps])

  useEffect(() => {
    setInitialAssignedGroupApps(allGroupApps)
  }, [allGroupApps])

  // Assign by user
  const [assignSelectedUser, setAssignSelectedUser] = useState();
  const allUserApps = useSelector((state) => state?.usersList?.allUserApps);
  const allUserAppsLoading = useSelector((state) => state?.usersList?.allUserAppsLoading);
  const [assignedUserApps, setAssignedUserApps] = useState([]);
  const [availableUserApps, setAvailableUserApps] = useState([]);

  const updateSelectedUser = (event, value) => {
    setAssignSelectedUser(value);
    resetDropdownSelectionState();
  }

  const addSelectedUserApp = (event, value) => {
    updateAssignedUserApps('add', value);
  }

  const setInitialAssignedUserApps = (value) => {
    const initialApps = value.map(app =>  ({...app, status: 'initial'}))
    setAssignedUserApps(initialApps);
  }

  const updateAssignedUserApps = (type, value) => {

    if(type === 'add') {
      const appStatus = allUserApps.filter(app => app.id === value.appId).length > 0 ? 'initial' : 'add';

      setAssignedUserApps((assignedUserApps) =>[...assignedUserApps, {...value, value: value.appName, id: value.appId, status: appStatus}])
    }

    if(type === 'remove') {
      const filteredApps = assignedUserApps.filter(app => app.id !== value.id);
      setAssignedUserApps((assignedUserApps) => filteredApps)
    }

  };

  const getAvailableUserApps = (allApps, assignedUserApps) => {
    const referenceIds = new Set(assignedUserApps.map(item => item.id));
    // Filter elements in arrayToFilter by checking if their id exists in the referenceIds set
    return allApps.filter(item => !referenceIds.has(item.appId) && item.appName !== 'Select All');
  }

  useEffect(()=>{
    const availableApps = getAvailableUserApps(allApps, assignedUserApps);
    setAvailableUserApps(apps=> availableApps);
  }, [allApps, allUserApps, assignedUserApps])

  useEffect(() => {
    setInitialAssignedUserApps(allUserApps)
  }, [allUserApps])

  useEffect(() => {
    if(assignSelectedUser !== null && assignSelectedUser !== undefined && 'userId' in assignSelectedUser) {
      dispatch(fetchAppsByUserId([assignSelectedUser.userId]))
    }
  }, [assignSelectedUser]);

  const handleTabChange = (event, newValue) => {
    resetTabState();
    setSelectedTab(newValue);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllUsers(true));
    dispatch(fetchAllUserGroups());
    dispatch(fetchAllUserApps());
  }, []);

  const filterUserOptions = (allUsers) => {
    return allUsers.filter(user => user.userName !== 'Select All');
  }

  const filterApplicationsOptions = (allApplications) => {
    return allApplications.filter(app => app.appName !== 'Select All');
  }

  const [errorMsg, setErrorMsg] = useState(null);
  const [displayErrorMsg, setDisplayErrorMsg] = useState(false);
  const [displaySuccessMsg, setDisplaySuccessMsg] = useState(false);

  const getFormData = (selectedTab) => {
    switch(selectedTab) {
      case 0:
        return {
          appIdList: assignedUserApps.map(app => app.id),
          userId: assignSelectedUser.userId,
        };
      case 1:
        return {
          appIdList: assignedGroupApps?.map(app => app.id),
          groupId: assignSelectedGroup.id,
        };
      case 2:
        return {
          applicationId: assignSelectedApplication.appId,
          userIdList: applicationAssignments.users.map(user => user.userId),
          groupIdList: applicationAssignments.groups.map(group => group.id)
        }
    }
  }

  const getFormEndpoint = (selectedTab) => {
    switch(selectedTab) {
      case 0: return 'AssignUserApplications';
      case 1: return 'AssignGroupApplications';
      case 2: return 'AssignApplicationUsersGroups';
    }
  }

  const validateSubmissionData = () => {
    if(selectedTab === 0 && !assignSelectedUser){
      return false;
    }

    if(selectedTab === 1 && !assignSelectedGroup){
      return false;
    }


    if(selectedTab === 2 && !assignSelectedApplication){
      return false;
    }

    return true;
  }

  const submitUserApplicationsHandler = (e) => {

    let token = sessionStorage.getItem("accessToken2");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
    };

    if(!validateSubmissionData()){
      setDisplayErrorMsg("Please ensure that all the fields have values.")
      return;
    }

    let data = getFormData(selectedTab);
    let endpoint = getFormEndpoint(selectedTab);

    axios
        .post(
            `${REACT_APP_BACKEND_API_APP}/api/${endpoint}`,
            data,
            config
        )
        .then(async (res) => {
          setErrorMsg(res.data.statusCode);
          setSubmitted(true);
          if (res.data.statusCode === 200) {
            setDisplaySuccessMsg(true)
          } else {
            setDisplayErrorMsg(true)
          }
        });
  };

  const resetTabState = () => {
    setAssignSelectedGroup(null)
    setAssignSelectedUser(null)
    setApplicationAssignments({users: [], groups: []})
    setAssignSelectedApplication(null);
    setAppData(initialState);
    setSelectedApps([]);
    setSubmitted(false);
    setErrorMsg(null);
    setDisplayErrorMsg(false);
    setDisplaySuccessMsg(false);
  }

  const resetDropdownSelectionState = () => {
    setSubmitted(false);
    setErrorMsg(null);
    setDisplayErrorMsg(false);
    setDisplaySuccessMsg(false);
  }

  const modalClose = () => {
    resetTabState();
    props?.setShowAssign(false)
  }

  React.useEffect(() => {
    setAppData({
      ...appData,
      assignApp: [...selectedApps, ...props?.selectedApps]
    })
  }, [props?.selectedApps, appData.assignApp.length === 0]);

  const EmptySelectionView = ({text, type}) => {
    return (<Box sx={{
      border: '0.0625rem solid #D0D5DD',
      borderRadius: '0.5rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      justifyContent: 'center',
      alignItems: 'center',

      '& p': {
        width: '9.375rem',
        color: '#626D8A',
        textAlign: 'center',
        fontSize: '1rem',
        lineHeight: 'normal'
      }
    }} mt={4} p='2rem 1rem' height={'4rem'}>
      <Typography>
        {text}
      </Typography>
    </Box>)
  }

  const SelectedView = ({type, data, removeSelected}) => {
    return (
      <Box sx={{
        border: '0.0625rem solid #D0D5DD',
        borderRadius: '0.5rem',

        '& p': {
          color: '#1D2939',
          fontFamily: "Manrope Medium !important",
          fontSize: '0.875rem',
          fontWeight: 500,
          lineHeight: 'normal',
        }
      }} mt={4} overflow='auto' maxHeight={'17.875rem'}>
        {data.map((item, index) => {
          return (
            <SelectedViewItem key={item.name} type={type} index={index} item={item} removeSelectedApp={removeSelected}/>
          )
        })}
      </Box>
    )
  }

  return (
    <>
      <Modal
        modalId={"assign"}
        modalLabel={"exampleModalLabel"}
        className={"addUserModal secondary-modal"}
        showModal={props?.show}
      >
          <div className="modal-dialog modal-dialog-centered one-col modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  Assign / Unassign Users to Apps
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={modalClose}
                ></button>
              </div>
              <div className="modal-body">
                <Tabs value={selectedTab} onChange={handleTabChange}>
                  <Tab label="By User" {...a11yProps(0)} />
                  <Tab label="By Group" {...a11yProps(1)} />
                  <Tab label="By Application" {...a11yProps(2)} />
                </Tabs>

                <SelectionTabs value={selectedTab} index={0}>
                  <Box>
                    <FormLabel>Select User</FormLabel>
                    <Box display='flex' gap='0.5rem'>
                      <AssignAppAutocomplete config={{type: 'user', multiple: false}} options={filterUserOptions(allUsers)} assignedValue={assignSelectedUser} placeholder="Select User" updateSelectedValue={updateSelectedUser}/>
                      <IconButton sx={{flexShrink: 0, padding: '0.75rem' }} disabled><CopyAllIcon /></IconButton>
                      <IconButton sx={{flexShrink: 0, padding: '0.75rem' }} disabled><ContentPastIcon /></IconButton>
                    </Box>
                  </Box>
                  {allUserAppsLoading && (
                      <Box   sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        m: 2,
                      }}>
                        <LinearProgress sx={{width: '50%'}}/>
                      </Box>
                  )}

                  {(!allUserAppsLoading && assignSelectedUser && assignedUserApps.length > 0) && <SelectedView type={'value'} data={assignedUserApps} removeSelected={updateAssignedUserApps} />}
                  {(!allUserAppsLoading && assignSelectedUser && assignedUserApps.length === 0) && (
                      <Box sx={{m: 2}}>
                        <Typography paragraph='true' align='center'>User has no apps assigned. Start by selecting one app to be assigned below.</Typography>
                      </Box>
                  )}
                  {(!allUserAppsLoading && !assignSelectedUser) && (
                      <Box sx={{m: 2}}>
                        <Typography paragraph='true' align='center'>Please select a user to see assigned apps.</Typography>
                      </Box>
                  )}

                  {assignSelectedUser && <Box mt={2}>
                    <FormLabel>Select Applications</FormLabel>
                    <AssignAppAutocomplete config={{type: 'app-select', multiple: false}} options={availableUserApps} placeholder="Add application" updateSelectedValue={addSelectedUserApp} />
                  </Box>}

                </SelectionTabs>

                <SelectionTabs value={selectedTab} index={1}>
                  <Box>
                    <FormLabel>Select Group</FormLabel>
                    <Box display='flex' gap='0.5rem'>
                      <AssignAppAutocomplete config={{type: 'value', multiple: false}} options={allGroups} assignedValue={assignSelectedGroup} placeholder="Select Group" updateSelectedValue={updateSelectedGroup}/>
                      <IconButton sx={{flexShrink: 0, padding: '0.75rem' }} disabled><CopyAllIcon /></IconButton>
                      <IconButton sx={{flexShrink: 0, padding: '0.75rem' }} disabled><ContentPastIcon /></IconButton>
                    </Box>
                  </Box>
                  {allGroupAppsLoading && (
                      <Box   sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        m: 2,
                      }}>
                        <LinearProgress sx={{width: '50%'}}/>
                      </Box>
                  )}

                  {(!allGroupAppsLoading && assignSelectedGroup && assignedGroupApps?.length > 0) && <SelectedView type={'value'} data={assignedGroupApps} removeSelected={updateAssignedGroupApps}/>}
                  {(!allGroupAppsLoading && assignSelectedGroup && assignedGroupApps?.length === 0) && (
                      <Box sx={{m: 2}}>
                        <Typography paragraph='true' align='center'>Group has no apps assigned. Start by selecting one app to be assigned below.</Typography>
                      </Box>
                  )}
                  {(!allGroupAppsLoading && !assignSelectedGroup) && (
                      <Box sx={{m: 2}}>
                        <Typography paragraph='true' align='center'>Please select a group to see assigned apps.</Typography>
                      </Box>
                  )}

                  {assignSelectedGroup && <Box mt={2}>
                    <FormLabel>Select Applications</FormLabel>
                    <AssignAppAutocomplete config={{type: 'app-select', multiple: false}} options={availableGroupApps} placeholder="Add application" updateSelectedValue={addSelectedGroupApp} />
                  </Box>}

                </SelectionTabs>
                <SelectionTabs value={selectedTab} index={2}>
                  <Box>
                    <FormLabel>Select Application</FormLabel>
                    <AssignAppAutocomplete config={{type: 'app', multiple: false}} options={filterApplicationsOptions(allApps)} assignedValue={assignSelectedApplication} placeholder="Select Application" updateSelectedValue={updateSelectedApplication}/>
                  </Box>
                  {(!assignSelectedApplication && !applicationAssignmentsLoading) && (
                      <Box sx={{m: 2}}>
                        <Typography paragraph='true' align='center'>Please select an application to see assigned users and groups.</Typography>
                      </Box>
                  )}

                  {assignSelectedApplication && (
                    <Grid container spacing={4}>
                      <Grid item xs={6}>
                        {applicationAssignmentsLoading ? (
                            <Box   sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              m: 2,
                            }}>
                              <LinearProgress sx={{width: '50%'}}/>
                            </Box>
                        ) : ( (applicationAssignments.users?.length > 0) ? <SelectedView type={'user'} data={applicationAssignments.users} removeSelected={updateAssignedApplicationUsers}/> :
                          <EmptySelectionView text={'No users assigned.'} type='user' />)}
                      <Box mt={2}>
                        <FormLabel>Select Users</FormLabel>
                        <AssignAppAutocomplete config={{type: 'user-select', multiple: false}} options={availableApplicationUsers} placeholder="Add Users" updateSelectedValue={addSelectedApplicationUser}  />
                      </Box>
                      </Grid>
                      <Grid item xs={6}>
                        {applicationAssignmentsLoading ? (
                            <Box   sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              m: 2,
                            }}>
                              <LinearProgress sx={{width: '50%'}}/>
                            </Box>
                        ) : ( (applicationAssignments.groups?.length > 0) ? <SelectedView type={"value"} data={applicationAssignments.groups} removeSelected={updateAssignedApplicationGroups} /> :
                            <EmptySelectionView text={'No groups assigned.'} type='group' />)}
                        <Box mt={2}>
                          <FormLabel>Select Groups</FormLabel>
                          <AssignAppAutocomplete config={{type: 'value-select', multiple: false}} options={availableApplicationGroups} placeholder="Add Groups" updateSelectedValue={addSelectedApplicationGroup} />
                        </Box>
                      </Grid>
                    </Grid>)}


                </SelectionTabs>
                {displaySuccessMsg && <CustomAlert title={ "Application assignment saved successfully." } />}
                {displayErrorMsg && <CustomAlert severity='error' title={"An error encountered while trying to save the application assignment."} />}
              </div>
              <div className="modal-footer">
                { errorMsg === 200 && submitted ?
                  <button
                    className="btn btn-genmeb ms-3 submit--btn"
                    data-bs-dismiss="modal"
                    onClick={modalClose}
                  >
                    Done
                  </button> : (
                  <>
                  <button
                    type="button"
                    className="btn btn-secondary cancel--btn"
                    data-bs-dismiss="modal"
                    onClick={modalClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={submitUserApplicationsHandler}
                    className="btn btn-genmeb submit--btn"
                  >
                    Save
                  </button>
                  </>
                  )
                }

              </div>
            </div>
          </div>
        {/* )  */}
      </Modal>
    </>
  );
};

export default Assign;
