import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDropzone } from 'react-dropzone';
import Modal from "../UI/Modal";
import Download from "../../assets/images/circle-down.svg"
import DownloadError from "../../assets/images/download.svg"
import Check from "../../assets/images/check.svg"
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "rsuite";
import Upload from "../../assets/images/document-upload.svg"
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {resetBulkEventsState, uploadBulkEvents, uploadBulkEventsValidate} from "../../actions/action-event";
import { Alert, AlertTitle } from "@mui/material";
import UploadEventErrorTable from "./UploadEventErrorTable";
import ExcelJS from 'exceljs';
import {
  BULK_EVENT_CREATE_ERROR, BULK_EVENT_VALIDATION_ERRORS, EMPTY_BULK_UPLOAD_FILE_FORMAT,
  INVALID_BULK_UPLOAD_FILE_FORMAT,
} from "../../constants";
import {formatDate, joinArrayByComma, renderStringsFromArray} from "../../middlewares/utils";


const AddBulkEvents = (props) => {
  const { modalId, modalLabel, setBulkAddSuccess } = props;
  //Validation
  const loadingValidation = useSelector((state) => state.event.bulkValidateLoading);
  const validateStatusCodeRedux = useSelector((state) => state.event.bulkValidateStatusCode);
  const validateResponseMessage = useSelector((state) => state.event.bulkValidateResponse);
  const validateResponseData = useSelector((state) => state.event.bulkValidateResponseData);
  //Uploading
  const loadingEventUpload = useSelector((state) => state.event.bulkUploadLoading);
  const uploadStatusCodeRedux = useSelector((state) => state.event.bulkUploadStatusCode);
  const uploadResponseMessage = useSelector((state) => state.event.bulkUploadResponse);
  const uploadResponseData = useSelector((state) => state.event.bulkUploadResponseData);

  const [uploadedExcelFileContent, setUploadedExcelFileContent] = useState(0);
  const [exceptionWithExcelfile, setExceptionWithExcelfile] = useState('');
  const [eventUploadStep, setEventUploadStep] = useState(1)
  const [ acceptedFiles, setAcceptedFiles ] = useState([]);
  const dispatch = useDispatch();
  const modalRef = useRef(null);


  useEffect(() => {
    if(uploadStatusCodeRedux === 200) {
      setBulkAddSuccess(true)
    }
  }, [uploadStatusCodeRedux]);

  useEffect(() => {
    if(uploadedExcelFileContent.length > 0) {
      setDataforValidationRequest()
    }
  }, [uploadedExcelFileContent]);

  const setDataforValidationRequest = () => {
    const req = {
      events: []
    };
    uploadedExcelFileContent.map((event, i) => {
      req.events.push({
        title: event?.[0],
        description: event?.[1],
        eventStartDateTime: event?.[2],
        eventEndDateTime: event?.[3],
        location: event?.[4],
        groups: parseUserGroups(event?.[5]),
        userEmails: parseUserEmails(event?.[6])
      })
    })
    dispatch(uploadBulkEventsValidate(req));
  }

  const parseExcelFile = (file) => {
    const reader = new FileReader()

    reader.onload = async (e) => {
      try {
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(e.target.result);
        const worksheet = workbook.getWorksheet(1);
        const eventData = [];

        worksheet.eachRow((row, rowNumber) => {
          if(rowNumber === 1) return;
          const rowData = [];
          row.eachCell({includeEmpty: true},(cell, colNumber) => {
            //Read the best possible representation as text
            let plainText = cell.text;
            if (plainText.richText) {
              // Convert rich text to plain text
              plainText = plainText.richText.map(segment => segment.text).join('');
            }

            rowData.push(plainText);
          });
          eventData.push(rowData);
        });

        if(worksheet.actualRowCount > 1) {
          setUploadedExcelFileContent(eventData);
          setExceptionWithExcelfile('')
        } else {
          setExceptionWithExcelfile(EMPTY_BULK_UPLOAD_FILE_FORMAT)
        }

      } catch (error) {
        setAcceptedFiles([]);
        setExceptionWithExcelfile(INVALID_BULK_UPLOAD_FILE_FORMAT)
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const onDrop = useCallback(acceptedFiles => {
    setAcceptedFiles(acceptedFiles)
    parseExcelFile(acceptedFiles[0]);
  }, [])

  const submitEvents = () => {
    const validEvents =  validateResponseData.events.filter(event => event.validationErrors.length === 0);

    const req = {
      events: validEvents
    };

    dispatch(uploadBulkEvents(req));
    setAcceptedFiles([]);
  };

  useEffect(() => {
    const modalElement = modalRef.current;

    const handleModalHidden = () => {
      dispatch(resetBulkEventsState());
      setEventUploadStep(1);
      setExceptionWithExcelfile('');
      setAcceptedFiles([]);
    };

    if (modalElement) {
      modalElement.addEventListener('hidden.bs.modal', handleModalHidden);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener('hidden.bs.modal', handleModalHidden);
      }
    };
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':  ['.xlsx']
    },
    maxFiles: 1,
    maxSize: 50000000
  });

  const dropZoneError = (error) => {
    console.log(error);
  }

  const continueToNextStep = () => {
    if(eventUploadStep === 1) { // if user is on first step simply show the second page
      setEventUploadStep(2)
    } else if(eventUploadStep === 2) { // if user is on second step move to step 3 and submit the file for upload
      submitEvents();
      setEventUploadStep(3)
    }
  }

  const getInvalidEvents = () => {
    return validateResponseData.events.filter(event => event.validationErrors.length > 0).length;
  }

  const getValidEvents = () => {
    return validateResponseData.events.filter(event => event.validationErrors.length === 0).length;
  }

  const replaceFile = () => {
    setAcceptedFiles([]);
  }

  const parseUserGroups = (groups) => {
    return groups?.split(/\s*,\s*/).map(s => s.trim()).filter(s => s.length > 0);
  };

  const parseUserEmails = (users) => {
    return users?.split(/\s*,\s*/).map(s => s.trim()).filter(s => s.length > 0);
  };

  const handleGenerateExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    worksheet.addRow(['Title', 'Description', 'Start Date', 'End Date', 'Location', 'Groups', 'Users', 'Issues found']);
    validateResponseData?.events.map((event) => {
      if(event?.validationErrors.length > 0){
        worksheet.addRow([event?.title, event?.description, formatDate(event.eventStartDateTime), formatDate(event.eventEndDateTime), event.location, joinArrayByComma(event?.groups), joinArrayByComma(event.userEmails), renderStringsFromArray(BULK_EVENT_VALIDATION_ERRORS, event?.validationErrors)]);
      }
    })

    const blob = await workbook.xlsx.writeBuffer();

    const url = window.URL.createObjectURL(new Blob([blob]));
    const a = document.createElement('a');
    a.href = url;
    a.download = "invalid-bulk-upload-events.xlsx";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  // Step 1: Set file error to true when the file is too big
  const [ fileUploadError, setFileUploadError ] = useState(false);

  return (
    <>
      <Modal
        modalId={"bulkEventsUpload"}
        modalLabel={modalLabel}
        className={"addUserModal"}
        modalRef={modalRef}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-l"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Add Bulk Calendar Events
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body" style={ { paddingBottom: '1.88rem', minHeight: '35.75rem' } }>

              <>
              {/* Step 1 Header */}
              {(eventUploadStep === 1) && (
              <div className="d-flex align-items-center justify-content-between">
                <Typography sx={ {
                  color: '#626D8A',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '171.429%',
                  letterSpacing: '0.28px',
                }}>Import file</Typography>
                <a href="./sample-events-sheet.xlsx" download={'sample-events-sheet.xlsx'} className="download-template">
                  <img src={Download} alt="Download" />
                  Download template
                </a>
              </div>)}
              {/* Step 1 Header Ends */}

              {/* Step 1 Sceanario: Show dropzone, no file selected yet */}
              { eventUploadStep === 1 && !loadingValidation && acceptedFiles.length === 0 && (
              <>
              <Box
                  className={fileUploadError && "MuiAlert-root" }
                  role={ fileUploadError && "alert"}
                  sx={{
                    cursor: 'pointer',
                    marginTop: '1.25rem',
                    borderRadius: '8px',
                    height: '28.875rem',
                    border: '1px solid #D9D9D9',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    '&.MuiAlert-root': {
                      borderColor: '#FF7066',
                      '&~.MuiAlert-message': {
                        color: '#A71106',
                        marginTop: '0.25rem',
                      }
                    },
                    '& .MuiTypography-root': {
                      fontSize: '1rem',
                      letterSpacing: 'normal',
                      lineHeight: ' normal',
                      fontWeight: 400,
                      '& span': {
                        color: '#06A894',
                        fontFamily: "Manrope",
                      }
                    },
                    ...(isDragActive && { backgroundColor: '#F8FAFF' })
                  }}
                  {...getRootProps()}
              >
                <input {...getInputProps()} />
                <img src={Upload} alt="" />
                <Typography sx={{marginTop: '1rem'}}>Drag and drop CSV file</Typography>
                <Typography sx={{ marginTop: '0.25rem' }}>
                  or <Typography component='span'>Browse from computer</Typography>
                </Typography>
                <Typography>(max 50MB)</Typography>
              </Box>
              { fileUploadError && <Typography className="MuiAlert-message" variant="body2">This file is too big. Try a file smaller than 50MB.</Typography> }
              </>
              )}

              {/* Step 1 Sceanario: Show dropzone, no file selected yet ends */}

              {/* Step 1 Sceanario: File is being validated */}
              {(eventUploadStep === 1 && loadingValidation) && (
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '1.25rem',
                  borderRadius: '8px',
                  border: '1px solid #D9D9D9',
                  height: '28.875rem',
                }}>
                  <Loader className="primary-loader" />
                  <Typography sx={{fontSize: '1rem', color: '#626D8A', mt: 2}}>
                    Importing file
                  </Typography>
                </Box>
              )}
              {/* Step 1 Sceanario: File is being validated ends*/}

              {/* Step 1 Sceanario: File has been successfully validated, option to replace here */}
              { eventUploadStep === 1 && !loadingValidation && acceptedFiles.length === 1 && (
                <ul className="uploaded-files secondary">
                  <li key={acceptedFiles[0]?.path}>
                    <Box flexGrow={1}>
                      <p title={acceptedFiles[0]?.path}>{ acceptedFiles[ 0 ]?.name }</p>
                      <button onClick={replaceFile}>Replace file</button>
                    </Box>
                    <img src={Check} alt="" />
                  </li>
                </ul>
              )}
              </>
              {/* Step 1 Scenario: File has been successfully validated ends */}


              { eventUploadStep === 2 && (
                  <>
                    { getValidEvents() > 0 && (
                    <Alert sx={ {
                      borderRadius: '8px',
                      mb: 2,
                      padding: '1rem',
                      gap: '12px',
                      border: '1px solid #6CE9A6',
                      background: '#F6FEF9',

                      '& .MuiAlert-icon': {
                        padding: 0,
                        margin: 0,
                        color: '#027A48',

                        '& svg': {
                          fontSize: '20px'
                        }
                      },

                      '& .MuiAlert-message': {
                        padding: 0,

                        '& .MuiTypography-root': {
                          marginTop: '0.25rem',
                          fontFamily: 'Manrope',
                          fontSize: '14px',
                          fontWeight: 400,
                          color: '#027A48',
                          lineHeight: '142.857%',
                        },

                        '& .MuiAlertTitle-root': {
                          fontFamily: "Manrope SemiBold",
                          margin: 0,
                          color: '#027A48',
                          fontWeight: 600,
                        }
                      }
                    }} icon={<CheckCircleOutlineOutlinedIcon />} variant="outlined" severity="success">
                      <AlertTitle>{getValidEvents()}/{validateResponseData.events.length} calendar events can be imported.</AlertTitle>
                    </Alert>)}

                    { getInvalidEvents() > 0 && (
                    <>
                    <Alert sx={ {
                      borderRadius: '8px',
                      mb: 2,
                      padding: '1rem',
                      gap: '12px',
                      border: '1px solid #FDA29B',
                      background: '#FFFBFA',

                      '& .MuiAlert-icon': {
                        padding: 0,
                        margin: 0,
                        color: '#B42318',

                        '& svg': {
                          fontSize: '20px'
                        }
                      },

                      '& .MuiAlert-message': {
                        padding: 0,

                        '& .MuiTypography-root': {
                          marginTop: '0.25rem',
                          fontFamily: 'Manrope',
                          fontSize: '14px',
                          fontWeight: 400,
                          color: '#B42318',
                          lineHeight: '142.857%',
                        },

                        '& .MuiAlertTitle-root': {
                          margin: 0,
                          fontFamily: "Manrope SemiBold",
                          color: '#B42318',
                          fontWeight: 600,
                        }
                      }
                    }} icon={<ErrorOutlineOutlinedIcon />} variant="outlined" severity="error">
                      <AlertTitle>{getInvalidEvents()}/{validateResponseData.events.length} calendar events have import errors</AlertTitle>
                      <Typography>These events encountered errors when importing. Try importing these events again.</Typography>
                    </Alert>
                    <div className="d-flex align-items-center justify-content-between">
                      <Typography sx={ {
                        color: '#626D8A',
                        fontSize: '14px',
                        fontWeight: 600,
                        lineHeight: '171.429%',
                        letterSpacing: '0.28px',
                      }}>Events that can’t be imported</Typography>
                      <button className="download-template" onClick={handleGenerateExcel}>
                        <img src={Download} alt="Download" />
                        Download events that can’t be imported (.xlsx)
                      </button>
                    </div>
                    <UploadEventErrorTable events={validateResponseData.events} />
                    </>)}

                    { validateResponseData.newUserGroups.length > 0 && (
                    <Alert sx={ {
                      borderRadius: '8px',
                      mt: 2,
                      padding: '1rem',
                      gap: '12px',
                      border: '1px solid #6CE9A6',
                      background: '#F6FEF9',

                      '& .MuiAlert-icon': {
                        padding: 0,
                        margin: 0,
                        color: '#027A48',

                        '& svg': {
                          fontSize: '20px'
                        }
                      },

                      '& .MuiAlert-message': {
                        padding: 0,

                        '& .MuiTypography-root': {
                          marginTop: '0.25rem',
                          fontFamily: 'Manrope',
                          fontSize: '14px',
                          fontWeight: 400,
                          color: '#027A48',
                          lineHeight: '142.857%',
                        },

                        '& .MuiAlertTitle-root': {
                          margin: 0,
                          fontFamily: "Manrope SemiBold",
                          color: '#027A48',
                          fontWeight: 600,
                        }
                      }
                    }} icon={<CheckCircleOutlineOutlinedIcon />} variant="outlined" severity="success">
                      <AlertTitle>{uploadResponseData.events.length}/{getValidEvents()} events will be created</AlertTitle>
                    </Alert>)}

                  </>)}

              {(eventUploadStep === 3 && loadingEventUpload) && (
                  <>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '1.25rem',
                    height: '28.875rem',
                  }}>
                    <Loader className="primary-loader" />
               
                  </Box>
                  </>
              )}

              { eventUploadStep === 3 && uploadStatusCodeRedux === 200 && (
                  <>
                    <Alert sx={ {
                      borderRadius: '8px',
                      mb: 2,
                      padding: '1rem',
                      gap: '12px',
                      border: '1px solid #6CE9A6',
                      background: '#F6FEF9',

                      '& .MuiAlert-icon': {
                        padding: 0,
                        margin: 0,
                        color: '#027A48',

                        '& svg': {
                          fontSize: '20px'
                        }
                      },

                      '& .MuiAlert-message': {
                        padding: 0,

                        '& .MuiTypography-root': {
                          marginTop: '0.25rem',
                          fontFamily: 'Manrope',
                          fontSize: '14px',
                          fontWeight: 400,
                          color: '#027A48',
                          lineHeight: '142.857%',
                        },

                        '& .MuiAlertTitle-root': {
                          fontFamily: "Manrope SemiBold",
                          margin: 0,
                          color: '#027A48',
                          fontWeight: 600,
                        }
                      }
                    }} icon={<CheckCircleOutlineOutlinedIcon />} variant="outlined" severity="success">
                      <AlertTitle>{uploadResponseData.events.length}/{validateResponseData.events.length} events has been successfully created.</AlertTitle>
                    </Alert>
                    { uploadResponseData.newUserGroups?.length > 0 && (
                    <Alert sx={ {
                      borderRadius: '8px',
                      mt: 2,
                      padding: '1rem',
                      gap: '12px',
                      border: '1px solid #6CE9A6',
                      background: '#F6FEF9',

                      '& .MuiAlert-icon': {
                        padding: 0,
                        margin: 0,
                        color: '#027A48',

                        '& svg': {
                          fontSize: '20px'
                        }
                      },

                      '& .MuiAlert-message': {
                        padding: 0,

                        '& .MuiTypography-root': {
                          marginTop: '0.25rem',
                          fontFamily: 'Manrope',
                          fontSize: '14px',
                          fontWeight: 400,
                          color: '#027A48',
                          lineHeight: '142.857%',
                        },

                        '& .MuiAlertTitle-root': {
                          margin: 0,
                          fontFamily: "Manrope SemiBold",
                          color: '#027A48',
                          fontWeight: 600,
                        }
                      }
                    }} icon={<CheckCircleOutlineOutlinedIcon />} variant="outlined" severity="success">
                      <AlertTitle>{uploadResponseData.events.length}/{validateResponseData.events.length} events has been successfully created</AlertTitle>
                    </Alert>)}

                    { getInvalidEvents() > 0 && (
                      <>
                    <Alert sx={ {
                      borderRadius: '8px',
                      mb: 2,
                      padding: '1rem',
                      gap: '12px',
                      border: '1px solid #FDA29B',
                      background: '#FFFBFA',

                      '& .MuiAlert-icon': {
                        padding: 0,
                        margin: 0,
                        color: '#B42318',

                        '& svg': {
                          fontSize: '20px'
                        }
                      },

                      '& .MuiAlert-message': {
                        padding: 0,

                        '& .MuiTypography-root': {
                          marginTop: '0.25rem',
                          fontFamily: 'Manrope',
                          fontSize: '14px',
                          fontWeight: 400,
                          color: '#B42318',
                          lineHeight: '142.857%',
                        },

                        '& .MuiAlertTitle-root': {
                          margin: 0,
                          fontFamily: "Manrope SemiBold",
                          color: '#B42318',
                          fontWeight: 600,
                        }
                      }
                    }} icon={<ErrorOutlineOutlinedIcon />} variant="outlined" severity="error">
                      <AlertTitle>{getInvalidEvents()}/{validateResponseData.events.length} calendar events have import errors</AlertTitle>
                      <div className="d-flex align-items-center justify-content-end">
                      <button className="download-template" style={{color: '#D92D20'}} onClick={handleGenerateExcel}>
                        Download events that can’t be imported (.xlsx)
                        <img src={DownloadError} alt="Download" />
                      </button>
                    </div>
                    </Alert>                
                    </>
                    )}
                  </>)}

            </div>

            <div className="modal-footer">
              <div className="flex-grow-1 d-flex align-items-center">
                <ul className="d-flex align-items-center">
                  <li className={eventUploadStep === 1 && "active"}></li>
                  <li className={eventUploadStep === 2 && "active"}></li>
                  <li className={eventUploadStep === 3 && "active"}></li>
                </ul>
                {/* Step 1 Sceanario: File could not be validated error message */}
                {(eventUploadStep === 1 && validateStatusCodeRedux !== 200 && !loadingValidation) && <p>{validateResponseMessage}</p>}
                {/* Step 1 Sceanario: File could be send to validate error */}
                {(eventUploadStep === 1 && exceptionWithExcelfile && !loadingValidation) && <p>{exceptionWithExcelfile}</p>}
                {/* Step 1 Sceanario: File could not be validated error message */}
                {(eventUploadStep === 3 && uploadStatusCodeRedux !== 200 && !loadingEventUpload) && <p>{BULK_EVENT_CREATE_ERROR}</p>}
              </div>
             { eventUploadStep === 3 && uploadStatusCodeRedux === 200 ?
              <button
              type="button"
              className={`btn btn-genmeb ms-3`}
              data-bs-dismiss="modal"
            >
              Done
            </button> :
            <>
             <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className={`btn btn-genmeb ms-3 ${acceptedFiles.length === 0 || ( acceptedFiles.length === 1 && validateStatusCodeRedux !== 200) || ( eventUploadStep === 2 && validateStatusCodeRedux === 200 && getValidEvents() == 0)? 'disabled' : ''}`}
                onClick={continueToNextStep}
              >
                Continue
              </button>
              </>
            }
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default AddBulkEvents;