import {masterActions, usersActions} from "../actions/constants";
import { updateState } from "../middlewares/utils";

const defaultState = {
  loading: true,
  response: null,
  userGroups: [],
  departments: [],
  notificationTypes: [],
  userRoles: [],
  locations: [],
  error: false,
  responseLoading: true,
  groupsResponse: null,
  groupsResponseLoading: true,
  deleteResponse: null,
  deleteResponseLoading: true,
  departmentsLoading: true,
  locationsLoading: true,
  photoInfo: [],
  photoLoading: true,
  photoMessage: "",
  calendarEventCategories : [],
  sharepointNotifications: [],
  allGroupApps: [],
  applicationAssignments: {users: [], groups: []},
};

const master = (state = defaultState, actions) => {
  switch (actions.type) {
    case masterActions.FETCH_ALL_DEPARTMENTS_START:
      return updateState(state, { departmentsLoading: true });
    case masterActions.FETCH_ALL_DEPARTMENTS_SUCCESS:
      return updateState(state, {
        departments: actions.payload.data,
        departmentsLoading: actions.payload.loading,
      });
    case masterActions.FETCH_ALL_DEPARTMENTS_FAILED:
      return updateState(state, { ...actions.payload });

    case masterActions.FETCH_ALL_LOCATIONS_START:
      return updateState(state, { locationsLoading: true });
    case masterActions.FETCH_ALL_LOCATIONS_SUCCESS:
      return updateState(state, {
        locations: actions.payload.data,
        locationsLoading: actions.payload.loading,
        message: actions.payload.message,
      });
    case masterActions.FETCH_ALL_LOCATIONS_FAILED:
      return updateState(state, { ...actions.payload });

    case masterActions.FETCH_ALL_USER_GROUPS_START:
      return updateState(state, { loading: true });
    case masterActions.FETCH_ALL_USER_GROUPS_SUCCESS:
      return updateState(state, {
        userGroups: actions.payload.responseData,
        loading: false,
      });
    case masterActions.FETCH_ALL_USER_GROUPS_FAILED:
      return updateState(state, { error: actions.payload });

    case masterActions.CREATE_USER_GROUPS_START:
      return updateState(state, {
        groupsResponseLoading: true,
        groupsResponse: null,
      });
    case masterActions.CREATE_USER_GROUPS_SUCCESS:
      return updateState(state, {
        groupsResponse: actions.payload.statusCode,
        groupsResponseLoading: false,
        groupPaylod: actions.payload
      });
    case masterActions.CREATE_USER_GROUPS_FAILED:
      return updateState(state, { error: actions.payload });

    case masterActions.DELETE_USER_GROUPS_START:
      return updateState(state, {
        deleteResponse: null,
        deleteResponseLoading: true,
      });
    case masterActions.DELETE_USER_GROUPS_SUCCESS:
      return updateState(state, {
        deleteResponse: actions.payload.statusCode,
        deleteResponseLoading: false,
      });
    case masterActions.DELETE_USER_GROUPS_FAILED:
      return updateState(state, { error: actions.payload });

    case masterActions.UPDATE_USER_GROUPS_START:
      return updateState(state, {
        groupsResponseLoading: true,
        groupsResponse: null,
      });
    case masterActions.UPDATE_USER_GROUPS_SUCCESS:
      return updateState(state, {
        updateStatus: actions.payload,

        groupsResponse: actions.payload.statusCode,
        groupsResponseLoading: false,
      });
    case masterActions.UPDATE_USER_GROUPS_FAILED:
      return updateState(state, { error: actions.payload });

    case masterActions.FETCH_ALL_USER_ROLES_START:
      return updateState(state, { loading: true });
    case masterActions.FETCH_ALL_USER_ROLES_SUCCESS:
      return updateState(state, {
        userRoles: actions.payload.responseData,
        loading: false,
      });
    case masterActions.FETCH_ALL_USER_ROLES_FAILED:
      return updateState(state, { error: actions.payload });

    case masterActions.CREATE_NOTIFICATION_TYPE_START:
      return updateState(state, { responseLoading: true, response: null });
    case masterActions.CREATE_NOTIFICATION_TYPE_SUCCESS:
      return updateState(state, {
        response: actions.payload.statusCode,
        responseLoading: false,
      });
    case masterActions.CREATE_NOTIFICATION_TYPE_FAILED:
      return updateState(state, { error: actions.payload });

    case masterActions.FETCH_ALL_NOTIFICATION_TYPE_START:
      return updateState(state, { loading: true });
    case masterActions.FETCH_ALL_NOTIFICATION_TYPE_SUCCESS:
      return updateState(state, {
        notificationTypes: actions.payload,
        loading: false,
      });
    case masterActions.FETCH_ALL_NOTIFICATION_TYPE_FAILED:
      return updateState(state, { error: actions.payload });

    case masterActions.DELETE_NOTIFICATION_TYPE_START:
      return updateState(state, { loading: true });
    case masterActions.DELETE_NOTIFICATION_TYPE_SUCCESS:
      return updateState(state, {
        deletedMsg: actions.payload,
        loading: false,
      });
    case masterActions.DELETE_NOTIFICATION_TYPE_FAILED:
      return updateState(state, { error: actions.payload });

    case masterActions.UPDATE_NOTIFICATION_TYPE_START:
      return updateState(state, { responseLoading: true, response: null });
    case masterActions.UPDATE_NOTIFICATION_TYPE_SUCCESS:
      return updateState(state, {
        updateStatus: actions.payload,

        response: actions.payload.statusCode,
        responseLoading: false,
      });
    case masterActions.UPDATE_NOTIFICATION_TYPE_FAILED:
      return updateState(state, { error: actions.payload });

    case masterActions.FETCH_IMAGES_START:
      return updateState(state, { photoLoading: true });
    case masterActions.FETCH_IMAGES_SUCCESS:
      return updateState(state, {
        photoInfo: actions.payload.photo,
        photoLoading: actions.payload.loading,
        photoMessage: actions.payload.message,
      });
    case masterActions.FETCH_IMAGES_FAILED:
      return updateState(state, { ...actions.payload });
    case masterActions.CREATE_CALENDAR_EVENT_CATEGORY_START:
      return updateState(state, { responseLoading: true, response: null });
    case masterActions.CREATE_CALENDAR_EVENT_CATEGORY_SUCCESS:
      return updateState(state, {
        response: actions.payload.statusCode,
        responseLoading: false,
      });
    case masterActions.CREATE_CALENDAR_EVENT_CATEGORY_FAILED:
      return updateState(state, { error: actions.payload });
  
    case masterActions.FETCH_ALL_CALENDAR_EVENT_CATEGORY_START:
      return updateState(state, { loading: true });
    case masterActions.FETCH_ALL_CALENDAR_EVENT_CATEGORY_SUCCESS:
      return updateState(state, {
        calendarEventCategories: actions.payload,
        loading: false,
      });
    case masterActions.FETCH_ALL_CALENDAR_EVENT_CATEGORY_FAILED:
      return updateState(state, { error: actions.payload });
  
    case masterActions.DELETE_CALENDAR_EVENT_CATEGORY_START:
      return updateState(state, { loading: true });
    case masterActions.DELETE_CALENDAR_EVENT_CATEGORY_SUCCESS:
      return updateState(state, {
        deletedMsg: actions.payload,
        loading: false,
      });
    case masterActions.DELETE_CALENDAR_EVENT_CATEGORY_FAILED:
      return updateState(state, { error: actions.payload });
  
    case masterActions.UPDATE_CALENDAR_EVENT_CATEGORY_START:
      return updateState(state, { responseLoading: true, response: null });
    case masterActions.UPDATE_CALENDAR_EVENT_CATEGORY_SUCCESS:
      return updateState(state, {
        updateStatus: actions.payload,
  
        response: actions.payload.statusCode,
        responseLoading: false,
      });
    case masterActions.UPDATE_CALENDAR_EVENT_CATEGORY_FAILED:
      return updateState(state, { error: actions.payload });
    case masterActions.GET_SHARE_POINT_NOTIFICATIONS_READ_STATUS_SUCCESS:
      return updateState(state, {
        sharepointNotificationsReadStatus: actions.payload
      });
    case masterActions.FETCH_ALL_APPS_BY_GROUPID_START:
      return updateState(state, { allGroupAppsLoading: true });
    case masterActions.FETCH_ALL_APPS_BY_GROUPID_SUCCESS:
      return updateState(state, {
        allGroupApps: actions.payload.test,
        allGroupAppsLoading: actions.payload.loading,
        allGroupAppsMessage: actions.payload.message,
      });
    case masterActions.FETCH_ALL_APPS_BY_GROUPID_FAILED:
      return updateState(state, { error: actions.payload });

    case masterActions.FETCH_ALL_USERS_AND_GROUPS_BY_APPLICATION_ID_START:
      return updateState(state, { applicationAssignmentsLoading: true });
    case masterActions.FETCH_ALL_USERS_AND_GROUPS_BY_APPLICATION_ID_SUCCESS:
      return updateState(state, {
        applicationAssignments: actions.payload.test,
        applicationAssignmentsLoading: actions.payload.loading,
        applicationAssignmentsMessage: actions.payload.message,
      });
    case masterActions.FETCH_ALL_USERS_AND_GROUPS_BY_APPLICATION_ID_FAILED:
      return updateState(state, { error: actions.payload });

    default:
      return state;
  }
};

export default master;
