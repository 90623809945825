import { Avatar, Badge, Box, Button, IconButton, Popover, Typography } from "@mui/material";
import React from "react";
import { Bell, Close } from "../Icons";
import {formatDate, openInNewTab} from "./utils";
import {
    fetchNotificationTypes, getSharepointNotificationsReadStatus,
    persistSharedNotificationReadStatus,
    updateAlertReadStatus
} from "../../actions/action-master";
import {dashDateNotificatiionFilter, fetchDashboardAlerts} from "../../actions/action-notifications";
import * as actions from "../../actions/action-notifications";
import {fetchSharepointData, resetSharepointState} from "../../actions/actions-sharepoint";
import {connect} from "react-redux";
import {FILTER_BY_DATE} from "../../actions/constants";

const style = {
    heading: {
        color: '#101828',
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: 'normal',
    },

    dot: {
        width: '0.625rem',
        height: '0.625rem',
        position: 'absolute',
        top: 0,
        right: '-1.375rem',
        borderRadius: '0.8125rem',
        background: '#06A894'
    },

    avatar: { p: '0.125rem', borderRadius: 0, background: 'transparent', color: '#475467' },

    read: {
        background: '#F2F4F7',

        '&:hover': {
            background: '#EAECF0',
            borderColor: '#06A894'
        }
    },

    row: {
        cursor: 'pointer',
        borderLeft: '0.25rem solid transparent',
    },

    unread: {
        background: '#FCFCFD',
        
        '&:hover': {
            background: '#F9FAFB',
            borderColor: '#06A894'
        }
    },

    footer: {
        padding: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        gap: '0.625rem'
    },

    body: {
        maxHeight: '18.75rem',
        overflow: 'auto'
    },

    date: { position: 'sticky', zIndex: 1, top: 0, p: '0.25rem 0.75rem', color: '#475467', letterSpacing: 'normal', fontSize: '0.625rem', textTransform: 'uppercase', fontWeight: 600, lineHeight: 'normal', background: '#EAECF0' },
        
    header: {
        padding: '0 0.75rem 0 1rem',
        display: 'flex',
        alignItems: 'center',
        background: '#fff',
        borderTopLeftRadius: '0.5rem',
        borderTopRightRadius: '0.5rem',
        height: '3.25rem',
        justifyContent: 'space-between'
    },

    iconButton: {
        padding: '0.375rem',
        borderRadius: 0,

        '&:hover': {
            background: 'transparent'
        }
    }
}

const SharepointNotifications = ({item, persistSharedNotificationReadStatus}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const badgeContent = item?.notifications.filter(notification => !notification.isRead).length || 0;
    const groupedNotifications = groupNotificationsByDate(item.notifications);
    const filterByDate = new Date(new Date().setDate(new Date().getDate() - FILTER_BY_DATE)).toISOString();
    const handleClick = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.stopPropagation()
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    function sortByDateDescending(arr, dateKey) {
        return arr.sort((a, b) => {
            const dateA = new Date(a[dateKey]);
            const dateB = new Date(b[dateKey]);

            return dateB - dateA;
        });
    }

    function groupNotificationsByDate(notifications) {
        const result = {};
        const sortedNotifications = sortByDateDescending(notifications, 'lastModifiedDateTime')
        const now = new Date();
        const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const startOfYesterday = new Date(startOfToday);
        startOfYesterday.setDate(startOfToday.getDate() - 1);

        const startOfWeek = new Date(startOfToday);
        startOfWeek.setDate(startOfToday.getDate() - startOfToday.getDay());

        sortedNotifications.forEach(notification => {
            const time = new Date(notification.lastModifiedDateTime);

            if (time >= startOfToday) {
                if (!result.hasOwnProperty('today')) {
                    result['today'] = [];
                }
                result.today.push(notification);
            } else if (time >= startOfYesterday && time < startOfToday) {
                if (!result.hasOwnProperty('yesterday')) {
                    result['yesterday'] = [];
                }
                result.yesterday.push(notification);
            } else if (time >= startOfWeek) {
                if (!result.hasOwnProperty('lastWeek')) {
                    result['lastWeek'] = [];
                }
                result.lastWeek.push(notification);
            } else {
                if (!result.hasOwnProperty('other')) {
                    result['other'] = [];
                }
                result.other.push(notification);
            }
        });
        return result;
    }

    const handleNotificationClick = (notification) => {
        persistSharedNotificationReadStatus({id: notification.id, isRead: true, filterByDate: filterByDate});
        openInNewTab(
            decodeURIComponent(notification.webUrl),
        )
    };

    const handleNotificationsMarkAllAsRead = () => {
        const unreadNotifications = item.notifications.filter(notification => !notification.isRead );
        // Refactor BE endpoint to take array to reduce to a single request.
        if (unreadNotifications.length > 0) {
            unreadNotifications.forEach(unreadNotification =>
                persistSharedNotificationReadStatus({id: unreadNotification.id, isRead: true, filterByDate: filterByDate}));
        }
    }

    return (
        <Badge sx={{
            position: 'absolute',
            right: '0.5rem',
            top: '0.5rem',
        }} badgeContent={badgeContent} color="success">
            <IconButton aria-describedby={id} onClick={handleClick} sx={{
                p: '0.5rem',
                background: '#fff',
                border: '0.0625rem solid #D0D5DD',
                borderRadius: '0.5rem',
                boxShadow: '0rem 0.0625rem 0.125rem 0rem rgba(16, 24, 40, 0.05)',
                '&:focus': {
                    boxShadow: '0rem 0.0625rem 0.125rem 0rem rgba(16, 24, 40, 0.05), 0rem 0rem 0rem 0.25rem #F2F4F7',
                }
            }}>
                <Bell fill="#344054" />
            </IconButton>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClick={(e) => e.stopPropagation()}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box sx={style.header}>
                    <Typography sx={style.heading}>{item.appName} notifications</Typography>
                    <IconButton onClick={handleClose} sx={style.iconButton}><Close /></IconButton>
                </Box>
                <Box sx={style.body}>
                    {Object.entries(groupedNotifications).map(([interval, notifications]) => (
                        <Box key={interval}>
                            <Typography sx={style.date}>{interval}</Typography>
                            {notifications.map((notification, index) => (
                            <Box key={index} display='flex' gap="0.75rem" p="0.75rem" sx={{ ...style.row, ...(notification.isRead ? style.read : style.unread) }}
                                 onClick={() => handleNotificationClick(notification)
                            }>
                                <Box position='relative'>
                                    {!notification.isRead && <Box sx={style.dot} />}
                                    <Typography sx={{ fontSize: '0.875rem', lineHeight: 'normal', color: '#101828' }}>{notification.name}</Typography>
                                    <Typography sx={{ fontSize: '0.875rem', lineHeight: 'normal', color: '#475467' }}>{formatDate(notification.lastModifiedDateTime)}</Typography>
                                </Box>
                            </Box>
                            ))}
                        </Box>
                    ))}
                </Box>
                <Box sx={style.footer}>
                    <Button fullWidth variant="text"
                            onClick={() => handleNotificationsMarkAllAsRead()}
                            sx={{color: '#026459', boxShadow: 'none', '&:hover': {
                        background: '#E6F6F4', boxShadow: 'none',
                    }}}>Mark all as read</Button>
                    <Button fullWidth variant="outlined"
                            onClick={() =>
                                openInNewTab(
                                    decodeURIComponent(item.appUrl),
                                )
                            }
                            sx={{'&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)', border: '0.0625rem solid #D0D5DD',
                    }}}>Open folder</Button>
                </Box>
            </Popover>
        </Badge>
    )
}


const mapDispatchToProps = (dispatch) => ({
    persistSharedNotificationReadStatus : (payload) =>
        dispatch(persistSharedNotificationReadStatus(payload)),
});

export default connect(null, mapDispatchToProps)(SharepointNotifications);