import { eventActions } from "../actions/constants";
import { updateState } from "../middlewares/utils";

const defaultState = {
  openDeleteModal: false,
  loading: false,
  response: {},
  event: [],
  error: false,
  pageKeys: [],
  pageNo: 1,
  totalRecords: 0,
  currentPage: null,
  recordsPerPage: 10,
  displayLimit: 10,
  paginationOptions: {
    pageNo: 1,
    displayLimit: 10,
  },
  eventStart: "",
  eventEnd: "",
  fromDate: "",
  toDate: "",
  fromPopUpDate: "",
  toPopUpDate : "",
  dashEvents: [],
  dashEventsLoading: true,
  dashPopEvents: [],
  dashPopEventsLoading: true,
  dashPopupDates:[],
  datesPopupLoading: true,
  datesLoading:true,
  dashDates: []
};

const events = (state = defaultState, actions) => {
  switch (actions.type) {
    case eventActions.CREATE_NEW_EVENT_START:
      return updateState(state, { loading: true });
    case eventActions.CREATE_NEW_EVENT_SUCCESS:
      return updateState(state, { response: actions.payload, loading: false });
    case eventActions.CREATE_NEW_EVENT_FAILED:
      return updateState(state, { error: actions.payload });

    case eventActions.FETCH_ALL_EVENT_START:
      return updateState(state, { loading: true });
    case eventActions.FETCH_ALL_EVENT_SUCCESS:
      return updateState(state, {
        event: actions.payload.payload,
        loading: actions.payload.loading,
      });
    case eventActions.FETCH_ALL_EVENT_FAILED:
      return updateState(state, { error: actions.payload });

    case eventActions.DELETE_EVENT_START:
      return updateState(state, { loading: true });
    case eventActions.DELETE_EVENT_SUCCESS:
      return updateState(state, {
        deletedMsg: actions.payload,
        loading: false,
      });
    case eventActions.DELETE_EVENT_FAILED:
      return updateState(state, { error: actions.payload });
    case eventActions.OPEN_DELETE_MODAL:
      return updateState(state, { openDeleteModal: actions.payload.openModal });

    case eventActions.UPDATE_EVENT_START:
      return updateState(state, { loading: false });
    case eventActions.UPDATE_EVENT_SUCCESS:
      return updateState(state, { updateStatus: actions.payload });
    case eventActions.UPDATE_EVENT_FAILED:
      return updateState(state, { error: actions.payload });
    case eventActions.SET_EVENT_RECORDS_LENGTH:
      return updateState(state, { totalRecords: actions.payload.records });
    case eventActions.FETCH_PAGINATION_DATA_SUCCESS:
      return updateState(state, { paginationOptions: { ...actions.payload } });
    case eventActions.FETCH_SEARCH_INPUT: {
      return updateState(state, {
        search: actions.payload.search,
        paginationOptions: { ...actions.payload.paginationOptions },
      });
    }

    case eventActions.FETCH_EVENT_FITLER_DATES:
      return updateState(state, {
        eventStart: actions.payload.dates.startDate,
        eventEnd: actions.payload.dates.endDate,
        paginationOptions: { ...actions.payload.paginationOptions },
      });

    case eventActions.FETCH_DASH_EVENTS_BY_LOGIN_ID_START:
      return updateState(state, { dashEventsLoading: true });
    case eventActions.FETCH_DASH_EVENTS_BY_LOGIN_ID_SUCCESS:
      return updateState(state, {
        dashEvents: actions.payload.payload,
        dashEventsLoading: actions.payload.loading,
      });
    case eventActions.FETCH_DASH_EVENTS_BY_LOGIN_ID_FAILED:
      return updateState(state, { ...actions.payload });

    case eventActions.FETCH_DASH_POPUP_EVENTS_BY_LOGIN_ID_START:
      return updateState(state, { dashPopEventsLoading: true });
    case eventActions.FETCH_DASH_POPUP_EVENTS_BY_LOGIN_ID_SUCCESS:
      return updateState(state, {
        dashPopEvents: actions.payload.payload,
        dashPopEventsLoading: actions.payload.loading,
      });
    case eventActions.FETCH_DASH_POPUP_EVENTS_BY_LOGIN_ID_FAILED:
      return updateState(state, { ...actions.payload });

    case eventActions.FETCH_DASH_EVENTS_FITLER_DATES:
      return updateState(state, {
        fromDate: actions.payload.dates.startDate,
        toDate: actions.payload.dates.endDate,
      });

      case eventActions.FETCH_DASH_POPUP_EVENTS_FITLER_DATES:
      return updateState(state, {
        fromPopUpDate: actions.payload.dates.startDate,
        toPopUpDate: actions.payload.dates.endDate,
      });

    case eventActions.FETCH_DATES_EVENTS_BY_LOGIN_ID_START:
      return updateState(state, { datesLoading: true });
    case eventActions.FETCH_DATES_EVENTS_BY_LOGIN_ID_SUCCESS:
      return updateState(state, {
        dashDates: actions.payload.payload,
        datesLoading: actions.payload.loading,
      });
    case eventActions.FETCH_DATES_EVENTS_BY_LOGIN_ID_FAILED:
      return updateState(state, { ...actions.payload });

    case eventActions.FETCH_DATES_POPUP_EVENTS_BY_LOGIN_ID_START:
      return updateState(state, { datesPopupLoading: true });
    case eventActions.FETCH_DATES_POPUP_EVENTS_BY_LOGIN_ID_SUCCESS:
      return updateState(state, {
        dashPopupDates: actions.payload.payload,
        datesPopupLoading: actions.payload.loading,
      });
    case eventActions.FETCH_DATES_POPUP_EVENTS_BY_LOGIN_ID_FAILED:
      return updateState(state, { ...actions.payload });
    case eventActions.ADD_BULK_EVENTS_START:
      return updateState(state, {
        bulkUploadLoading: true,
        bulkUploadResponse: null,
        bulkUploadStatusCode: null,
      });
    case eventActions.ADD_BULK_EVENTS_SUCCESS:
      return updateState(state, {
        bulkUploadLoading: false,
        bulkUploadResponse: actions.payload.displayMessage,
        bulkUploadStatusCode: actions.payload.statusCode,
        bulkUploadResponseData: actions.payload.responseData
      });
    case eventActions.ADD_BULK_EVENTS_FAILED:
      return updateState(state, { error: actions.payload });
    case eventActions.VALIDATE_BULK_EVENTS_START:
      return updateState(state, {
        bulkValidateLoading: true,
        bulkValidateResponse: null,
        bulkValidateStatusCode: null,
      });
    case eventActions.VALIDATE_BULK_EVENTS_SUCCESS:
      return updateState(state, {
        bulkValidateLoading: false,
        bulkValidateResponse: actions.payload.displayMessage,
        bulkValidateStatusCode: actions.payload.statusCode,
        bulkValidateResponseData: actions.payload.responseData
      });
    case eventActions.BULK_EVENT_UPLOAD_RESET:
      return updateState(state, {
        bulkValidateLoading: false,
        bulkValidateResponse: null,
        bulkValidateStatusCode: null,
        bulkUploadLoading: false,
        bulkUploadResponse: null,
        bulkUploadStatusCode: null,
      });
    case eventActions.VALIDATE_BULK_EVENTS_FAILED:
      return updateState(state, { error: actions.payload });
    default:
      return state;
  }
};

export default events;
