import React, { forwardRef } from "react";
import arowIcon from "../../assets/images/new-images/next-arow.svg";
import lock from "../../assets/images/new-images/lock.svg";
import n1 from "../../assets/images/new-images/placeholder.png";
import { useSortable } from "@dnd-kit/sortable";
import { openInNewTab } from "./utils";
import { IMAGE_FILE_TYPES } from "../../constants";
import {DragIcon} from '../Icons'
import SharepointNotifications from "./SharepointNotifications";

const Item = forwardRef(
  ({ item, id, isDragging, index,searchInput, style, ...props }, ref) => {
    const inlineStyles = {
      ...style,
    };

    const grabStyle = {
      cursor: isDragging ? "grabbing" : "grab",
      transform: isDragging ? "scale(1.04)" : "scale(1)",
      
    };
    const sortable = useSortable({ id: id });
    const { attributes, listeners } = sortable;

    return (
        <li
            className="app-drow-list"
            ref={ref}
            style={inlineStyles}
            {...props}
            onClick={() =>
                openInNewTab(
                    decodeURIComponent(item.appUrl),
                    decodeURIComponent(item.mobileAppUrl)
                )
            }
        >
            {index === 0 && !searchInput ? (
                <button className="dragIcon2">
                    <img src={lock} alt="dragIcon"/>
                </button>
            ) : (
                <button className="dragIcon" {...attributes} {...listeners} style={grabStyle}>
                    <DragIcon />
                </button>
            )}
            {item.notifications.length > 0  && <SharepointNotifications item={item} />}
            <span className="uploadAppLogo">
                <img
                    className="app-logo"
                    src={
                        item.imagebase64String !== "" && item.imagebase64String !== null
                            ? `data:${item.imagebase64String.startsWith('PHN') ? IMAGE_FILE_TYPES.SVG : IMAGE_FILE_TYPES.JPEG};base64,${item.imagebase64String}`
                            : n1
                    }
                    alt={item.alt}
                />
            </span>
            <p className="app-name">{item.displayName}</p>
            <span
                style={{fontSize: "12px", display: "block", lineHeight: '18px', color: '#1D2939'}}
                className="information"
            >
          {item.description}
        </span>
            <img className="arowIcon" src={arowIcon} alt="arowIcon"/>
        </li>
    );
  }
);

export default Item;
